
import React, { useEffect, useRef, useState } from "react";
import Foff from "../../assets/FoF-Logo.png";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import {
  Application,
  Assets,
  Color,
  Container,
  Texture,
  Sprite,
  Graphics,
  Text,
  TextStyle,
  BlurFilter,
  FillGradient,
  Rectangle,
  AnimatedSprite,
  BitmapText,
} from "pixi.js";

import axios from "axios";
import GameHistoryTable from "../../Pages/GameHistoryTable";
import SliderItems from "../Payout Table Slider/SliderItems";
import SessionExpired from "../../utils/SessionExpired";
import { useNavigate } from "react-router-dom";
import LoadFile from "../LoadFileJson/LoadFile.json";
import LoadFile_mobile from "../LoadFileJson/mobile_LoadFile.json";
// import * as TWEEN from '@tweenjs/tween.js'
import Preloader from "../../utils/Preloader";
import Swal from "sweetalert2";
import { getMessage } from "../../utils/userMessages";
import {gameType} from "../../utils/gameType";

export const MexTEST = () => {
  const pixiContainer = useRef(null);
  const [isHtmlVisible, setHtmlVisible] = useState(false);
  const [isPayoutTable, setPayoutTable] = useState(false);
  const [isSessionExpired, setSessionExpired] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();
  const playerId = localStorage.getItem("playerId");
  const token = localStorage.getItem("token");
  const MinorContainerSticky = new Container();
  const MajorContainerSticky = new Container();
  const GrandContainerSticky = new Container();
  const MiniContainerSticky = new Container();
  const jackpotWinContainer = new Container();
  jackpotWinContainer.visible = false;
  useEffect(() => {
    (async () => {
        async function gameLoadDataApi() {
            try {
              const gameData = await axios?.post(
                `${process.env.REACT_APP_API_URL}/GamePlay/GameLoad`,
                {
                  playerId: playerId,
                  gameId: gameType.MexTest,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              return gameData?.data?.resultData;
            } catch (error) {
              if (error?.response?.status === 401) {
                localStorage.removeItem("playerId");
                localStorage.removeItem("token");
                sessionExpired();
              }
            }
        }

      const loadDynamicAssets= await gameLoadDataApi()
      console.log(loadDynamicAssets,'loadDynamicAssets')
    
      let loadImg = ["HH","KK","II","FF","WW","CC","DD","GG","SS","JJ","EE","TT","JJ","EE","TT"];
      var loadPath= loadImg?.map((item)=>`${process.env.REACT_APP_ASSET_URL}/Images/GameIcon/Newfolder_${gameType.MexTest}/${item}.png`)
          
    
    
      const loadDynamicAssetsArr =  loadDynamicAssets?.defaultCombination.split(",")
      let modifyGameLoad = ["HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH","HH"]
      let blankTexture=[1,2,3,6,7,8,11,12,13,16,17,18,21,22,23]
      for(let i=0;i<blankTexture.length;i++){
      modifyGameLoad[blankTexture[i]]=loadDynamicAssetsArr[i];
      }
      console.log(modifyGameLoad,'loadDynamicAssetsArr')
      var assetPath= modifyGameLoad?.map((item)=>`${process.env.REACT_APP_ASSET_URL}/Images/GameIcon/Newfolder_${gameType.MexTest}/${item}.png`)
      console.log("loadDynamicAssets", assetPath)

      // Create a new application
      const app = new Application();

      // Initialize the application
      await app.init({
        width: window.innerWidth,
        height: window.innerHeight,
        antialias: true,
      });
      document.body.appendChild(app.canvas);

      // Load the textures
      // await Assets.load(LoadFile.loadImages);
      let loadFiledata;
      const allAssets = [
        ...LoadFile.slotSpriteSheet,
        ...LoadFile.loadImages,
        ...LoadFile.loadAnimatedSprite,
        ...LoadFile.payLines,
        // ...LoadFile.symbolsList,
        ...assetPath,
        ...loadPath,
        ...LoadFile?.slotSpriteImg,
        ...LoadFile.Cortinilla_LinkBeat,
        ...LoadFile.sheetSheet,
        ...LoadFile.sheetSheet2,
        ...LoadFile.sheetSheetoverlay,
      ];
      if (window.innerWidth > 480) {
        loadFiledata = await Assets.load(allAssets, (value) => {
          setProgress(Math.floor(value * 100));
        });
      } else {
        const allAssetsmobile = [
        //   ...LoadFile_mobile.slotSpriteSheet,
          ...assetPath,
          ...loadPath,
          ...LoadFile_mobile.loadImages,
          ...LoadFile_mobile.loadAnimatedSprite,
          ...LoadFile_mobile.payLines,
          ...LoadFile_mobile.symbolsList,
          ...LoadFile?.slotSpriteImg,
        ];
        loadFiledata = await Assets.load(allAssetsmobile, (value) => {
          setProgress(Math.floor(value * 100));
        });
      }
      let payline = Number(loadDynamicAssets?.noOfPayline);
      let multiCounter = 0;
      let multiPlyArr = loadDynamicAssets?.gameMultiplyerList;
      // console.log(loadFiledata,"loadFiledata")
      var coinDenonimation;
      var animationSize = {};
      var size = [window.innerWidth, window.innerHeight];
      var ratio = size[0] / size[1];
      let freeSpinCredits;
      let X1percent = size[0] / 100;
      let Y1percent = size[1] / 100;
      let freeSpinType = false;
      let freespinWinvalue = 0;
      let totalWinstickyamount = 0;
      let totalWinstickyamountdollar = 0;
      let checkFIreIcon = 0;
      let count = 0;
      let IsstickySpin = false;
      let linkPrizeAmount;
      let prizeTypeForStickeySpin;
      let countTTSym;
      var subWinTextvalue;
      var testKeyVar;
      let IsspinReelAnimation = false;
      let grandValue = 0;
      let majorValue = 0;
      let minorValue = 0;
      let miniValue = 0;
      let defaultLang;
      let seperatePayline;
      let finalFreeSpinWinning;
      let stickySpininterval;
      let grandJackpotAmount;
      var formatter = new Intl.NumberFormat("en-US");
      var reelrunning = false;
      var totalFireAmount = 0;
      var totalFireAmountInDollar = 0;
      let totalStickySpinAmountInCredits = 0;
      let totalFreeStickyAmountInCredits = 0;
      let totalFreeStickyAmountInCreditsInDollar = 0;
      let totalFreegameWinCredits;
      var disableSpace = false;
      var stickyFlag=false;
      var sunFireDataObj;
      var SunFireText;
      var SunFireText1;
      var SunFireText2;
      var SunFireText3;
      var SunFireText4;
      var SunFireText5;
      var SunFireText6;
      var SunFireText7;
      var SunFireText8;
      var SunFireText9;
      var SunFireText10;
      var SunFireText11;
      var SunFireText12;
      var SunFireText13;
      var SunFireText14;
      let hitApi = false;
      let backtofreegameafterSticky = false;
      // let stickyCounter = 0
      let strickfordoblecallFunction = false;
      let pos = [
        { x: X1percent * 24, y: Y1percent * 26.5 },
        { x: X1percent * 24, y: Y1percent * 47 },
        { x: X1percent * 24, y: Y1percent * 67.5 },
        { x: X1percent * 36.5, y: Y1percent * 26.5 },
        { x: X1percent * 36.5, y: Y1percent * 47 },
        { x: X1percent * 36.5, y: Y1percent * 67.5 },
        { x: X1percent * 49, y: Y1percent * 26.5 },
        { x: X1percent * 49, y: Y1percent * 47 },
        { x: X1percent * 49, y: Y1percent * 67.5 },
        { x: X1percent * 61.5, y: Y1percent * 26.5 },
        { x: X1percent * 61.5, y: Y1percent * 47 },
        { x: X1percent * 61.5, y: Y1percent * 67.5 },
        { x: X1percent * 74, y: Y1percent * 26.5 },
        { x: X1percent * 74, y: Y1percent * 47 },
        { x: X1percent * 74, y: Y1percent * 67.5 },
      ];

      let stickybackground = new PIXI.Container();
      let reelIndex;
      let slotIndex;
      let IsStickeySpinWon = false;
      var StickeySpinWon;
      let remaningStickeySpin = 0;
      let spaceFilledWtihFire = 0;
      // let spaceFilledWtihFire;

      let REEL_WIDTH;
      let SYMBOL_SIZE;
      if (window.innerWidth <= 480) {
        SYMBOL_SIZE = app.screen.width / 5.8;
        REEL_WIDTH = app.screen.width / 7.8;
      } else {
        SYMBOL_SIZE = app.screen.width / 9;
        REEL_WIDTH = app.screen.width / 8;
      }


      const stickyContainer = new Container();
      let stickyContainerindex = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    const slotTextures = [];
      for (let i = 0; i < loadPath?.length; i++) {
        const texture = Texture.from(loadPath[i]);
        texture.name = loadImg[i];
        slotTextures.push(texture);
      }
    for (let i = 0; i < assetPath?.length; i++) {
      const texture = Texture.from(assetPath[i]);
      texture.name = modifyGameLoad[i];
      slotTextures.push(texture);
    }

      console.log(slotTextures,'slotTextures',modifyGameLoad,'slotTextures',assetPath)
   
      let slotSpriteSheet = LoadFile?.slotSpriteSheet;

      const linkHitSheet = await Assets.load(LoadFile.Cortinilla_LinkBeat[0]);
      const sheetSheet = await Assets.load(LoadFile.sheetSheet[0]);
      const sheetSheetoverlay = await Assets.load(
        LoadFile.sheetSheetoverlay[0]
      );
      const sheetSheet2 = await Assets.load(LoadFile.sheetSheet2[0]);

      seperatePayline = LoadFile?.payLines;

      const spriteA00 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB00 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay00 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA01 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB01 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay01 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA02 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB02 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay02 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );

      const spriteA10 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteAOverlay10 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteB10 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA11 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteAOverlay11 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteB11 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA12 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteAOverlay12 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteB12 = new AnimatedSprite(sheetSheet2.animations.enemy);

      const spriteA20 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB20 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay20 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA21 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB21 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay21 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA22 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB22 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay22 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );

      const spriteA30 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB30 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay30 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA31 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB31 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay31 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA32 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB32 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay32 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );

      const spriteA40 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB40 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay40 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA41 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB41 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay41 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );
      const spriteA42 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB42 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteAOverlay42 = new AnimatedSprite(
        sheetSheetoverlay.animations.enemy
      );

      function animateSpin(prizeData, sunfire) {
        spriteB00.removeChild(SunFireText);
        SunFireText = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4,
            },
          })
        );
        // SunFireText = new Text(`${sunfire !='RegularPrize' && sunfire != undefined && sunfire != "" ? sunfire?.toUpperCase() : prizeData}`, new TextStyle({ fontSize: window.innerWidth > 480 ? 50 : 60, fill: (sunfire == 'Minor' ? 'green' : sunfire == 'Mini' ? '#6356f3' : sunfire == 'Major'? 'yellow': '#fff'), fontFamily: "sans-serif", fontWeight: "bolder", stroke: window.innerWidth > 480 ? { color: "#000", width: 3 } : { color: "#000", width: 2 } }));
        SunFireText.scale.set(1.2);
        // spriteA00.anchor.set(0.5);
        // spriteA00.animationSpeed = 1.5;
        // spriteA00.loop = false;
        // spriteA00.play();
        spriteB00.anchor.set(0.5);
        spriteB00.animationSpeed = 1.5;
        spriteB00.animationSpeed = 1.5;

        // spriteA00.width = animationSize.width - 5;
        // spriteA00.height = animationSize.height - 32;
        // spriteA00.height = animationSize.height - 15;
        // spriteA00.width =
        //   window.innerWidth > 480 ? app.screen.width / 10 : X1percent * 18;
        // spriteA00.height =
        //   window.innerWidth > 480
        //     ? app.screen.height / 5
        //     : app.screen.height / 10;
        spriteB00.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB00.height = app.screen.height / 2.22;
        spriteB00.play();
        spriteAOverlay00.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay00.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay00.anchor.set(0.5);
        spriteAOverlay00.animationSpeed = 0.5;
        spriteAOverlay00.play();
        spriteAOverlay00.loop = false;

        if (SunFireText.text !== "undefined" && prizeData > 0)
        SunFireText.anchor.set(0.5);
        SunFireText.position.set(4, 9);
        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay00.addChild(spriteB00);
          stickyContainer.addChild(spriteAOverlay00);
          stickyContainerindex[0] = 1;
          spriteB00.addChild(SunFireText);

        } else {
          // spriteA00.addChild(spriteB00);
          // app.stage.addChildAt(spriteA00, 22);
        }
      }

      function animateSpin1(prizeData, sunfire) {
        spriteB01.removeChild(SunFireText1);
        SunFireText1 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText1.scale.set(1.2);


        // spriteA01.anchor.set(0.5);
        // spriteA01.animationSpeed = 1.5;
        // spriteA01.loop = false;
        // spriteA01.play();
        spriteB01.anchor.set(0.5);
        spriteB01.animationSpeed = 1.5;
        spriteB01.animationSpeed = 1.5;

        // spriteA01.width = animationSize.width - 5;
        // spriteA01.height = animationSize.height - 32;
        // spriteA01.height = animationSize.height - 15;
        // spriteA01.width =
        //   window.innerWidth > 480 ? app.screen.width / 10 : X1percent * 18;
        // spriteA01.height =
        //   window.innerWidth > 480
        //     ? app.screen.height / 5
        //     : app.screen.height / 10;
        spriteB01.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB01.height = app.screen.height / 2.22;
        spriteB01.play();
        spriteAOverlay01.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 14;
        spriteAOverlay01.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 8;
        spriteAOverlay01.anchor.set(0.5);
        spriteAOverlay01.animationSpeed = 0.5;
        spriteAOverlay01.play();
        spriteAOverlay01.loop = false;

        if (SunFireText1.text !== "undefined" && prizeData > 0)
        SunFireText1.anchor.set(0.5);
        SunFireText1.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay01.addChild(spriteB01);
          stickyContainer.addChild(spriteAOverlay01);
          stickyContainerindex[1] = 1;
          spriteB01.addChild(SunFireText1);

        } else {
          // spriteA01.addChild(spriteB01);
          // app.stage.addChildAt(spriteA01, 22);
        }
      }

      function animateSpin2(prizeData, sunfire) {
        spriteB02.removeChild(SunFireText2);
        SunFireText2 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText2.scale.set(1.2);

        // spriteA02.anchor.set(0.5);
        // spriteA02.animationSpeed = 1.5;
        // spriteA02.loop = false;
        // spriteA02.play();
        spriteB02.anchor.set(0.5);
        spriteB02.animationSpeed = 1.5;
        spriteB02.animationSpeed = 1.5;

        // spriteA02.width = animationSize.width - 5;
        // spriteA02.height = animationSize.height - 32;
        // spriteA02.height = animationSize.height - 15;
        // spriteA02.width =
        //   window.innerWidth > 480 ? app.screen.width / 10 : X1percent * 18;
        // spriteA02.height =
        //   window.innerWidth > 480
        //     ? app.screen.height / 5
        //     : app.screen.height / 10;
        spriteB02.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB02.height = app.screen.height / 2.22;
        spriteB02.play();
        spriteAOverlay02.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay02.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay02.anchor.set(0.5);
        spriteAOverlay02.animationSpeed = 0.5;
        spriteAOverlay02.play();
        spriteAOverlay02.loop = false;
        if (SunFireText2.text !== "undefined" && prizeData > 0)
          
        SunFireText2.anchor.set(0.5);
        SunFireText2.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay02.addChild(spriteB02);
          stickyContainer.addChild(spriteAOverlay02);
          stickyContainerindex[2] = 1;
          spriteB02.addChild(SunFireText2);
        } else {
          // spriteA02.addChild(spriteB02);
          // app.stage.addChildAt(spriteA02, 22);
        }
      }

   
      function animateSpin3(prizeData, sunfire) {
        spriteB10.removeChild(SunFireText3);
        SunFireText3 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText3.scale.set(1.2)

        // spriteA10.width = animationSize.width - 5;
        // spriteA10.height = animationSize.height - 32;
        // spriteA10.height = animationSize.height - 15;
        // spriteA10.width =
        //   window.innerWidth > 480 ? app.screen.width / 10 : X1percent * 18;
        // spriteA10.height =
        //   window.innerWidth > 480
        //     ? app.screen.height / 5
        //     : app.screen.height / 10;
        spriteAOverlay10.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay10.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteB10.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB10.height = app.screen.height / 2.22;
        spriteA10.anchor.set(0.5);
        spriteA10.animationSpeed = 0.5;
        spriteA10.loop = false;
        spriteA10.play();
        spriteB10.anchor.set(0.5);
        spriteB10.animationSpeed = 0.5;
        spriteB10.animationSpeed = 0.5;
        spriteB10.play();
        spriteAOverlay10.anchor.set(0.5);
        spriteAOverlay10.animationSpeed = 0.5;
        spriteAOverlay10.animationSpeed = 0.5;
        spriteAOverlay10.play();
        spriteAOverlay10.loop = false;

        if (SunFireText3.text !== "undefined" && prizeData > 0)
        SunFireText3.anchor.set(0.5);
        SunFireText3.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay10.addChild(spriteB10);
          stickyContainer.addChild(spriteAOverlay10);
          stickyContainerindex[3] = 1;
          spriteB10.addChild(SunFireText3);

        } else {
          // spriteA10.addChild(spriteB10);
          // app.stage.addChildAt(spriteA10, 22);
        }
      }

      function animateSpin4(prizeData, sunfire) {
        spriteB11.removeChild(SunFireText4);
        SunFireText4 = new Text(
          `${
           sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText4.scale.set(1.2)

        // spriteA11.width = animationSize.width - 5;
        // spriteA11.height = animationSize.height - 32;
        // spriteA11.height = animationSize.height - 15;
        // spriteA11.width =
        //   window.innerWidth > 480 ? app.screen.width / 10 : X1percent * 18;
        // spriteA11.height =
        //   window.innerWidth > 480
        //     ? app.screen.height / 5
        //     : app.screen.height / 10;
        spriteAOverlay11.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay11.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay11.anchor.set(0.5);
        spriteAOverlay11.animationSpeed = 0.5;
        spriteAOverlay11.play();
        spriteAOverlay11.loop = false;
        spriteB11.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB11.height = app.screen.height / 2.22;
        spriteA11.anchor.set(0.5);
        spriteA11.animationSpeed = 0.5;
        spriteA11.loop = false;
        spriteA11.play();
        spriteB11.anchor.set(0.5);
        spriteB11.animationSpeed = 0.5;
        spriteB11.animationSpeed = 0.5;
        spriteB11.play();
        if (SunFireText4.text !== "undefined" && prizeData > 0)
        SunFireText4.anchor.set(0.5);
        SunFireText4.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay11.addChild(spriteB11);
          stickyContainer.addChild(spriteAOverlay11);
          stickyContainerindex[4] = 1;
          spriteB11.addChild(SunFireText4);

        } else {
          // spriteA11.addChild(spriteB11);
          // app.stage.addChildAt(spriteA11, 22);
        }
      }

      function animateSpin5(prizeData, sunfire) {
        spriteB12.removeChild(SunFireText5);
        SunFireText5 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
       SunFireText5.scale.set(1.2);
        // spriteA12.width = animationSize.width - 5;
        // spriteA12.height = animationSize.height - 32;
        spriteB12.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB12.height = app.screen.height / 2.22;
        // spriteA12.anchor.set(0.5);
        // spriteA12.animationSpeed = 0.5;
        // spriteA12.loop = false;
        // spriteA12.play();
        spriteB12.anchor.set(0.5);
        spriteB12.animationSpeed = 0.5;
        spriteB12.animationSpeed = 0.5;
        spriteB12.play();
        spriteAOverlay12.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay12.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay12.anchor.set(0.5);
        spriteAOverlay12.animationSpeed = 0.5;
        spriteAOverlay12.play();
        spriteAOverlay12.loop = false;
        if (SunFireText5.text !== "undefined" && prizeData > 0)
        SunFireText5.anchor.set(0.5);
        SunFireText5.position.set(4, 9);
        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay12.addChild(spriteB12);
          stickyContainer.addChild(spriteAOverlay12);
          stickyContainerindex[5] = 1;
          spriteB12.addChild(SunFireText5);

        } else {
          // spriteA12.addChild(spriteB12);
          // app.stage.addChildAt(spriteA12, 22);
        }
      }

      function animateSpin6(prizeData, sunfire) {
        spriteB20.removeChild(SunFireText6);
        SunFireText6 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText6.scale.set(1.2);
        // spriteA20.width = animationSize.width - 5;
        // spriteA20.height = animationSize.height - 32;
        spriteB20.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB20.height = app.screen.height / 2.22;
        // spriteA20.anchor.set(0.5);
        // spriteA20.animationSpeed = 0.5;
        // spriteA20.loop = false;
        // spriteA20.play();
        spriteB20.anchor.set(0.5);
        spriteB20.animationSpeed = 0.5;
        spriteB20.animationSpeed = 0.5;
        spriteB20.play();
        spriteAOverlay20.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay20.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay20.anchor.set(0.5);
        spriteAOverlay20.animationSpeed = 0.5;
        spriteAOverlay20.play();
        spriteAOverlay20.loop = false;
        if (SunFireText6.text !== "undefined" && prizeData > 0)
         
        SunFireText6.anchor.set(0.5);
        SunFireText6.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay20.addChild(spriteB20);
          stickyContainer.addChild(spriteAOverlay20);
          stickyContainerindex[6] = 1;
          spriteB20.addChild(SunFireText6);
        } else {
          // spriteA20.addChild(spriteB20);
          // app.stage.addChildAt(spriteA20, 22);
        }
      }

      function animateSpin7(prizeData, sunfire) {
        spriteB21.removeChild(SunFireText7);
        SunFireText7 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
           stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText7.scale.set(1.2);

        // spriteA21.width = animationSize.width - 5;
        // spriteA21.height = animationSize.height - 32;
        spriteB21.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;

        spriteB21.height = app.screen.height / 2.22;
        // spriteA21.anchor.set(0.5);
        // spriteA21.animationSpeed = 0.5;
        // spriteA21.loop = false;
        // spriteA21.play();
        spriteB21.anchor.set(0.5);
        spriteB21.animationSpeed = 0.5;
        spriteB21.animationSpeed = 0.5;
        spriteB21.play();
        spriteAOverlay21.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay21.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay21.anchor.set(0.5);
        spriteAOverlay21.animationSpeed = 0.5;
        spriteAOverlay21.play();
        spriteAOverlay21.loop = false;
        if (SunFireText7.text !== "undefined" && prizeData > 0)
          
        SunFireText7.anchor.set(0.5);
        SunFireText7.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay21.addChild(spriteB21);
          stickyContainer.addChild(spriteAOverlay21);
          stickyContainerindex[7] = 1;
          spriteB21.addChild(SunFireText7);
        } else {
          // spriteA21.addChild(spriteB21);
          // app.stage.addChildAt(spriteA21, 22);
        }
      }

      function animateSpin8(prizeData, sunfire) {
        spriteB22.removeChild(SunFireText8);
        SunFireText8 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText8.scale.set(1.2);

        // spriteA22.width = animationSize.width - 5;
        // spriteA22.height = animationSize.height - 32;
        spriteB22.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB22.height = app.screen.height / 2.22;
        spriteB22.height = app.screen.height / 2.22;
        // spriteA22.anchor.set(0.5);
        // spriteA22.animationSpeed = 0.5;
        // spriteA22.loop = false;
        // spriteA22.play();
        spriteB22.anchor.set(0.5);
        spriteB22.animationSpeed = 0.5;
        spriteB22.animationSpeed = 0.5;
        spriteB22.play();
        spriteAOverlay22.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay22.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay22.anchor.set(0.5);
        spriteAOverlay22.animationSpeed = 0.5;
        spriteAOverlay22.play();
        spriteAOverlay22.loop = false;
        if (SunFireText8.text !== "undefined" && prizeData > 0)
        SunFireText8.anchor.set(0.5);
        SunFireText8.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay22.addChild(spriteB22);
          stickyContainer.addChild(spriteAOverlay22);
          stickyContainerindex[8] = 1;
          spriteB22.addChild(SunFireText8);

        } else {
          // spriteA22.addChild(spriteB22);
          // app.stage.addChildAt(spriteA22, 22);
        }
      }

      function animateSpin9(prizeData, sunfire) {
        spriteB30.removeChild(SunFireText9);

        SunFireText9 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
       SunFireText9.scale.set(1.2);

        // spriteA30.width = animationSize.width - 5;
        // spriteA30.height = animationSize.height - 32;
        spriteB30.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB30.height = app.screen.height / 2.22;
        // spriteA30.anchor.set(0.5);
        // spriteA30.animationSpeed = 0.5;
        // spriteA30.loop = false;
        // spriteA30.play();
        spriteB30.anchor.set(0.5);
        spriteB30.animationSpeed = 0.5;
        spriteB30.animationSpeed = 0.5;
        spriteB30.play();
        spriteAOverlay30.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay30.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay30.anchor.set(0.5);
        spriteAOverlay30.animationSpeed = 0.5;
        spriteAOverlay30.play();
        spriteAOverlay30.loop = false;
        if (SunFireText9.text !== "undefined" && prizeData > 0)
        SunFireText9.anchor.set(0.5);
        SunFireText9.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay30.addChild(spriteB30);
          stickyContainer.addChild(spriteAOverlay30);
          stickyContainerindex[9] = 1;
          spriteB30.addChild(SunFireText9);

        } else {
          // spriteA30.addChild(spriteB30);
          // app.stage.addChildAt(spriteA30, 22);
        }
      }

      function animateSpin10(prizeData, sunfire) {

        spriteB31.removeChild(SunFireText10);

        // spriteA31.width = animationSize.width - 5;
        // spriteA31.height = animationSize.height - 32;
        spriteB31.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB31.height = app.screen.height / 2.22;
        // spriteA31.anchor.set(0.5);
        // spriteA31.animationSpeed = 0.5;
        // spriteA31.loop = false;
        // spriteA31.play();
        spriteB31.anchor.set(0.5);
        spriteB31.animationSpeed = 0.5;
        spriteB31.animationSpeed = 0.5;
        spriteAOverlay31.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay31.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay31.anchor.set(0.5);
        spriteAOverlay31.animationSpeed = 0.5;
        spriteAOverlay31.play();
        spriteAOverlay31.loop = false;
        SunFireText10 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText10.scale.set(1.2);

        if (SunFireText10.text !== "undefined" && prizeData > 0)
        SunFireText10.anchor.set(0.5);
        SunFireText10.position.set(4, 9);
        spriteB31.play();

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay31.addChild(spriteB31);
          stickyContainer.addChild(spriteAOverlay31);
          stickyContainerindex[10] = 1;
          spriteB31.addChild(SunFireText10);

        } else {
          // spriteA31.addChild(spriteB31);
          // app.stage.addChildAt(spriteA31, 22);
        }
      }

      function animateSpin11(prizeData, sunfire) {

        spriteB32.removeChild(SunFireText11);

        // spriteA32.width = animationSize.width - 5;
        // spriteA32.height = animationSize.height - 32;
        spriteB32.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB32.height = app.screen.height / 2.22;
        // spriteA32.anchor.set(0.5);
        // spriteA32.animationSpeed = 0.5;
        // spriteA32.loop = false;
        // spriteA32.play();
        spriteB32.anchor.set(0.5);
        spriteB32.animationSpeed = 0.5;
        spriteB32.animationSpeed = 0.5;
        spriteB32.play();
        spriteAOverlay32.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay32.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay32.anchor.set(0.5);
        spriteAOverlay32.animationSpeed = 0.5;
        spriteAOverlay32.play();
        spriteAOverlay32.loop = false;
        SunFireText11 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText11.scale.set(1.2);

        if (SunFireText11.text !== "undefined" && prizeData > 0)
        SunFireText11.anchor.set(0.5);
        SunFireText11.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay32.addChild(spriteB32);
          stickyContainer.addChild(spriteAOverlay32);
          stickyContainerindex[11] = 1;
          spriteB32.addChild(SunFireText11);

        } else {
          // spriteA32.addChild(spriteB32);
          // app.stage.addChildAt(spriteA32, 22);
        }
      }

      function animateSpin12(prizeData, sunfire) {

        spriteB40.removeChild(SunFireText12);

        spriteB40.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB40.height = app.screen.height / 2.22;
        spriteB40.anchor.set(0.5);
        spriteB40.animationSpeed = 0.5;
        spriteB40.animationSpeed = 0.5;
        spriteB40.play();
        spriteAOverlay40.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay40.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay40.anchor.set(0.5);
        spriteAOverlay40.animationSpeed = 0.5;
        spriteAOverlay40.play();
        spriteAOverlay40.loop = false;
        SunFireText12 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText12.scale.set(1.2);

        if (SunFireText12.text !== "undefined" && prizeData > 0)
        SunFireText12.anchor.set(0.5);
        SunFireText12.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay40.addChild(spriteB40);
          stickyContainer.addChild(spriteAOverlay40);
          stickyContainerindex[12] = 1;
          spriteB40.addChild(SunFireText12);

        } else {
          // spriteA40.addChild(spriteB40);
          // app.stage.addChildAt(spriteA40, 22);
        }
      }

      function animateSpin13(prizeData, sunfire) {
        spriteB41.removeChild(SunFireText13);

        // spriteA41.width = animationSize.width - 5;
        // spriteA41.height = animationSize.height - 32;
        spriteB41.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB41.height = app.screen.height / 2.22;
        // spriteA41.anchor.set(0.5);
        // spriteA41.animationSpeed = 0.5;
        // spriteA41.loop = false;
        // spriteA41.play();
        spriteB41.anchor.set(0.5);
        spriteB41.animationSpeed = 0.5;
        spriteB41.animationSpeed = 0.5;
        spriteB41.play();
        spriteAOverlay41.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay41.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay41.anchor.set(0.5);
        spriteAOverlay41.animationSpeed = 0.5;
        spriteAOverlay41.play();
        spriteAOverlay41.loop = false;
        SunFireText13 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText13.scale.set(1.2);

        if (SunFireText13.text !== "undefined" && prizeData > 0)
        SunFireText13.anchor.set(0.5);
        SunFireText13.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          spriteAOverlay41.addChild(spriteB41);
          stickyContainer.addChild(spriteAOverlay41);
          stickyContainerindex[13] = 1;
          spriteB41.addChild(SunFireText13);

        } else {
          // spriteA41.addChild(spriteB41);
          // app.stage.addChildAt(spriteA41, 22);
        }
      }

      function animateSpin14(prizeData, sunfire) {
        spriteB42.removeChild(SunFireText14);

        // spriteA42.width = animationSize.width - 5;
        // spriteA42.height = animationSize.height - 32;
        spriteB42.width =
          window.innerWidth > 480
            ? app.screen.width / 4.45
            : app.screen.width / 1;
        spriteB42.height = app.screen.height / 2.22;
        // spriteA42.anchor.set(0.5);
        // spriteA42.animationSpeed = 0.5;
        // spriteA42.loop = false;
        // spriteA42.play();
        spriteB42.anchor.set(0.5);
        spriteB42.animationSpeed = 0.5;
        spriteB42.animationSpeed = 0.5;
        spriteB42.play();
        spriteAOverlay42.width =
          window.innerWidth > 480 ? app.screen.width / 8.7 : X1percent * 17.5;
        spriteAOverlay42.height =
          window.innerWidth > 480 ? app.screen.height / 5 : Y1percent * 9;
        spriteAOverlay42.anchor.set(0.5);
        spriteAOverlay42.animationSpeed = 0.5;
        spriteAOverlay42.play();
        spriteAOverlay42.loop = false;
        SunFireText14 = new Text(
          `${
            sunfire != "RegularPrize" && sunfire != undefined && sunfire != ""
              ? sunfire?.toUpperCase()
              : Math.round(prizeData)
          }`,
          new TextStyle({
            fontSize: window.innerWidth > 480 ? 50 : 60,
            fill:
              sunfire == "Minor"
                ? "green"
                : sunfire == "Mini"
                ? "#6356f3"
                : sunfire == "Major"
                ? "yellow"
                : "#fff",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            stroke: {
              color:
                sunfire == "Minor"
                  ? "white "
                  : sunfire == "Mini"
                  ? "black"
                  : sunfire == "Major"
                  ? "red"
                  : "#fff",
              lineWidth: 4, // set the stroke width to 4
            },
          })
        );
        SunFireText14.scale.set(1.2);

        if (SunFireText14.text !== "undefined" && prizeData > 0)
        SunFireText14.anchor.set(0.5);
        SunFireText14.position.set(4, 9);

        if (spaceFilledWtihFire >= 6) {
          app.stage.removeChild(spriteAOverlay42);
          spriteAOverlay42.addChild(spriteB42);
          stickyContainer.addChild(spriteAOverlay42);
          stickyContainerindex[14] = 1;
          spriteB42.addChild(SunFireText14);

        } else {
          // spriteA42.addChild(spriteB42);
          // app.stage.addChildAt(spriteA42, 22);
        }
      }

      //assets Loaded
      setTimeout(() => {
        setLoder(!loder);
      }, 1000);
      // setProgress(100);

      var creditsVal;
      var winCount;
      let dollarWinValue;
      var freeSpin;
      var currentfreeSpinGameLoad;
      var currentStickySpinGameLoad;
      var currentfreeSpin;
      var freeGamesWin;
      var winValue = 0;
      var winAmountShow = [];
      let remainStickySpin = false;

      function formatNumber(value) {
        return Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      async function getProgressiveJackpot() {
        try {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/GamePlay/ProgressiveJackpot`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((jackpotData) => {
              GrandText.text =
                "$" +
                formatNumber(jackpotData?.data?.resultData[0]?.jackpotAmount);
              MajorText.text =
                "$" +
                formatNumber(jackpotData?.data?.resultData[1]?.jackpotAmount);
            });
        } catch (error) {
          if (error?.response?.status === 401) {
            localStorage.removeItem("playerId");
            localStorage.removeItem("token");
            sessionExpired();
          }
          console.log("jackpot error:", error);
        }
      }

      function sessionExpired() {
        setSessionExpired(!isSessionExpired);
      }
      let data;
      let gameLoadComingStickData;
      var denominationArr;   
      try {
        gameLoadComingStickData = data = await gameLoadDataApi();
        denominationArr = data?.gameDenoms;
        creditsVal = (data?.playerBalance*100) || 0;
        currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin;
        currentStickySpinGameLoad = data?.noOfRemaningStickySpin || 0;
        defaultLang = data?.defaulLanguage === "Spanish" ? "es" : "en";
        
        if (window.location.pathname == "/atlantis-legend") {
          localStorage.setItem("defaultLang", defaultLang);
        } else {
          localStorage.removeItem("defaultLang");
        }
        if (currentStickySpinGameLoad > 0) {
          remainStickySpin = true;
          spaceFilledWtihFire = 0;
        }
      } catch (error) {}
      var imgArrSlot = [];

      let filteredArr = denominationArr.filter(item => item.enabled);
      coinDenonimation = filteredArr[0].stringAmount*100;

      let reelData = data?.defaultCombination?.split(",");

      function getImgWithUrl(name) {
        return slotTextures.filter((el, i) => (el.name === name ? el : ""));
      }
      for (let i = 0; i < reelData?.length; i++) {
        imgArrSlot?.push(getImgWithUrl(reelData[i])[0]);
      }
      

      var singlePaylinesNum = [];
      var paylinesNum = [];
      //  game spin api function
     
      var startCount = 0;
      var payLineIntervel;

     
      // var betAmountValue = 50;
      var betVal = 50;
      var payLineAnimateArr;
      var winningSymbolArr;
      var remaingFreeSpin = 0;
      var occurenceArr;
      var fireSymbolCount;
      function calculateOutput(value) {
        if (value >= 500) {
          return "10.00";
        } else if (value >= 250) {
          return "5.00";
        } else if (value >= 150) {
          return "3.00";
        } else if (value >= 100) {
          return "2.00";
        } else if (value >= 50) {
          return "1.00";
        }
      }
      async function gameSpinApi(spinType) {
        payLineTrueHandle();
        
        var betAmountNewVal = calculateOutput(betVal);
        try {
          const time = new Date("2015-03-25");
          const dataApi = await axios?.post(
            `${process.env.REACT_APP_API_URL}/GamePlay/Bet/Bet`,
            {
              gameId: gameType.MexTest,
              playerId: playerId,
              denomination: (coinDenonimation / 100).toFixed(2),
              betAmount: 1.0,
              // betAmount: betAmountNewVal,
              multiplier: betVal / 50,
              isfreeSpinWon: spinType,
              isStickySpinWon: IsStickeySpinWon,
              testStickey: testKeyVar,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const time1 = new Date("2015-03-25");
          if (dataApi?.data?.resultData == null) {
            Swal.fire({
              title: "Error!",
              text: "Server not responded, Try Again!",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Retry!",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                startPlay();
              }
            });
          } else if (dataApi?.data?.StatusCode == 0) {
            Swal.fire({
              title: "Error!",
              text: dataApi?.data?.Message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Retry!",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                startPlay();
              }
            });
          }
          imgArrSlot = [];
          hitApi = true;
          StickeySpinWon = dataApi?.data?.resultData?.isStickeyspinWon;
          winCount = dataApi?.data?.resultData?.winValue || 0;
          dollarWinValue = dataApi?.data?.resultData?.dollarWinValue || 0;
          freeSpin = dataApi?.data?.resultData?.remaningFreeSpinCount || 0;
          freeGamesWin = dataApi?.data?.resultData?.isFreeSpinWon;
          currentfreeSpin = dataApi?.data?.resultData?.noOfFreeSpinWon || 0;
          reelIndex = dataApi?.data?.resultData?.reelIndex
            ? dataApi?.data?.resultData?.reelIndex
            : "";
          slotIndex = dataApi?.data?.resultData?.slotIndex
            ? dataApi?.data?.resultData?.slotIndex
            : "";

          spaceFilledWtihFire = dataApi?.data?.resultData?.spaceFilledWtihFire;
          totalFireAmount = dataApi?.data?.resultData?.totalFireAmount;
          totalFireAmountInDollar = dataApi?.data?.resultData?.totalFireAmountInDollar;
          totalStickySpinAmountInCredits = dataApi?.data?.resultData?.totalStickySpinAmountInCredits;
          totalFreeStickyAmountInCredits = dataApi?.data?.resultData?.totalFreeStickyAmountInCredits;
          totalFreeStickyAmountInCreditsInDollar = dataApi?.data?.resultData?.totalFreeStickyAmountInCreditsInDollar;
          totalFreegameWinCredits = dataApi?.data?.resultData?.totalFreegameWinCredits;





          remaningStickeySpin = dataApi?.data?.resultData?.remaningStickeySpin;
          currentStickySpinGameLoad = remaningStickeySpin;
    
          remaingFreeSpin = dataApi?.data?.resultData?.remaningFreeSpinCount;
          grandJackpotAmount = dataApi?.data?.resultData?.grandJackpotAmount;
          if (count == 0) {
            spaceFilledWtihFire = spaceFilledWtihFire;
            
          } else {
            remaningStickeySpin = dataApi?.data?.resultData?.remaningStickeySpin
              ? remaningStickeySpin
              : "";
            setTimeout(() => {
              spaceFilledWtihFire =
                dataApi?.data?.resultData?.spaceFilledWtihFire >= 6
                  ? spaceFilledWtihFire
                  : countTTSym;
            }, 3000);
          }

          let dummuwiningCombination;
          count = count + 1;
          if(count == 2){
            //testKeyVar = 4;
          }
          sunFireDataObj = {
            prizeAmount: dataApi?.data?.resultData?.prizeAmount || [],
            prizeType: dataApi?.data?.resultData?.prizeType || [],
            reelIndex: dataApi?.data?.resultData?.reelIndex || [],
            slotIndex: dataApi?.data?.resultData?.slotIndex || [],
          };

          linkPrizeAmount = dataApi?.data?.resultData?.linkPrizeAmount
            ? dataApi?.data?.resultData?.linkPrizeAmount
                ?.split(",")
                ?.map((val) => parseFloat(val).toFixed(0))
            : dataApi?.data?.resultData?.prizeAmount?.map((val) => parseFloat(val).toFixed(0) );
            fireSymbolCount = dataApi?.data?.resultData?.winningCombination?.split(",");

          prizeTypeForStickeySpin =
            dataApi?.data?.resultData?.prizeTypeForStickeySpin != null
              ? dataApi?.data?.resultData?.prizeTypeForStickeySpin?.split(",")
              : dataApi?.data?.resultData?.prizeTypeForStickeySpin == null &&
                dataApi?.data?.resultData?.spaceFilledWtihFire >= 6
              ? dataApi?.data?.resultData?.prizeType
              : [];

          countTTSym = fireSymbolCount?.filter(
            (symbol) => symbol === "TT"
          )?.length;
          let newSlotArr = dataApi?.data?.resultData?.winningCombination?.split(",");

          creditsVal = (dataApi?.data?.resultData?.playerCurrentBalance*100);
          winValue = dataApi?.data?.resultData?.finalFreeSpinWinning > 0
              ? dataApi?.data?.resultData?.finalFreeSpinWinning : dataApi?.data?.resultData?.winValue;

            dollarWinValue =  dataApi?.data?.resultData?.dollarFinalFreeSpinWinning > 0
              ? dataApi?.data?.resultData?.dollarFinalFreeSpinWinning : dataApi?.data?.resultData?.dollarWinValue;
              
          if(freeGamesWin == true && StickeySpinWon == true) stickyspinType = true;   // for (freeGame + sticky spin) feature
          if(freeGamesWin == true) freespinTypebool = true;  //// for freeGame spin feature
          if(freeGamesWin == false && StickeySpinWon == true) stickyspinType = true  //  for sticky spin
          if(freeGamesWin == false && StickeySpinWon == false && spaceFilledWtihFire < 6) stickyspinType = false  //  for sticky spin
           //  for sticky spin
          // console.log(freeGamesWin,StickeySpinWon,spaceFilledWtihFire)

          // if (winValue > 0 && remaingFreeSpin >= 0 && freeGamesWin == true) {
          //   setTimeout(() => {
          //     playFreeWinAudio();
          //   }, 2000);
          // }
          finalFreeSpinWinning =
            dataApi?.data?.resultData?.finalFreeSpinWinning;

            finalFreeSpinWinning = dataApi?.data?.resultData?.finalFreeSpinWinning;
          
          addStickyNumber(
            reelIndex,
            slotIndex,
            dataApi?.data?.resultData?.prizeAmount?.map((val) =>
              parseFloat(val).toFixed(0)
            ),
            dataApi?.data?.resultData?.prizeType
          );
          function getImgWithUrl(name) {
            return slotTextures.filter((el, i) => (el.name === name ? el : ""));
          }
          imgArrSlot = [];
          for (let i = 0; i < newSlotArr?.length; i++) {
            imgArrSlot?.push(getImgWithUrl(newSlotArr[i])[0]);
          }
          if (
            dataApi?.data?.resultData?.winValue > 0 &&
            dataApi?.data?.resultData?.winningAtPayLines !== " "
          ) {
            if (
              dataApi?.data?.resultData?.winningAtPayLines?.includes(",") &&
              dataApi?.data?.resultData?.winningAtPayLines?.length > 0
            ) {
              paylinesNum =
                dataApi?.data?.resultData?.winningAtPayLines?.split(",");
              winAmountShow =
                dataApi?.data?.resultData?.winningByPaylines?.split(",");
            } else if (
              dataApi?.data?.resultData?.winningAtPayLines?.length > 0 &&
              dataApi?.data?.resultData?.winningAtPayLines?.length <= 2
            ) {
              const singleLine = dataApi?.data?.resultData?.winningAtPayLines;
              const singleWinning =
                dataApi?.data?.resultData?.winningByPaylines;
              winAmountShow = [];
              paylinesNum.push(singleLine);
              winAmountShow.push(singleWinning);
            }
            let payLineAnimate = dataApi?.data?.resultData?.winningIndexes;

            payLineAnimateArr = payLineAnimate
              .split("/")
              .map((row) => row?.split(" ")?.map(Number));
            winningSymbolArr =
              dataApi?.data?.resultData?.winingSymbol?.split(",");

            occurenceArr =
              dataApi?.data?.resultData?.symbolOccurrence.split(",");
            setTimeout(() => {
              singlePaylinesNum = [...singlePaylinesNum, paylinesNum];
            });
            setTimeout(() => {
              if (
                freeGamesWin == true &&
                dataApi?.data?.resultData?.winValue >= 0 && currentfreeSpin >=5
              ) {
                SpinBtn.visible = false;
                SpinBtn2.visible = false;
                // bottom.eventMode = "none";
                // bottom.cursor = "none";

                

                if (hitflag == false) {
                  noOfFreeSpinWonHandle();
                }
              } else if (freeGamesWin == true && remaingFreeSpin >= 5 ) {
                // bottom.eventMode = "none";
                // bottom.cursor = "none";
                SpinBtn.visible = false;
                SpinBtn2.visible = false;
                noOfFreeSpinWonHandle();
                
              }
            }, 1000);
            if (occurenceArr) {
              setTimeout(() => {
                createLinesBunch_No(paylinesNum);
              }, 1800);
            }
          }
          // startPlay()
          return imgArrSlot;
        } catch (error) {
          console.error("Error:", error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("playerId");
            localStorage.removeItem("token");
            sessionExpired();
          } else {
            Swal.fire({
              title: "Error!",
              text: "Server error, Try Again!",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Retry!",
            }).then((result) => {
              if (result.isConfirmed) {
                clearInterval(stickySpininterval);
                startPlay();
              }
            });
          }
        }
      }

      // *****************************  get sprite sheet function by name  *********************************//
      // Load all the sprite sheet JSON files
      if (Window.innerWidth > 480) {
        slotSpriteSheet = LoadFile?.slotSpriteSheet;
      } else {
        slotSpriteSheet = LoadFile_mobile?.slotSpriteSheet;
      }
      Promise.all(slotSpriteSheet.map((url) => Assets.load(url)))
        .then((textures) => {
          // console.log("All sprite sheets loaded successfully!");
        })
        .catch((error) => {
          console.error("Error loading sprite sheets:", error);
        });

      let sheetSheet1;
      let slotNameArr = ["CC", "FF", "WW", "EE", "SS", "GG", "DD"];
      let showImg;
      let spriteImg = ["HH", "KK", "II", "JJ"];
      async function getSpriteSheet(name) {
        return slotSpriteSheet[slotNameArr.indexOf(name)]
          ? slotSpriteSheet[slotNameArr.indexOf(name)]
          : "PP";
      }

      getSpriteSheet();
      var removeAnimSprite = [];
      async function animateSpinSprite(img, cordinate) {
        let { xvalue, yvalue } = cordinate;
        let result = slotNameArr.find((element) => element === img);
        if (result !== img) {
          let spriteImgResult = spriteImg.findIndex(
            (element) => element === img
          );
          showImg = Sprite.from(LoadFile?.slotSpriteImg[spriteImgResult]);
          showImg.y = app.screen.height / yvalue;
          showImg.x = app.screen.width / xvalue;
          showImg.width =
            window.innerWidth > 480
              ? app.screen.width / 9
              : app.screen.width / 6;
          showImg.height =
            window.innerWidth > 480
              ? app.screen.height / 6.6
              : app.screen.height / 16;
          showImg.anchor.set(0.5);
          app.stage.addChildAt(showImg, 30);
          removeAnimSprite.push(showImg);
        } else {
          let name = await getSpriteSheet(img);
          sheetSheet1 = await Assets.load(name);
          let spriteSheet = new AnimatedSprite(sheetSheet1.animations.enemy);
          spriteSheet.width =
            window.innerWidth > 480
              ? app.screen.width / 10
              : app.screen.width / 6;
          spriteSheet.height =
            window.innerWidth > 480
              ? app.screen.height / 5
              : app.screen.height / 13;
          spriteSheet.anchor.set(0.5);
          spriteSheet.animationSpeed = 1.5;
          spriteSheet.loop = true;
          spriteSheet.play();
          spriteSheet.y = app.screen.height / yvalue;
          spriteSheet.x = app.screen.width / xvalue;
          app.stage.addChildAt(spriteSheet, 30);
          removeAnimSprite.push(spriteSheet);
        }
      }

      //**************************** back To Prev Layout Icon & images ************************************* */
      async function backToPrevLayoutIcon(spinType) {
        try {
          let res = await axios.post(
            `${process.env.REACT_APP_API_URL}/GamePlay/BackToLayout?playerId=${playerId}&spinType=${spinType}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res?.data?.resultData == null) {
            Swal.fire({
            title: "Error!",
            text: "Server not responded, Try Again!",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Retry!",
            allowOutsideClick: false,
            }).then((result) => {
            if (result.isConfirmed) {
            backToPreviousLayout();
            }
            });
            }else{
            return res?.data;            
            }
          // return res?.data;
        } catch (error) {
          console.log(error);
        }
      }

      function removeAllSprites() {
        for (let sprite of removeAnimSprite) {
          app.stage.removeChild(sprite);
          sprite.destroy(); // Clean up resources
        }
        removeAnimSprite.length = null; // Clear the array
      }
      //***************************************************************** */
      const BackgroundImg = new Sprite(
        Texture.from("/assets/MainBG/Dorado_Main_BG.png")
      );
      BackgroundImg.height = app.screen.height;
      BackgroundImg.width = app.screen.width;
      BackgroundImg.x = (app.screen.width - BackgroundImg.width) / 2;
      BackgroundImg.y = (app.screen.height - BackgroundImg.height) / 2;
      app.stage.addChild(BackgroundImg);

      const BackgroundImgmobile = new Sprite(
        Texture.from("/assets/Backgrounds/mobile_background.png")
      );
      BackgroundImgmobile.height = app.screen.height;
      BackgroundImgmobile.width = app.screen.width;
      BackgroundImgmobile.x = (app.screen.width - BackgroundImg.width) / 2;
      BackgroundImgmobile.y = (app.screen.height - BackgroundImg.height) / 2;
      app.stage.addChild(BackgroundImgmobile);
      BackgroundImgmobile.visible = false;

      const stickyBackgroundImg = new Sprite(
        Texture.from("/assets/Link/Fondo-Link-01.png")
      );
      stickyBackgroundImg.height = app.screen.height * 2;
      stickyBackgroundImg.width = app.screen.width;
      stickyBackgroundImg.x = (app.screen.width - BackgroundImg.width) / 2;
      stickyBackgroundImg.y = Y1percent * -80;
      stickyBackgroundImg.visible = false;
      app.stage.addChild(stickyBackgroundImg);

      // bet betVal
      const MarginY = 0;

      // Game Frame Image added here
      const GameFrame = new Sprite(
        Texture.from("/assets/Game/Dorado_frame.png")
      );
      GameFrame.height = app.screen.height / 1.5;
      GameFrame.width = app.screen.width / 1.55;
      GameFrame.x = (app.screen.width - GameFrame.width) / 2; // Center horizontally
      GameFrame.y = (app.screen.height - GameFrame.height) / 2; // Center horizontally
      app.stage.addChild(GameFrame);

      // sticky ganeframe
      const stickyGameFrame = new Sprite(
        Texture.from("/assets/Link/Marco-Link.png")
      );
      stickyGameFrame.height = app.screen.height / 1.4;
      stickyGameFrame.width = app.screen.width / 1.47;
      stickyGameFrame.x = (app.screen.width - GameFrame.width) / 2.2; // Center horizontally
      stickyGameFrame.y = (app.screen.height - GameFrame.height) / 2.47; // Center horizontally
      stickyGameFrame.visible = false;

      // Game Frame background
      const GameBG = new Sprite(
        Texture.from("/assets/MainBG/Dorado_background_Game.png")
      );
      GameBG.height = app.screen.height / 1.62;
      GameBG.width = ((app.screen.width - GameFrame.width) * 3.48) / 2;
      GameBG.x = (app.screen.width - GameBG.width) / 2;
      GameBG.y = (app.screen.height - GameBG.height) / 2;

      app.stage.addChild(GameBG);

      // Add play text
      const textStyle = new TextStyle({
        fontSize:
          betVal === 500 ? app.screen.width / 98 : app.screen.width / 54,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: window.innerWidth > 480 ? "bolder" : "normal",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      // Add play text
      const coinDenomTextStyle = new TextStyle({
        fontSize:
          window.innerWidth > 1440
            ? app.screen.width / 50
            : app.screen.width / 54,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      const top = new Graphics()
        .rect(0, 0, app.screen.width, MarginY)
        .fill({ color: 0x0 });
      const bottom = new Graphics()
        .rect(0, 200 * 3 + MarginY, app.screen.width, MarginY)
        .fill({ color: 0x0 });

      //  ******************* Game Top Section Code*********************

      ////***********   Minor text Section starts  *************
      //Minor Text Frame background Image imported here
      const MinorTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Base.png")
      );
      MinorTextFrameBG.height = Y1percent * 11.7;
      MinorTextFrameBG.width = X1percent * 13;
      MinorTextFrameBG.x = (app.screen.width - MinorTextFrameBG.width) / 7.5;
      MinorTextFrameBG.y = (app.screen.height - MinorTextFrameBG.height) / 22;
      app.stage.addChild(MinorTextFrameBG);

      //Minor Text Frame imported here
      const MinorTextFrame = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Contorno.png")
      );
      MinorTextFrame.height = app.screen.height / 9;
      MinorTextFrame.width = X1percent * 13;
      MinorTextFrame.x = (app.screen.width - MinorTextFrame.width) / 7.5;
      MinorTextFrame.y = (app.screen.height - MinorTextFrame.height) / 22;
      app.stage.addChild(MinorTextFrame);

      //Minor Text Png imported here
      const MinorTextImg = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Nombre.png")
      );
      MinorTextImg.height = app.screen.height / 18;
      MinorTextImg.width = app.screen.width / 13;
      MinorTextImg.x = (app.screen.width - MinorTextImg.width) / 6.3;
      MinorTextImg.y = (app.screen.height - MinorTextImg.height) / 30;
      app.stage.addChild(MinorTextImg);

      // Minor Text added here

      // const MinorText = new BitmapText(`$500.00`, textStyle);
      const MinorText = new BitmapText(
        `$${formatter.format(betVal)}.00`,
        textStyle
      );
      MinorText.width =
        window.innerWidth > 1440
          ? app.screen.width / 17.5
          : window.innerWidth > 1366 && window.innerWidth < 1440
          ? app.screen.width / 35
          : window.innerWidth > 932 && window.innerWidth < 1367
          ? app.screen.width / 28
          : window.innerWidth >= 667 && window.innerWidth <= 932
          ? app.screen.width / 20
          : app.screen.width / 10;
      MinorText.x = (app.screen.width - MinorText.width) / 5.1;
      MinorText.anchor.set(0.5);

      MinorText.y = (app.screen.height - MinorTextImg.height) / 9.5;
      top.addChild(MinorText);
      MinorText.visible = true;

      const stickyMinorText = new BitmapText(`$${betVal}.00`, textStyle);
      stickyMinorText.width =
        window.innerWidth > 1440
          ? app.screen.width / 17.5
          : window.innerWidth > 1366 && window.innerWidth < 1440
          ? app.screen.width / 35
          : window.innerWidth > 800 && window.innerWidth < 1367
          ? app.screen.width / 28
          : app.screen.width / 50;
      stickyMinorText.x = (app.screen.width - stickyMinorText.width) / 5.9;
      stickyMinorText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(stickyMinorText);
      stickyMinorText.visible = false;

      ////***************   Grand text Section strats   ***************
      //Grand Text Frame background Image imported here
      const GrandTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Base.png")
      );
      GrandTextFrameBG.height = Y1percent * 14;
      GrandTextFrameBG.width = X1percent * 20;
      GrandTextFrameBG.x = X1percent * 29.5;
      GrandTextFrameBG.y = Y1percent * 2.5;
      app.stage.addChild(GrandTextFrameBG);

      //Grand Text Frame imported here
      const GrandTextFrame = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Contorno1.png")
      );
      GrandTextFrame.height = Y1percent * 14;
      GrandTextFrame.width = X1percent * 20;
      GrandTextFrame.x = X1percent * 29.5;
      GrandTextFrame.y = Y1percent * 2.5;
      app.stage.addChild(GrandTextFrame);

      //Grand Text Frame imported here
      const GrandTextImg = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Nombre.png")
      );
      GrandTextImg.height = app.screen.height / 10;
      GrandTextImg.width = app.screen.width / 8;
      GrandTextImg.x = X1percent * 33.4;
      GrandTextImg.y = Y1percent * -2;
      app.stage.addChild(GrandTextImg);

      // Add Grand text
      // const GrandText = new BitmapText("$1,00,000.00", textStyle);
      const GrandText = new BitmapText(`$${grandValue}`, textStyle);
      GrandText.anchor.set(0.5);
      GrandText.x =
        window.innerWidth > 1600
          ? (app.screen.width - GrandTextImg.width) / 2.23
          : window.innerWidth > 1367 && window.innerWidth < 1599
          ? (app.screen.width - GrandTextImg.width) / 2.35
          : (app.screen.width - GrandTextImg.width) / 2.35;
      GrandText.y = (app.screen.height - MinorTextImg.height) / 10;
      top.addChild(GrandText);
      app.stage.addChild(top);

      ////***************   Major text Section starts   ***************
      //Major Text Frame background Image imported here
      const MajorTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Base.png")
      );
      MajorTextFrameBG.height = X1percent * 6.6;
      MajorTextFrameBG.width = app.screen.width/5;
      MajorTextFrameBG.x = X1percent * 50;
      MajorTextFrameBG.y = Y1percent * 2.65;
      app.stage.addChild(MajorTextFrameBG);

      //Grand Text Frame imported here
      const MajorTextFrame = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Contorno1.png")
      );
      MajorTextFrame.height = app.screen.height / 7.71;
      MajorTextFrame.width = app.screen.width / 5;
      MajorTextFrame.x = (app.screen.width - MajorTextFrame.width) / 1.6;
      MajorTextFrame.y = (app.screen.height - MajorTextFrame.height) / 30;
      app.stage.addChild(MajorTextFrame);

      //Major Text Frame imported here
      const MajorTextImg = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Nombre.png")
      );
      MajorTextImg.height = app.screen.height / 12;
      MajorTextImg.width = app.screen.width / 10;
      MajorTextImg.x = (app.screen.width - MajorTextImg.width) / 1.65;
      MajorTextImg.y = Y1percent * -2;
      app.stage.addChild(MajorTextImg);

      // Add Major text
      const MajorText = new BitmapText(majorValue, textStyle);
      MajorText.x =
        window.innerWidth > 1440
          ? (app.screen.width - MajorTextImg.width) / 1.51
          : (app.screen.width - MajorTextImg.width) / 1.5;
      MajorText.y = (app.screen.height - MinorTextImg.height) / 10;
      MajorText.anchor.set(0.5);

      top.addChild(MajorText);
      app.stage.addChild(top);

      const JackpotValue = await getProgressiveJackpot();
      grandValue = JackpotValue?.find(
        (item) => item?.jackpotName === "Grand"
      )?.jackpotAmount?.toFixed(3);
      majorValue = JackpotValue?.find(
        (item) => item?.jackpotName === "Major"
      )?.jackpotAmount?.toFixed(3);

      ////***************   Mini text section starts   ***************
      //Mini Text Frame background Image imported here
      const MiniTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Mini/Minir-Jackpot-Base.png")
      );
      MiniTextFrameBG.height = Y1percent * 11;
      MiniTextFrameBG.width = X1percent * 13;
      MiniTextFrameBG.x = (app.screen.width - MiniTextFrameBG.width) / 1.156;
      MiniTextFrameBG.y = Y1percent * 3.9;
      app.stage.addChild(MiniTextFrameBG);

      //Mini Text Frame imported here
      const MiniTextFrame = new Sprite(
        Texture.from("/assets/Link/Mini/Minir-Jackpot-Contorno.png")
      );
      MiniTextFrame.height = app.screen.height / 9;
      MiniTextFrame.width = X1percent * 13;
      MiniTextFrame.x = (app.screen.width - MinorTextFrameBG.width) / 1.156;
      MiniTextFrame.y = (app.screen.height - MinorTextFrameBG.height) / 22;
      app.stage.addChild(MiniTextFrame);

      //Mini Text Png imported here
      const MiniTextImg = new Sprite(
        Texture.from("/assets/Link/Mini/Mini-Jackpot-Nombre.png")
      );
      MiniTextImg.height = app.screen.height / 20;
      MiniTextImg.width = app.screen.width / 18;
      MiniTextImg.x = (app.screen.width - MiniTextImg.width) / 1.2;
      MiniTextImg.y = (app.screen.height - MiniTextImg.height) / 25;
      app.stage.addChild(MiniTextImg);

      // Add Mini text
      const MiniText = new BitmapText(`$${10}.00`, textStyle);
      MiniText.width =
        window.innerWidth > 1440
          ? app.screen.width / 17.5
          : window.innerWidth > 1366 && window.innerWidth < 1440
          ? app.screen.width / 35
          : window.innerWidth > 932 && window.innerWidth < 1367
          ? app.screen.width / 30
          : window.innerWidth >= 667 && window.innerWidth <= 932
          ? app.screen.width / 20
          : app.screen.width / 10;
      MiniText.x = (app.screen.width - MiniText.width) / 1.157;
      MiniText.y = (app.screen.height - MinorTextImg.height) / 9.5;
      MiniText.anchor.set(0.5);
      top.addChild(MiniText);
      MiniText.visible = true;

      //Mini Text Frame background Image imported here
      const ExploradoraSimbolo = new Sprite(
        Texture.from(
          window.innerWidth > 480
            ? "/assets/Slots/Slot_Sprite/Exploradora_Simbolo.png"
            : "/assets/Slots/Slot_Sprite/Exploradora_Simbolo_mobile.png"
        )
      );
      ExploradoraSimbolo.height = Y1percent * 30;
      ExploradoraSimbolo.width = X1percent * 50;
      ExploradoraSimbolo.x = X1percent * 25;
      ExploradoraSimbolo.y = Y1percent * 3;
      ExploradoraSimbolo.visible = false;
      app.stage.addChild(ExploradoraSimbolo);

      const stickyMiniText = new BitmapText(`$${10}.00`, textStyle);
      stickyMiniText.width =
        window.innerWidth > 1440
          ? app.screen.width / 17.5
          : window.innerWidth > 1366 && window.innerWidth < 1440
          ? app.screen.width / 35
          : window.innerWidth > 800 && window.innerWidth < 1367
          ? app.screen.width / 28
          : app.screen.width / 50;
      stickyMiniText.x = (app.screen.width - stickyMiniText.width) / 1.2;
      stickyMiniText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(stickyMiniText);
      stickyMiniText.visible = false;

      // sticky spin buttons***********
      ////***********   Minor text Section starts  *************
      const stickySpinBg = new Sprite(
        Texture.from("/assets/MainBG/Dorado_Sticky_background.png")
      );
      stickySpinBg.height = app.screen.height;
      stickySpinBg.width = app.screen.width;
      stickySpinBg.visible = false;
      stickySpinBg.x = 0;
      stickySpinBg.y = 0;

      const MinorStickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Base1.png")
      );
      MinorStickyTextFrameBG.height = Y1percent * 10.9;
      MinorStickyTextFrameBG.width = X1percent * 13;
      MinorStickyTextFrameBG.x =
        (app.screen.width - MinorTextFrameBG.width) / 7.5;
      MinorStickyTextFrameBG.y = Y1percent * 3.5;
      MinorStickyTextFrameBG.visible = false;
      app.stage.addChild(MinorStickyTextFrameBG);

      //Minor Text Frame imported here
      const MinorStickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Contorno.png")
      );
      MinorStickyTextFrame.height = Y1percent * 11.2;
      MinorStickyTextFrame.width = X1percent * 13;
      MinorStickyTextFrame.x =
        (app.screen.width - MinorTextFrameBG.width) / 7.5;
      MinorStickyTextFrame.y = Y1percent * 3.5;
      MinorStickyTextFrame.visible = false;
      app.stage.addChild(MinorStickyTextFrame);

      //Minor Text Png imported here
      const MinorStickyTextImg = new Sprite(
        Texture.from("/assets/Link/Minor/Minor-Jackpot-Nombre.png")
      );
      MinorStickyTextImg.height = app.screen.height / 18;
      MinorStickyTextImg.width = app.screen.width / 17;
      MinorStickyTextImg.x = (app.screen.width - MinorTextImg.width) / 6;
      MinorStickyTextImg.y = (app.screen.height - MinorTextImg.height) / 40;
      MinorStickyTextImg.visible = false;
      app.stage.addChild(MinorStickyTextImg);

      //grand winning Png imported here
      const stickyGrandWinFrame = new Sprite(
        Texture.from("/assets/Link/GrandJackpot.png")
      );
      stickyGrandWinFrame.height = Y1percent * 40;
      stickyGrandWinFrame.width = X1percent * 50;
      stickyGrandWinFrame.x = X1percent * 25;
      stickyGrandWinFrame.y = Y1percent * 20;
      stickyGrandWinFrame.visible = true;
      jackpotWinContainer.addChild(stickyGrandWinFrame);

      const gameWinStyle = new PIXI.TextStyle({
        fill: "#fff",
        fontFamily: "Arial , roboto, sans-serif",
        fontSize: window.innerWidth > 480 ? Y1percent * 15 : Y1percent * 15,
        fontWeight: window.innerWidth > 480 ? "bolder" : "normal",
      });
      const textForGameWin = new PIXI.BitmapText("1000", gameWinStyle);
      textForGameWin.x = X1percent * 41;
      textForGameWin.y = Y1percent * 35;
      textForGameWin.visible = true;
      jackpotWinContainer.addChild(textForGameWin);

      stickyTotalwin();
      function stickyTotalwin() {
        textForGameWin.text = "10000";
        if (textForGameWin.text.length == 7) {
        }
      }

      ////***************   Grand text Section strats   ***************
      const linkHitImg = new AnimatedSprite(linkHitSheet.animations.enemy);
      const linkHitImg2 = new AnimatedSprite(linkHitSheet.animations.enemy);
      function playLinkHitAnimation() {
        stickyFlag=true;
        addlinkhitimg();
        setTimeout(() => {
          LinkHitScream.play();
        }, 1000);
        setTimeout(() => {
          LinkHitMusic.play();
          LinkHitMusic.loop = true;
          LinkHitRing.play();
          LinkHitRing2.play();
        }, 1800);
      }

      function addlinkhitimg() {
        linkHitImg.animationSpeed = 0.5;
        linkHitImg.loop = false;
        linkHitImg.play();
        linkHitImg.width = app.screen.width;
        linkHitImg.height = app.screen.height;
        linkHitImg.onComplete = () => {
          linkHitImg.gotoAndStop(0);
          linkHitImg.visible = false;
        };
        app.stage.addChild(linkHitImg);
      }

      async function switchNormalSpin() {
        //app.stage.addChild(linkHitImg)
        linkHitImg.play();
        linkHitImg.visible = true;

        linkHitImg.onComplete = () => {
          linkHitImg.gotoAndStop(0);
          linkHitImg.visible = false;
        };

        LinkHitMusic.pause();
        removeStickyFireIcon();
        for (let i = 0; i < 5; i++) {
          for (let j = 0; j < 3; j++) {
            reels[i].container.children[j].visible = true;
          }
        }
        data = await gameLoadDataApi();
        creditsVal = (data?.playerBalance*100) || 0;
        currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin || 0;
      }

      //remove all fireicon after complete sticky spin and back to normal
      function removeStickyFireIcon() {
        stickyContainer.removeChild(spriteAOverlay00);
        stickyContainer.removeChild(spriteAOverlay01);
        stickyContainer.removeChild(spriteAOverlay02);
        stickyContainer.removeChild(spriteAOverlay10);
        stickyContainer.removeChild(spriteAOverlay11);
        stickyContainer.removeChild(spriteAOverlay12);
        stickyContainer.removeChild(spriteAOverlay20);
        stickyContainer.removeChild(spriteAOverlay21);
        stickyContainer.removeChild(spriteAOverlay22);
        stickyContainer.removeChild(spriteAOverlay30);
        stickyContainer.removeChild(spriteAOverlay31);
        stickyContainer.removeChild(spriteAOverlay32);
        stickyContainer.removeChild(spriteAOverlay40);
        stickyContainer.removeChild(spriteAOverlay41);
        stickyContainer.removeChild(spriteAOverlay42);
      }

      //Grand Text Frame background Image imported here
      const GrandstickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Base.png")
      );
      GrandstickyTextFrameBG.height = Y1percent * 14;
      GrandstickyTextFrameBG.width = X1percent * 20;
      GrandstickyTextFrameBG.x = X1percent * 29.5;
      GrandstickyTextFrameBG.y = Y1percent * 2.5;
      GrandstickyTextFrameBG.visible = false;
      app.stage.addChild(GrandstickyTextFrameBG);

      //Grand Text Frame imported here
      const GrandstickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Contorno.png")
      );
      GrandstickyTextFrame.height = app.screen.height / 7.5;
      GrandstickyTextFrame.width = app.screen.width / 5;
      GrandstickyTextFrame.x =
        (app.screen.width - GrandstickyTextFrame.width) / 2.7;
      GrandstickyTextFrame.y =
        (app.screen.height - GrandstickyTextFrame.height) / 30;
      GrandstickyTextFrame.visible = false;
     app.stage.addChild(GrandstickyTextFrame);

      //Grand Text Frame imported here
      const GrandstickyTextImg = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Nombre.png")
      );
      GrandstickyTextImg.height = app.screen.height / 10;
      GrandstickyTextImg.width = app.screen.width / 8;
      GrandstickyTextImg.x = X1percent * 34;
      GrandstickyTextImg.y = Y1percent * -2;
      GrandstickyTextImg.visible = false;
      app.stage.addChild(GrandstickyTextImg);

      //Grand Text Frame imported here
      const GrandStickyFrameDesign = new Sprite(
        Texture.from("/assets/Link/Grand/Grand-Jackpot-Fuego.png")
      );
      GrandStickyFrameDesign.height = Y1percent * 13;
      GrandStickyFrameDesign.width = X1percent * 20.3;
      GrandStickyFrameDesign.x = X1percent * 29.5;
      GrandStickyFrameDesign.y = Y1percent * 2.7;
      GrandStickyFrameDesign.visible = false;
      app.stage.addChild(GrandStickyFrameDesign);

      ////***************   Major text Section starts   ***************
      //Major Text Frame background Image imported here
      const MajorstickyTextFrameBG = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Base.png")
      );
      MajorstickyTextFrameBG.height = X1percent * 6;
      MajorstickyTextFrameBG.width = Y1percent * 40;
      MajorstickyTextFrameBG.x = X1percent * 50;
      MajorstickyTextFrameBG.y = Y1percent * 3.5;
      MajorstickyTextFrameBG.visible = false;
      app.stage.addChild(MajorstickyTextFrameBG);

      //Grand Text Frame imported here
      const MajorstickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Contorno.png")
      );
      MajorstickyTextFrame.height = app.screen.height / 8;
      MajorstickyTextFrame.width = app.screen.width / 5;
      MajorstickyTextFrame.x =
        (app.screen.width - MajorstickyTextFrame.width) / 1.6;
      MajorstickyTextFrame.y = Y1percent * 3.5;
      MajorstickyTextFrame.visible = false;
      app.stage.addChild(MajorstickyTextFrame);

      //Major Text Frame imported here
      const MajorstickyTextImg = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Nombre.png")
      );
      MajorstickyTextImg.height = Y1percent * 8;
      MajorstickyTextImg.width = X1percent * 10;
      MajorstickyTextImg.x = X1percent * 55;
      MajorstickyTextImg.y = Y1percent * -1.3;
      MajorstickyTextImg.visible = false;
      app.stage.addChild(MajorstickyTextImg);

      //Major Text Frame imported here
      const MajorStickyFrameDesign = new Sprite(
        Texture.from("/assets/Link/Major/Major-Jackpot-Fuego.png")
      );
      MajorStickyFrameDesign.height = Y1percent * 13;
      MajorStickyFrameDesign.width = X1percent * 20;
      MajorStickyFrameDesign.x = X1percent * 50;
      MajorStickyFrameDesign.y = Y1percent * 3.2;
      MajorStickyFrameDesign.visible = false;
      app.stage.addChild(MajorStickyFrameDesign);

      ////***************   Mini text section starts   ***************
      //Mini Text Frame imported here
      const MinistickyTextFrame = new Sprite(
        Texture.from("/assets/Link/Mini/Minir-Jackpot-Contorno1.png")
      );
      MinistickyTextFrame.height = app.screen.height / 9;
      MinistickyTextFrame.width = X1percent * 13;
      MinistickyTextFrame.x =
        (app.screen.width - MinistickyTextFrame.width) / 1.156;
      MinistickyTextFrame.y = Y1percent * 3.9;
      MinistickyTextFrame.visible = false;
      app.stage.addChild(MinistickyTextFrame);

      //Mini Text Png imported here
      const MinistickyTextImg = new Sprite(
        Texture.from("/assets/Link/Mini/Mini-Jackpot-Nombre.png")
      );
      MinistickyTextImg.height = app.screen.height / 20;
      MinistickyTextImg.width = X1percent * 5;
      MinistickyTextImg.x = (app.screen.width - MinistickyTextFrame.width) / 1;
      MinistickyTextImg.y = (app.screen.height - MinistickyTextImg.height) / 40;
      MinistickyTextImg.visible = false;
      app.stage.addChild(MinistickyTextImg);

      //sticky remianing  count text
      // Add play text
      const textStyleremaining = new TextStyle({
        fontSize:
          window.innerWidth > 480
            ? app.screen.width / 54
            : app.screen.width / 15,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: window.innerWidth > 480 ? "bolder" : "normal",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      let remainingStickyCountainer = new Container();
      const stickyCountFrame = new Sprite(
        Texture.from("/assets/Link/Restantes/Spins-Restantes.png")
      );

      stickyCountFrame.width = app.screen.width / 4.6;
      stickyCountFrame.height = app.screen.height / 9;
      stickyCountFrame.x = (app.screen.width - stickyCountFrame.width) / 2;
      stickyCountFrame.y = (app.screen.height - stickyCountFrame.height) / 7;
      const stickyremainingText = new BitmapText(
        getMessage(defaultLang, "RemainingStickyCount"),
        textStyleremaining
      );
      stickyremainingText.x = X1percent * 41.6;
      stickyremainingText.y = Y1percent * 16;
      stickyremainingText.visible = true;

      const stickyremainingCount = new BitmapText(currentStickySpinGameLoad, textStyleremaining);
      // const stickyremainingCount = new BitmapText(`0`, textStyleremaining);
      stickyremainingCount.x = X1percent * 57.6;
      stickyremainingCount.y = Y1percent * 16;
      stickyremainingCount.visible = true;

      remainingStickyCountainer.addChild(stickyCountFrame);
      remainingStickyCountainer.addChild(stickyremainingCount);
      remainingStickyCountainer.addChild(stickyremainingText);

      let winnerTextBoxTop = new Container();
      const stickyspinoverlayFrame = new Sprite(
        Texture.from("/assets/Link/Total-Win-Base.png")
      );
      stickyspinoverlayFrame.width = X1percent * 31;
      stickyspinoverlayFrame.height = Y1percent * 8;
      stickyspinoverlayFrame.x = X1percent * 35;
      stickyspinoverlayFrame.y = Y1percent * 2;
      stickyspinoverlayFrame.visible = true;

      // Add play text
      const textStyles = new TextStyle({
        fontSize:
          betVal === 500 ? app.screen.width / 70 : app.screen.width / 30,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fdfdc4",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
        stroke: {
          color: "#e3e300",
          lineWidth: 4,
        },
      });

      const Stickywin = new BitmapText(`0`, textStyles);
      Stickywin.x = X1percent * 49;
      Stickywin.y = window.innerWidth > 480 ? Y1percent * 9.5 : Y1percent * 8.5;
      Stickywin.anchor.set(0.5);
      Stickywin.scale.set(window.innerWidth > 480 ? 1 : 2.5);
      Stickywin.visible = false;

      var stickyWinAmount = 0;
      function showStickywinAmount() {

        Stickywin.text = `$${stickyWinAmount.toFixed(2)}`;
      }
      const stickyspinBGFrame = new Sprite(
        Texture.from("/assets/Link/Total-Win-Contorno.png")
      );
      stickyspinBGFrame.width = X1percent * 30;
      stickyspinBGFrame.height = Y1percent * 8;
      stickyspinBGFrame.x = X1percent * 35;
      stickyspinBGFrame.y = Y1percent * 13;
      stickyspinBGFrame.visible = true;

      const stickyspinTotalwinImg = new Sprite(
        Texture.from("/assets/Link/Total-Win-Text.png")
      );
      stickyspinTotalwinImg.width = X1percent * 20;
      stickyspinTotalwinImg.height = Y1percent * 6;
     stickyspinTotalwinImg.x = X1percent * 40;
      stickyspinTotalwinImg.y = Y1percent;
      stickyspinTotalwinImg.visible = false;

      winnerTextBoxTop.visible = false;
      winnerTextBoxTop.addChild(stickyspinoverlayFrame);
      winnerTextBoxTop.addChild(stickyspinBGFrame);
      // winnerTextBoxTop.addChild(stickyremainingCount);
      winnerTextBoxTop.addChild(stickyspinTotalwinImg);
      winnerTextBoxTop.addChild(Stickywin);
      top.addChild(winnerTextBoxTop);
      app.stage.addChild(stickyContainer);
      // sticky space filed container
      let stickySpaceFilledCountContainer = new Container();
      const stickySpinBootomText = new Text(
        window.innerWidth > 480
          ? getMessage(defaultLang, "SpacesFillCountText")
          : `Spaces Filled`,
        {
          fontFamily: "Arial , roboto, sans-serif",
          fontSize: window.innerWidth > 480 ? Y1percent * 3.8 : Y1percent * 2.8,
          fill: "#fff",
          align: "left",
          wordWrap: true, // Add this line
          wordWrapWidth: X1percent * 10, // Adjust the width to your liking
        }
      );
      stickySpinBootomText.x = X1percent * 75;
      stickySpinBootomText.y = Y1percent * 88;
      stickySpinBootomText.visible = true;

      const stickyspinBottomCount = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );
      stickyspinBottomCount.width = X1percent * 8;
      stickyspinBottomCount.height = X1percent * 8;
      stickyspinBottomCount.x = X1percent * 85;
      stickyspinBottomCount.y = Y1percent * 85;
      stickyspinBottomCount.visible = true;

      const textStylespacefilled = new TextStyle({
        fontSize: window.innerWidth > 480 ? Y1percent * 5 : Y1percent * 3.5,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });
      const stickySpinBootomTextCount = new BitmapText(
        `${spaceFilledWtihFire}`,
        textStylespacefilled
      );
      function stickySpinBootomTextCountHandle(spaceFilledWtihFire) {
        stickySpinBootomTextCount.text = `${spaceFilledWtihFire}`;
        stickySpinBootomTextCount.x = X1percent * 89;
      }
      stickySpinBootomTextCount.anchor.set(0.5);
      stickySpinBootomTextCount.x = X1percent * 92;
      stickySpinBootomTextCount.y = Y1percent * 92.5;
      stickySpinBootomTextCount.visible = true;
      stickySpaceFilledCountContainer.addChild(stickySpinBootomText);
      stickySpaceFilledCountContainer.addChild(stickyspinBottomCount);
      stickySpaceFilledCountContainer.addChild(stickySpinBootomTextCount);
      stickySpaceFilledCountContainer.visible = false;
      app.stage.addChild(stickySpaceFilledCountContainer);
      app.stage.addChild(stickyGameFrame);

      //  ******************* Game Bottom Section Code*********************
      // ********* Win Image imported
      const WinBg = new Sprite(Texture.from("/assets/Backgrounds/Hud_Won.png"));
      WinBg.height = app.screen.height / 7;
      WinBg.width = app.screen.width / 5;
      WinBg.x = (app.screen.width - WinBg.width) / 2;
      WinBg.y = (app.screen.height - WinBg.height) / 1;
      // app.stage.addChild(WinBg);

      //bottom section text style starts
      const betTextStyle = new TextStyle({
        fontSize: app.screen.width / 55,
        fontWeight: "normal",
        align: "center",
        fill: "#ffffff",
        fontFamily: "Arial , roboto, sans-serif",
        stroke:
          window.innerWidth > 480
            ? { color: "#000", width: 4 }
            : { color: "#000", width: 2 },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      const betSubTextStyle = new TextStyle({
        align: "center",
        fontSize: app.screen.width / 80,
        fontWeight: "normal",
        fill: "#00b8b7",
        fontFamily: "Arial , roboto, sans-serif",
        stroke:
          window.innerWidth > 480
            ? { color: "#000", width: 2 }
            : { color: "#000", width: 1 },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      // ********* Credit Image imported
      const CreditsBg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_credits.png")
      );
      CreditsBg.height = app.screen.height / 7;
      CreditsBg.width = app.screen.width / 7;
      CreditsBg.x = (app.screen.width - CreditsBg.width) / 3.4;
      CreditsBg.y = (app.screen.height - CreditsBg.height) / 1;
    //   app.stage.addChild(CreditsBg);

      // Circular Image/Denomination text added
      const CreditText = new BitmapText(
        getMessage(defaultLang, "credit").toUpperCase(),
        betTextStyle
      );
      CreditText.x = (app.screen.width - CreditText.width) / 3.25;
      CreditText.y = (app.screen.height - CreditsBg.height) / 1.02;
    //   app.stage.addChild(CreditText);

      // Credit text code starts here
      var creditsValue = new BitmapText(
        `${formatter.format(Math.round(creditsVal))}`,
        betTextStyle
      );
      creditsValue.x =
        creditsValue.text.length === 3
          ? X1percent * 30.8
          : creditsValue.text.length === 12
          ? X1percent * 27
          : creditsValue.text.length === 5
          ? X1percent * 30
          : creditsValue.text.length === 6
          ? X1percent * 29.5
          : creditsValue.text.length === 7
          ? X1percent * 28.8
          : creditsValue.text.length === 8
          ? X1percent * 29
          : creditsValue.text.length === 9
          ? X1percent * 28.3
          : creditsValue.text.length === 11
          ? X1percent * 27.5
          : creditsValue.text.length === 10
          ? X1percent * 27.5
          : creditsValue.text.length === 2
          ? X1percent * 31.5
          : X1percent * 32;
      creditsValue.y = (app.screen.height - CreditsBg.height) / 0.97;
    //   app.stage.addChild(creditsValue);

      var creditsInput = parseFloat(creditsVal / 100);
      let creditsOutput =
        creditsInput % 1 === 0
          ? creditsInput.toFixed(2).replace(/\.0$/, "")
          : creditsInput.toFixed(2);
      //creditsSubValue

      let formattedNumber = formatter.format(creditsInput.toFixed(2));


      const creditsSubValue = new BitmapText(
        `$${formatNumber(creditsOutput)}`,
        betSubTextStyle
      );
      creditsSubValue.anchor.set(0.5);
      creditsSubValue.x = (app.screen.width - creditsSubValue.width) / 2.92;
      creditsSubValue.y = (app.screen.height - CreditsBg.height) / 0.9;
    //   app.stage.addChild(creditsSubValue);

    //   function updateCredits(creditsVal) {
    //     let creditsValueQ = new BitmapText(
    //       `${formatter.format(Math.round(creditsVal))}`,
    //       betTextStyle
    //     );
    //     if (window.innerWidth > 480) {
    //       creditsValueQ.x =
    //         (creditsVal == 0
    //           ? GameFrame.width - CreditText.width / 1.2
    //           : GameFrame.width - CreditText.width) / 2.01;
    //       creditsValueQ.y = (app.screen.height - CreditsBg.height) / 0.97;
    //     } else {
    //       creditsValueQ.x =
    //         creditsValueQ.text.length === 3
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 12
    //           ? 10
    //           : creditsValueQ.text.length === 5
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 6
    //           ? X1percent * 12
    //           : creditsValueQ.text.length === 7
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 8
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 9
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 11
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 10
    //           ? X1percent * 10
    //           : creditsValueQ.text.length === 2
    //           ? X1percent * 15
    //           : X1percent * 15;
    //       creditsValueQ.y = Y1percent * 72;
    //       creditsValueQ.height = Y1percent * 3.5;
    //     }
    //     // app.stage.addChildAt(creditsValueQ, 40);
    //     creditsValue.text = creditsValueQ.text;
    //     responsiveTextMiniMinor();
    //   }

    //   function updateCreditsSubVal() {
    //     let input = parseFloat(creditsVal / 100);
    //     let output =
    //       input % 1 === 0
    //         ? input.toFixed(2).replace(/\.0$/, "")
    //         : input.toFixed(2);

    //     let outputNumber = formatter.format(output);
    //     creditsSubValue.text = `$${formatNumber(output)}`;
    //     if (window.innerWidth > 480)
    //       creditsValue.x =
    //         creditsValue.text.length === 3
    //           ? X1percent * 30.8
    //           : creditsValue.text.length === 12
    //           ? X1percent * 27
    //           : creditsValue.text.length === 5
    //           ? X1percent * 30
    //           : creditsValue.text.length === 6
    //           ? X1percent * 29.5
    //           : creditsValue.text.length === 7
    //           ? X1percent * 28.8
    //           : creditsValue.text.length === 8
    //           ? X1percent * 29
    //           : creditsValue.text.length === 9
    //           ? X1percent * 28.3
    //           : creditsValue.text.length === 11
    //           ? X1percent * 27.5
    //           : creditsValue.text.length === 10
    //           ? X1percent * 27.5
    //           : creditsValue.text.length === 2
    //           ? X1percent * 31.5
    //           : X1percent * 32;
    //   }



      
      // ********* Bet Image imported/added

/*********************************************************************************************************** */
const bottomBtnContainer = new Container();
const winBtnContainer = new Container();
const creditsBtnContainer = new Container();
const betBtnContainer = new Container();

app.stage.addChild(bottomBtnContainer);

  betVal = Number(multiPlyArr[multiCounter])*payline;
   console.log('--',betVal)
   
   const betBgTexture = await PIXI.Assets.load("/assets/Backgrounds/Hud_Bet.png");
   const betBg = new Sprite(betBgTexture);
   betBg.height = app.screen.height / 7;
   betBg.width = app.screen.width / 7;
   betBg.x = (app.screen.width - betBg.width) / 1.42;
   betBg.y = (app.screen.height - betBg.height) / 1;
   betBtnContainer.addChild(betBg)
 
   const betText = new PIXI.Text("BET", { fontSize: 40, fill: 0xffffff });
   betText.anchor.set(0.5);
   betText.x = betBg.x + betBg.width / 2; 
   betText.y = betBg.y + betBg.height / 100; 
   betBtnContainer.addChild(betText);
   const betAmountText = new PIXI.Text(`${betVal}`, { fontSize: 40, fill: 0xffffff });
   betAmountText.anchor.set(0.5);
   betAmountText.x = betBg.x + betBg.width / 2;
   betAmountText.y = betBg.y + betBg.height / 2.6; 
   betBtnContainer.addChild(betAmountText);
   const betAmountsubText = new PIXI.Text(`$${formatNumber(betVal/100)}`, { fontSize: 25, fill: "yellow" });
   betAmountsubText.anchor.set(0.5);
   betAmountsubText.x = betBg.x + betBg.width / 2;
   betAmountsubText.y = betBg.y + betBg.height / 1.6; 
   betBtnContainer.addChild(betAmountsubText);
   
 
   betBg.interactive = true;
   betBg.buttonMode = true;
   betBg.on('pointerdown', () => {
     console.log(betVal)
     if(multiCounter < multiPlyArr.length-1){
       multiCounter++;
       betVal = multiPlyArr[multiCounter]*payline
       betAmountText.text = `${betVal}`;
       betAmountsubText.text = `$${formatNumber((betVal/100)*coinDenonimation)}`;
       console.log(multiCounter,'--',betVal)
       MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
       MiniText.text = `$${formatter.format(
         (betVal / 5) * coinDenonimation
       )}.00`;
       removingfunction();
       responsiveTextMiniMinor();
     }else{
       multiCounter=0;
       betVal = multiPlyArr[multiCounter]*payline
       betAmountText.text = `${betVal}`;
       betAmountsubText.text = `$${formatNumber((betVal/100)*coinDenonimation)}`;
       console.log(multiCounter,'--',betVal)
       MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
       MiniText.text = `$${formatter.format(
         (betVal / 5) * coinDenonimation
       )}.00`;
       removingfunction();
       responsiveTextMiniMinor();
     }
   })

    /*******************************************  show win value code  ******************************************************* */  
    
    app.stage.addChild(bottomBtnContainer);
      
      const winBgTexture = await PIXI.Assets.load("/assets/Backgrounds/Hud_Won.png");
      const winBg = new Sprite(winBgTexture);
      winBg.height = app.screen.height / 7;
      winBg.width = app.screen.width / 5;
      winBg.x = (app.screen.width - winBg.width) / 2;
      winBg.y = (app.screen.height - winBg.height) / 1;
      winBtnContainer.addChild(winBg);
      const winText = new PIXI.Text("WIN", { fontSize: 40, fill: 0xffffff });
      winText.anchor.set(0.5);
      winText.x = winBg.x + winBg.width / 2; 
      winText.y = winBg.y + winBg.height / 100 ; 
      winBtnContainer.addChild(winText);
      function formatNumberonlycomma(value) {
        const roundedValue = Math.floor(value);
        return roundedValue.toLocaleString('en-US');
      }
      const winAmountText = new PIXI.Text(`${formatNumberonlycomma(0)}`, { fontSize: 40, fill: 0xffffff });
      winAmountText.anchor.set(0.5);
      winAmountText.x = winBg.x + winBg.width / 2;
      winAmountText.y = winBg.y + winBg.height / 2.6; 
      winBtnContainer.addChild(winAmountText);
      const winAmountsubText = new PIXI.Text(`$${formatNumber(0)}`, { fontSize: 25, fill: "yellow" });
      winAmountsubText.anchor.set(0.5);
      winAmountsubText.x = winBg.x + winBg.width / 2;
      winAmountsubText.y = winBg.y + winBg.height / 1.6; 
      winBtnContainer.addChild(winAmountsubText);

    async function winBtnHandle(winVAl){
      console.log({winVAl})
      winAmountText.text = `${formatNumberonlycomma(winVAl)}`
      winAmountsubText.text = `$${formatNumber(winVAl/100)}`
    }
    async function winBtnHandleDollar(winVAl){
      console.log({winVAl})
      winAmountsubText.text = `$${formatNumber(winVAl)}`
    }
    winBtnHandle(0)


    /********************************************* show win value code end***************************************************** */  


// console.log({winVAl})
const creditsBgTexture = await PIXI.Assets.load("/assets/Backgrounds/Hud_credits.png");
const creditsBg = new Sprite(creditsBgTexture);
creditsBg.height = app.screen.height / 7;
creditsBg.width = app.screen.width / 7;
creditsBg.x = (app.screen.width - creditsBg.width) / 3.4;
creditsBg.y = (app.screen.height - creditsBg.height) / 1;

creditsBtnContainer.addChild(creditsBg);
const creditsText = new PIXI.Text("CREDITS", { fontSize: 40, fill: 0xffffff });
creditsText.anchor.set(0.5);
creditsText.x = creditsBg.x + creditsBg.width / 2; 
creditsText.y = creditsBg.y + creditsBg.height / 100; 
creditsBtnContainer.addChild(creditsText);
const creditsAmountText = new PIXI.Text(`${formatter.format(Math.round(creditsVal/coinDenonimation))}`, { fontSize: 40, fill: 0xffffff });
creditsAmountText.anchor.set(0.5);
creditsAmountText.x = creditsBg.x + creditsBg.width / 2;
creditsAmountText.y = creditsBg.y + creditsBg.height / 2.9; 
creditsBtnContainer.addChild(creditsAmountText);

const creditsAmountsubText = new PIXI.Text(`$${formatNumber(creditsVal/100)}`, { fontSize: 25, fill: "yellow" });
creditsAmountsubText.anchor.set(0.5);
creditsAmountsubText.x = creditsBg.x + creditsBg.width / 2;
creditsAmountsubText.y = creditsBg.y + creditsBg.height / 1.6; 
creditsBtnContainer.addChild(creditsAmountsubText);

async function creditsBtnHandle(winVAl,subWinVal){
  creditsAmountText.text = `${formatter.format(Math.round(winVAl))}`
  creditsAmountsubText.text = `$${formatNumber(subWinVal/100)}`
}
// console.log({creditsVal},{coinDenonimation})
// creditsBtnHandle((creditsVal/coinDenonimation),creditsVal);

 bottomBtnContainer.addChild(creditsBtnContainer);
 bottomBtnContainer.addChild(winBtnContainer);
 bottomBtnContainer.addChild(betBtnContainer);
 









/*********************************************************************************************************** */









      //credits text added
      // ********* Bet Image imported/added
      const BetBg = new Sprite(Texture.from("/assets/Backgrounds/Hud_Bet.png"));
      BetBg.height = app.screen.height / 7;
      BetBg.width = app.screen.width / 7;
      BetBg.x = (app.screen.width - BetBg.width) / 1.42;
      BetBg.y = (app.screen.height - BetBg.height) / 1;
    //   app.stage.addChild(BetBg);

      // Bet text added
      const BetText = new BitmapText(
        getMessage(defaultLang, "bet").toUpperCase(),
        betTextStyle
      );
      BetText.x = (app.screen.width - BetText.width) / 1.46;
      BetText.y = (app.screen.height - BetBg.height) / 1.02;
    //   app.stage.addChild(BetText);

      // Bet text added
      const BetValue = new BitmapText(`${betVal}`, betTextStyle);
      BetValue.x =
        defaultLang === "es"
          ? (app.screen.width - BetText.width) / 1.38
          : (app.screen.width - BetText.width) / 1.455;
      BetValue.y = (app.screen.height - BetBg.height) / 0.97;
    //   app.stage.addChild(BetValue);

      // Bet text added
      let input = parseFloat(betVal / 100);
      let BetSuboutput =
        input % 1 === 0
          ? input.toFixed(2).replace(/\.0$/, "")
          : input.toFixed(2);
      //BetSubValue
      const BetSubValue = new BitmapText(`$${BetSuboutput}`, betSubTextStyle);
      BetSubValue.x =
        defaultLang === "es"
          ? (app.screen.width - BetText.width) / 1.39
          : (app.screen.width - BetText.width) / 1.43;
      BetSubValue.y = (app.screen.height - BetBg.height) / 0.9;
      BetSubValue.anchor.set(0.5);
    //   app.stage.addChild(BetSubValue);

      const winTextStyle = new TextStyle({
        fontSize: app.screen.width / 45,
        fontWeight: 400,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#ffff00",
        stroke:
          window.innerWidth > 480
            ? { color: "#000", width: 3 }
            : { color: "#000", width: 2 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 4,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      var winTextValStyle = new TextStyle({
        fontSize: app.screen.width / 45,
        fontWeight: 400,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#fff",
        stroke:
          window.innerWidth > 480
            ? { color: "#000", width: 5 }
            : { color: "#000", width: 2 },
        dropShadow:
          window.innerWidth > 480
            ? { color: 0x000000, angle: Math.PI / 6, blur: 4, distance: 6 }
            : { color: 0x000000, angle: Math.PI / 6, blur: 1, distance: 2 },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      // Win text added
      const WinText = new BitmapText(
        getMessage(defaultLang, "win").toUpperCase(),
        winTextStyle
      );
      WinText.x = (app.screen.width - WinText.width) / 2;
      WinText.y = (app.screen.height - BetBg.height) / 1.03;
      // app.stage.addChild(WinText);

      // Win text value added
      var WinTextvalue = new BitmapText(`${formatter.format(winValue)}`, winTextValStyle);
      WinTextvalue.x =
        defaultLang === "es"
          ? winValue.text === 0
            ? (app.screen.width - WinText.width) / 1.88
            : (app.screen.width - WinText.width) / 1.88
          : winValue.text === 0
          ? (app.screen.width - WinText.width) / 1.95
          : (app.screen.width - WinText.width) / 1.96;
      WinTextvalue.y = (app.screen.height - BetBg.height) / 0.97;
      // WinTextvalue.anchor.set(0.5)
      // app.stage.addChild(WinTextvalue);


      let freespinInterval;
      let stickywiningInterval;
     let freestickyInterval;
    let freespinIntervalCount;
    let stickyspinType = false;
    let freespinTypebool = false;
    let basewiningInterval;

      // function UpdateWinValue(value,dollarValue){
        
        
      //   value = parseInt(value)
      //   subWinTextvalue.x = (app.screen.width - WinText.width) / 2;
      //   if (freespinTypebool == true && stickyspinType == false) {
      //     freespinWinvalue = parseInt(WinTextvalue.text)     
      //     console.log(freespinWinvalue,'Free Spin winValue+++  1')
      //     freespinIntervalCount = setInterval(() => {
      //       if (freespinWinvalue >= value) {
      //         clearInterval(freespinIntervalCount);
      //         freespinWinvalue = value;
      //         console.log(freespinWinvalue,'Free Spin winValue+++  2')
      //         bottom.visible = true;
      //       }
      //       else{
      //         if (currentfreeSpin  == 0 && freeSpin < 1 && freespinWinvalue >= 0 && remaningStickeySpin == 0) {
      //           setTimeout(() => {
      //             freeSpinType = false;
      //             freeSpinCredits.text = `${finalFreeSpinWinning}`;
      //           }, 5000);
      //         }            
      //       }
      //       if((value < 5000)){
      //         freespinWinvalue = freespinWinvalue + 3;
      //         console.log(freespinWinvalue,'Free Spin winValue+++  3')

      //         if (freespinWinvalue > value) {
      //           freespinWinvalue = value;
      //         }
      //         }else if(value > 5000){
      //           freespinWinvalue = freespinWinvalue + 99;
      //           console.log(freespinWinvalue,'Free Spin winValue+++  4')

      //         if (freespinWinvalue > value) {
      //           freespinWinvalue = value;
      //           console.log(freespinWinvalue,'Free Spin winValue+++  5')

      //         }
      //       }

      //       showWinValue(freespinWinvalue);
      //       console.log(freespinWinvalue,'Free Spin winValue+++  6')

      //     }, 0.01);

      //   }
      //   else if(freespinTypebool == false && stickyspinType == true){
      //     // console.log('stickyspin',totalWinstickyamount,totalFireAmountInDollar,remaningStickeySpin,value)          
      //     stickywiningInterval = setInterval(() => {
      //       if (totalWinstickyamount < value) {
      //         if (value > 5000 && value < 10000) {
      //           totalWinstickyamount += 10;
      //           if (totalWinstickyamount > value) {
      //             totalWinstickyamount = value;
      //           }
      //           showWinValue(totalWinstickyamount);
      //         } else if (value >= 10000) {
      //           totalWinstickyamount += 99;
      //           if (totalWinstickyamount > value) {
      //             totalWinstickyamount = value;
      //           }
      //           showWinValue(totalWinstickyamount);
      //         } else {
      //           totalWinstickyamount += 1;
      //           showWinValue(totalWinstickyamount);
      //         }
      //       } else {
      //         bottom.visible = true;
      //         SpinBtn2.visible = false;
      //         clearInterval(stickywiningInterval);
      //           if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
      //           backToPreviousLayout();
      //         }
      //       }
      //     }, 0.3);
      //   }
      //   else if(freespinTypebool == true && stickyspinType == true){
      //     // console.log('free+sticky spin',freeGamesWin,stickyspinType,value)        
      //     let winingAmount = parseInt(WinTextvalue.text);
      //     freestickyInterval = setInterval(() => {
      //       if ( winingAmount < value) {
      //         if (value > 5000 && value < 10000) {
      //           winingAmount = winingAmount + 10;
      //           if (winingAmount > value) {
      //             winingAmount = value;
      //           }
      //           showWinValue(winingAmount);
      //         } else if (value >= 10000) {
      //           winingAmount = winingAmount + 99;
      //           if (winingAmount > value) {
      //             winingAmount = value;
      //           }
      //           showWinValue(winingAmount);
      //         } else {
      //           winingAmount = winingAmount + 1;
      //           showWinValue(winingAmount);
      //         }
      //       }else{
      //         clearInterval(freestickyInterval);
      //         if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
      //           backToPreviousLayout();
      //         }else{
      //           bottom.visible = true;
      //           SpinBtn2.visible = false;
      //         }
      //       }
      //     }, 0.1);
      //   }
      //   else if(value > 0){
      //     let winvalue = 0;
      //     let basewiningInterval = setInterval(() => {
      //      // console.log(winvalue,value,'value1111')
      //       if (winvalue < value) {
      //         winvalue = winvalue + 1;
      //         showWinValue(winvalue);
      //       }else{
      //         clearInterval(basewiningInterval);
      //       }
      //     }, 0.3);
      //   }
      //   else if(value == 0){
      //     // console.log('counter in 0 value')        
      //     showWinValue(0)
      //   }
      //   updatedollarValue(dollarValue)
      // }


      function UpdateWinValue(value,dollarValue){
        
        // console.log("updatevalue",freespinTypebool,stickyspinType,value,dollarValue)
        
        value = parseInt(value)
        subWinTextvalue.x = (app.screen.width - subWinTextvalue.width) / 2;
        if (freespinTypebool == true && stickyspinType == false) {
          freespinWinvalue = parseInt(WinTextvalue.text)     
          freespinIntervalCount = setInterval(() => {
            if (freespinWinvalue >= value) {
              clearInterval(freespinIntervalCount);
              freespinWinvalue = value;
              bottom.visible = true;
            }
            else{
              if (currentfreeSpin  == 0 && freeSpin < 1 && freespinWinvalue >= 0 && remaningStickeySpin == 0) {
                setTimeout(() => {
                  freeSpinType = false;
                  freeSpinCredits.text = `${finalFreeSpinWinning}`;
                }, 5000);
              }            
            }
            if((value < 5000)){
              freespinWinvalue = freespinWinvalue + 3;

              if (freespinWinvalue > value) {
                freespinWinvalue = value;
              }
              }else if(value > 5000){
                freespinWinvalue = freespinWinvalue + 99;
              if (freespinWinvalue > value) {
                freespinWinvalue = value;
              }
            }
            showWinValue(freespinWinvalue);
            winBtnHandle(freespinWinvalue);
          }, 0.01);
        }
        else if(freespinTypebool == false && stickyspinType == true){
          // console.log('stickyspin',totalWinstickyamount,totalFireAmountInDollar,remaningStickeySpin,value)          
          stickywiningInterval = setInterval(() => {
            if (totalWinstickyamount < value) {
               if (value > 1000 && value <= 5000) {
                totalWinstickyamount = totalWinstickyamount + 9;
                if (totalWinstickyamount > value) {
                  totalWinstickyamount = value;
                }
                showWinValue(totalWinstickyamount);
                winBtnHandle(totalWinstickyamount)
              }
              else if (value > 5000 && value < 10000) {
                totalWinstickyamount = totalWinstickyamount + 10;
                if (totalWinstickyamount > value) {
                  totalWinstickyamount = value;
                }
                showWinValue(totalWinstickyamount);
                winBtnHandle(totalWinstickyamount)
              } else if (value >= 10000) {
                totalWinstickyamount = totalWinstickyamount + 99;
                if (totalWinstickyamount > value) {
                  totalWinstickyamount = value;
                }
                showWinValue(totalWinstickyamount);
                winBtnHandle(totalWinstickyamount)
              } else {
                totalWinstickyamount = totalWinstickyamount + 1;
                showWinValue(totalWinstickyamount);
                winBtnHandle(totalWinstickyamount)
              }
            } else {
              bottom.visible = true;
              SpinBtn2.visible = false;
              clearInterval(stickywiningInterval);
                if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
                backToPreviousLayout();
              }
            }
          }, 0.3);
        }
        else if(freespinTypebool == true && stickyspinType == true){
          // console.log('free+sticky spin',freeGamesWin,stickyspinType,value)        
          let winingAmount = parseInt(WinTextvalue.text);
          freestickyInterval = setInterval(() => {
            if ( winingAmount < value) {
               if (value > 0 && value < 2000) {
                  winingAmount = winingAmount + 2;
                  if (winingAmount > value) {
                    winingAmount = value;
                  }
                  showWinValue(winingAmount);
                  winBtnHandle(winingAmount)
                }
                else if (value >= 2000 && value <= 5000) {
                  winingAmount = winingAmount + 5;
                  if (winingAmount > value) {
                    winingAmount = value;
                  }
                  showWinValue(winingAmount);
                  winBtnHandle(winingAmount)
                }
                else if (value > 5000 && value < 10000) {
                  winingAmount = winingAmount + 10;
                  if (winingAmount > value) {
                    winingAmount = value;
                  }
                  showWinValue(winingAmount);
                  winBtnHandle(winingAmount)
                } else if (value >= 10000) {
                  winingAmount = winingAmount + 99;
                  if (winingAmount > value) {
                    winingAmount = value;
                  }
                  showWinValue(winingAmount);
                  winBtnHandle(winingAmount)
                } else {
                winingAmount = winingAmount + 1;
                showWinValue(winingAmount);
                winBtnHandle(winingAmount)
              }
            }else{
              clearInterval(freestickyInterval);
              if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
                backToPreviousLayout();
              }else{
                bottom.visible = true;
                SpinBtn2.visible = false;
              }
            }
          }, 0.1);
        }
        else if(value > 0){
          let winvalue = 0;
           basewiningInterval = setInterval(() => {
           // console.log(winvalue,value,'value1111')
            if (winvalue < value) {
              winvalue = winvalue + 1;
              showWinValue(formatter.format(winvalue));
              winBtnHandle(winvalue)
            }else{
              clearInterval(basewiningInterval);
            }
          }, 0.3);
        }
        else if(value == 0){
          // console.log('counter in 0 value')        
          showWinValue(0)
          winBtnHandle(0)
        }
        updatedollarValue(dollarValue)
      }


      //  function UpdateWinValue(value,dollarValue){
      //   console.log("updatevalue",freespinTypebool,stickyspinType,value,dollarValue)
        
      //   value = parseInt(value)
      //   subWinTextvalue.x = (app.screen.width - WinText.width) / 2;
      //   if (freespinTypebool == true && stickyspinType == false) {
      //     freespinWinvalue = parseInt(WinTextvalue.text)     
      //     freespinIntervalCount = setInterval(() => {
      //       if (freespinWinvalue >= value) {
      //         clearInterval(freespinIntervalCount);
      //         freespinWinvalue = value;
      //         bottom.visible = true;
      //       }
      //       else{
      //         if (currentfreeSpin  == 0 && freeSpin < 1 && freespinWinvalue >= 0 && remaningStickeySpin == 0) {
      //           setTimeout(() => {
      //             freeSpinType = false;
      //             // playFreeWinAudio();
      //             freeSpinCredits.text = `${finalFreeSpinWinning}`;
      //           }, 5000);
      //         }            
      //       }
      //       if((value < 5000)){
      //         freespinWinvalue = freespinWinvalue + 3;
              
      //         if (freespinWinvalue > value) {
      //           freespinWinvalue = value;
      //           // playFreeWinAudio();
      //         }
      //       }else if(value > 5000){
      //         freespinWinvalue = freespinWinvalue + 99;
      //         // playFreeWinAudio();              
      //         if (freespinWinvalue > value) {
      //           freespinWinvalue = value;
      //           // playFreeWinAudio();

      //         }
      //       }

      //       showWinValue(freespinWinvalue);

      //     }, 0.01);

      //   }
      //   else if(freespinTypebool == false && stickyspinType == true){
      //     // console.log('stickyspin',totalWinstickyamount,totalFireAmountInDollar,remaningStickeySpin,value)          
      //     stickywiningInterval = setInterval(() => {
      //       if (totalWinstickyamount < value) {
      //          if (value > 1000 && value <= 5000) {
      //           totalWinstickyamount = totalWinstickyamount + 9;
      //           if (totalWinstickyamount > value) {
      //             totalWinstickyamount = value;
      //           }
      //           showWinValue(totalWinstickyamount);
      //         }
      //         else if (value > 5000 && value < 10000) {
      //           totalWinstickyamount = totalWinstickyamount + 10;
      //           if (totalWinstickyamount > value) {
      //             totalWinstickyamount = value;
      //           }
      //           showWinValue(totalWinstickyamount);
      //         } else if (value >= 10000) {
      //           totalWinstickyamount = totalWinstickyamount + 99;
      //           if (totalWinstickyamount > value) {
      //             totalWinstickyamount = value;
      //           }
      //           showWinValue(totalWinstickyamount);
      //         } else {
      //           totalWinstickyamount = totalWinstickyamount + 1;
      //           showWinValue(totalWinstickyamount);
      //         }
      //       } else {
      //         bottom.visible = true;
      //         SpinBtn2.visible = false;
      //         clearInterval(stickywiningInterval);
      //           if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
      //           backToPreviousLayout();
      //         }
      //       }
      //     }, 0.3);
      //   }
      //   else if(freespinTypebool == true && stickyspinType == true){
      //     // console.log('free+sticky spin',freeGamesWin,stickyspinType,value)        
      //     let winingAmount = parseInt(WinTextvalue.text);
      //     freestickyInterval = setInterval(() => {
      //       if ( winingAmount < value) {
      //         if (value > 5000 && value < 10000) {
      //           winingAmount = winingAmount + 10;
      //           if (winingAmount > value) {
      //             winingAmount = value;
      //           }
      //           showWinValue(winingAmount);
      //         } else if (value >= 10000) {
      //           winingAmount = winingAmount + 99;
      //           if (winingAmount > value) {
      //             winingAmount = value;
      //           }
      //           showWinValue(winingAmount);
      //         } else {
      //           winingAmount = winingAmount + 1;
      //           showWinValue(winingAmount);
      //         }
      //       }else{
      //         clearInterval(freestickyInterval);
      //         if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
      //           backToPreviousLayout();
      //         }else{
      //           bottom.visible = true;
      //           SpinBtn2.visible = false;
      //         }
      //       }
      //     }, 0.1);
      //   }
      //   else if(value > 0){
      //     let winvalue = 0;
      //     let basewiningInterval = setInterval(() => {
      //      // console.log(winvalue,value,'value1111')
      //       if (winvalue < value) {
      //         winvalue = winvalue + 1;
      //         showWinValue(winvalue);
      //       }else{
      //         clearInterval(basewiningInterval);
      //       }
      //     }, 0.3);
      //   }
      //   else if(value == 0){
      //     // console.log('counter in 0 value')        
      //     showWinValue(0)
      //   }

      //   updatedollarValue(dollarValue)

      // }


      let freespinIntervalCountdollar;
      let freespinWinvaluedollar = 0;
      let stickywiningIntervaldollar;
      let freestickyIntervaldollar;




      // function updatedollarValue(dollarValue){
      //   // console.log(dollarValue)
    
      //   if (freespinTypebool == true && stickyspinType == false) {
      //     console.log("comming free spin")
      //     // freespinWinvaluedollar = parseInt(subWinTextvalue.text)
      //     freespinWinvaluedollar = subWinTextvalue.text.slice(1)

      //     freespinWinvaluedollar = parseFloat(freespinWinvaluedollar)
      //     console.log(freespinWinvaluedollar,'Free Spin winValue+++  6---7')
      //     freespinIntervalCountdollar = setInterval(() => {
      //       if (freespinWinvaluedollar >= Number(dollarValue)) {
      //         clearInterval(freespinIntervalCountdollar);
      //         freespinWinvaluedollar = dollarValue;
      //         console.log(freespinWinvaluedollar,'Free Spin winValue+++  2')
      //         bottom.visible = true;
      //       }
      //       else{
      //         if (currentfreeSpin  == 0 && freeSpin < 1 && freespinWinvaluedollar >= 0 && remaningStickeySpin == 0) {
      //           setTimeout(() => {
      //             freeSpinType = false;
      //             freeSpinCredits.text = `${finalFreeSpinWinning}`;
      //           }, 5000);
      //         }            
      //       }
      //       if((dollarValue < 5000)){
      //         freespinWinvaluedollar = freespinWinvaluedollar + 0.03;
      //         if (freespinWinvaluedollar > dollarValue) {
      //           freespinWinvaluedollar = dollarValue;
      //         }
      //         }else if(dollarValue > 5000){
      //           freespinWinvaluedollar = freespinWinvaluedollar + 0.1;
      //         if (freespinWinvaluedollar > dollarValue) {
      //           freespinWinvaluedollar = dollarValue;
      //         }
      //       }

      //       showWinValuedollar(freespinWinvaluedollar);
      //       console.log(freespinWinvaluedollar,'Free Spin winValue+++  6',dollarValue)

      //     }, 0.1);
      //   }
      //   else if(freespinTypebool == false && stickyspinType == true){
      //     // console.log('stickyspin',totalWinstickyamount,totalFireAmountInDollar,remaningStickeySpin,value)          
      //     stickywiningIntervaldollar = setInterval(() => {
      //       if (totalWinstickyamountdollar < dollarValue) {
      //         if (dollarValue > 5000 && dollarValue < 10000) {
      //           totalWinstickyamountdollar = totalWinstickyamountdollar + 9;
      //           if (totalWinstickyamountdollar > dollarValue) {
      //             totalWinstickyamountdollar = dollarValue;
      //           }
      //           showWinValuedollar(totalWinstickyamountdollar);
      //         } else if (dollarValue >= 10000) {
      //           totalWinstickyamountdollar = totalWinstickyamountdollar + 1;
      //           if (totalWinstickyamountdollar > dollarValue) {
      //             totalWinstickyamountdollar = dollarValue;
      //           }
      //           showWinValuedollar(totalWinstickyamountdollar);
      //         } else {
      //           totalWinstickyamountdollar = totalWinstickyamountdollar + 0.1;
      //           showWinValuedollar(totalWinstickyamountdollar);
      //         }
      //       } else {
      //         bottom.visible = true;
      //         SpinBtn2.visible = false;
      //         clearInterval(stickywiningIntervaldollar);
      //           if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
      //           backToPreviousLayout();
      //         }
      //       }
      //     }, 0.1);
      //   }
      //   else if(freespinTypebool == true && stickyspinType == true){
      //     // console.log('free+sticky spin',freeGamesWin,stickyspinType,value)        
      //     let winingAmountdollar = parseInt(subWinTextvalue.text);
      //     freestickyIntervaldollar = setInterval(() => {
      //       if ( winingAmountdollar < dollarValue) {
      //         if (dollarValue > 5000 && dollarValue < 10000) {
      //           winingAmountdollar = winingAmountdollar + 10;
      //           if (winingAmountdollar > dollarValue) {
      //             winingAmountdollar = dollarValue;
      //           }
      //           showWinValue(winingAmountdollar);
      //         } else if (dollarValue >= 10000) {
      //           winingAmountdollar = winingAmountdollar + 99;
      //           if (winingAmountdollar > dollarValue) {
      //             winingAmountdollar = dollarValue;
      //           }
      //           showWinValue(winingAmountdollar);
      //         } else {
      //           winingAmountdollar = winingAmountdollar + 0.1;
      //           showWinValue(winingAmountdollar);
      //         }
      //       }else{
      //         clearInterval(freestickyIntervaldollar);
      //         if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
      //           backToPreviousLayout();
      //         }else{
      //           bottom.visible = true;
      //           SpinBtn2.visible = false;
      //         }
      //       }
      //     }, 0.1);
      //   }
      //   else if(dollarValue > 0){
      //     let winvaluedollar = 0;
      //     let basewiningInterval = setInterval(() => {
      //      // console.log(winvalue,value,'value1111')
      //       if (winvaluedollar < dollarValue) {
      //         winvaluedollar = winvaluedollar + 0.1;
      //         if(winvaluedollar > dollarValue)  winvaluedollar = dollarValue
      //         showWinValuedollar(winvaluedollar);
      //       }else{
      //         clearInterval(basewiningInterval);
      //       }
      //     }, 0.1);
      //   }
      //   else if(dollarValue == 0){
      //     // console.log('counter in 0 value')        
      //     showWinValuedollar(0)
      //   }
      // }

      function updatedollarValue(dollarValue){
        // console.log(freespinTypebool,stickyspinType,dollarValue)
        if (freespinTypebool == true && stickyspinType == false) {
          freespinWinvaluedollar = subWinTextvalue.text.slice(1)
          freespinWinvaluedollar = parseFloat(freespinWinvaluedollar)
          // console.log(freespinWinvaluedollar,subWinTextvalue.text)
          freespinIntervalCountdollar = setInterval(() => {
            if (freespinWinvaluedollar >= dollarValue) {
              clearInterval(freespinIntervalCountdollar);
              freespinWinvaluedollar = dollarValue;
              // console.log(freespinWinvaluedollar,'Free Spin winValue+++  2')
              bottom.visible = true;
            }
            else{
              if (currentfreeSpin  == 0 && freeSpin < 1 && freespinWinvaluedollar >= 0 && remaningStickeySpin == 0) {
                setTimeout(() => {
                  freeSpinType = false;
                  freeSpinCredits.text = `${finalFreeSpinWinning}`;
                }, 5000);
              }            
            }
            if((dollarValue > 0 && dollarValue <= 100)){
              freespinWinvaluedollar = freespinWinvaluedollar + 0.9;
              if (freespinWinvaluedollar > dollarValue) {
                freespinWinvaluedollar = dollarValue;
              }
            }
            else if((dollarValue > 100 && dollarValue < 5000)){
              freespinWinvaluedollar = freespinWinvaluedollar + 1;
              if (freespinWinvaluedollar > dollarValue) {
                freespinWinvaluedollar = dollarValue;
              }
            }else if(dollarValue > 5000){
                freespinWinvaluedollar = freespinWinvaluedollar + 9;
              if (freespinWinvaluedollar > dollarValue) {
                freespinWinvaluedollar = dollarValue;
              }
            }
            // console.log(freespinWinvaluedollar)
            showWinValuedollar(freespinWinvaluedollar);

          }, 0.1);
        }
        else if(freespinTypebool == false && stickyspinType == true){
          // console.log('stickyspin',totalWinstickyamount,totalFireAmountInDollar,remaningStickeySpin,value)          
          stickywiningIntervaldollar = setInterval(() => {
            if (totalWinstickyamountdollar < dollarValue) {
              if (dollarValue > 0 && dollarValue < 100) {
                totalWinstickyamountdollar = totalWinstickyamountdollar + 0.9;
                if (totalWinstickyamountdollar > dollarValue) {
                  totalWinstickyamountdollar = dollarValue;
                }
                showWinValuedollar(totalWinstickyamountdollar);
              }
              else if (dollarValue >= 100 && dollarValue <= 5000) {
                totalWinstickyamountdollar = totalWinstickyamountdollar + 1;
                if (totalWinstickyamountdollar > dollarValue) {
                  totalWinstickyamountdollar = dollarValue;
                }
                showWinValuedollar(totalWinstickyamountdollar);
              }
              else if (dollarValue > 5000 && dollarValue < 10000) {
                totalWinstickyamountdollar = totalWinstickyamountdollar + 9;
                if (totalWinstickyamountdollar > dollarValue) {
                  totalWinstickyamountdollar = dollarValue;
                }
                showWinValuedollar(totalWinstickyamountdollar);
              } else if (dollarValue >= 10000) {
                totalWinstickyamountdollar = totalWinstickyamountdollar + 99;
                if (totalWinstickyamountdollar > dollarValue) {
                  totalWinstickyamountdollar = dollarValue;
                }
                showWinValuedollar(totalWinstickyamountdollar);
              } else {
                totalWinstickyamountdollar = totalWinstickyamountdollar + 0.1;
                showWinValuedollar(totalWinstickyamountdollar);
              }
            } else {
              bottom.visible = true;
              SpinBtn2.visible = false;
              clearInterval(stickywiningIntervaldollar);
                if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
                backToPreviousLayout();
              }
            }
          }, 0.1);
        }
        else if(freespinTypebool == true && stickyspinType == true){
          let winingAmountdollar = subWinTextvalue.text.slice(1)
          winingAmountdollar = parseFloat(winingAmountdollar)
          // console.log('free+sticky spin',winingAmountdollar,dollarValue)        

          freestickyIntervaldollar = setInterval(() => {
            if ( winingAmountdollar < dollarValue) {
               if (dollarValue > 0 && dollarValue < 100) {
                winingAmountdollar = winingAmountdollar + 0.4;
                if (winingAmountdollar > dollarValue) {
                  winingAmountdollar = dollarValue;
                }
                showWinValuedollar(winingAmountdollar);
              } 
              else if (dollarValue >= 100 && dollarValue <= 5000) {
                winingAmountdollar = winingAmountdollar + 9;
                if (winingAmountdollar > dollarValue) {
                  winingAmountdollar = dollarValue;
                }
                showWinValuedollar(winingAmountdollar);
              } 
              else if (dollarValue > 5000 && dollarValue < 10000) {
                winingAmountdollar = winingAmountdollar + 10;
                if (winingAmountdollar > dollarValue) {
                  winingAmountdollar = dollarValue;
                }
                showWinValuedollar(winingAmountdollar);
              } else if (dollarValue >= 10000) {
                winingAmountdollar = winingAmountdollar + 99;
                if (winingAmountdollar > dollarValue) {
                  winingAmountdollar = dollarValue;
                }
                showWinValuedollar(winingAmountdollar);
              } 
              else {
                winingAmountdollar = winingAmountdollar + 0.1;
                showWinValuedollar(winingAmountdollar);
              }
            }else{
              clearInterval(freestickyIntervaldollar);
              if (stickyBackgroundImg.visible == true && remaningStickeySpin <= 0) {
                backToPreviousLayout();
              }else{
                bottom.visible = true;
                SpinBtn2.visible = false;
              }
            }
          }, 0.1);
        }
        else if(dollarValue > 0){
          let winvaluedollar = 0;
          let basewiningInterval = setInterval(() => {
           // console.log(winvalue,value,'value1111')
            if (winvaluedollar < dollarValue) {
              winvaluedollar = winvaluedollar + 0.9;
              if(winvaluedollar > dollarValue)  winvaluedollar = dollarValue
              showWinValuedollar(winvaluedollar);
            }else{
              clearInterval(basewiningInterval);
            }
          }, 0.1);
        }
        else if(dollarValue == 0){
          // console.log('counter in 0 value')        
          showWinValuedollar(0)
        }
         

      }

    

      subWinTextvalue = new BitmapText(`$${"0.00"}`, betSubTextStyle);
      subWinTextvalue.x = (app.screen.width - subWinTextvalue.width) / 2;
      subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.913;
      // app.stage.addChild(subWinTextvalue);

      function showWinValue(winVal) {
       WinTextvalue.text = winVal 
        if (window.innerWidth <= 480) {
          if (WinTextvalue.text.length == 5) {
            WinTextvalue.x = X1percent * 46;
          } else if (WinTextvalue.text.length == 4) {
            WinTextvalue.x = X1percent * 48;
          } else if (WinTextvalue.text.length == 3) {
            WinTextvalue.x = X1percent * 49;
          } else {
            WinTextvalue.x = X1percent * 52.5;
          }
          subWinTextvalue.height = Y1percent * 3.2;
          subWinTextvalue.width = X1percent * 12;
          subWinTextvalue.x = (app.screen.width - subWinTextvalue.width) / 2;
          subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.913;
        } else {
          if (WinTextvalue.text.length == 9) {
            WinTextvalue.x = X1percent * 44;
          }
          if (WinTextvalue.text.length == 8) {
            WinTextvalue.x = X1percent * 44.5;
          }
          if (WinTextvalue.text.length == 7) {
            WinTextvalue.x = X1percent * 45;
          }
          if (WinTextvalue.text.length == 6) {
            WinTextvalue.x = X1percent * 46;
          }
          if (WinTextvalue.text.length == 5) {
            WinTextvalue.x = X1percent * 46.5;
          } else if (WinTextvalue.text.length == 4) {
            WinTextvalue.x = X1percent * 47.5;
          } else if (WinTextvalue.text.length == 3) {
            WinTextvalue.x = X1percent * 48;
          } else if (WinTextvalue.text.length == 2) {
            WinTextvalue.x = X1percent * 48.5;
          } else {
            WinTextvalue.x =
              defaultLang == "es"
                ? (app.screen.width - WinText.width) / 1.9
                : (app.screen.width - WinText.width) / 1.96;
         }
        }
      }

      function showWinValuedollar(winVal){
        let subwin = parseFloat(winVal);
          var subwinVal = subwin % 1 === 0 ? subwin.toFixed(2).replace(/\.0$/, "") : subwin.toFixed(2);
          subWinTextvalue.text = `$${formatNumber(subwinVal)}`;
          subWinTextvalue.x = (app.screen.width - subWinTextvalue.width) / 2;
          subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.913;

        if (window.innerWidth <= 480) {
          subWinTextvalue.height = Y1percent * 3.2;
          subWinTextvalue.width = X1percent * 12;
          subWinTextvalue.x = (app.screen.width - subWinTextvalue.width) / 2;
          subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.913;
        } 
      }

      

      async function handleDenominationWithBtn(denominationArr) {
        const buttonContainer = new PIXI.Container();
        const buttonContainerBackTexture = await PIXI.Assets.load("/assets/Denominations/Denom-Slide.png");
        const buttonContainerBackSprite = PIXI.Sprite.from(buttonContainerBackTexture);
        buttonContainerBackSprite.width = app.screen.width / 2.1;
        buttonContainerBackSprite.height = app.screen.height / 3;
        buttonContainerBackSprite.anchor.set(0.5);
        buttonContainerBackSprite.x = app.screen.width / 2;
        buttonContainerBackSprite.y = app.screen.height / 2;
        buttonContainer.visible=false;
        buttonContainer.addChild(buttonContainerBackSprite);
        app.stage.addChild(buttonContainer);
        const textStyle = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 36,
            fill: '#ffffff',
            align: 'center'
        });
        const text = new PIXI.Text('CHOOSE YOUR DENOMINATION', textStyle);
        text.anchor.set(0.5);
        text.x = buttonContainerBackSprite.width / 0.95;
        text.y = buttonContainerBackSprite.height / 0.8;
        buttonContainer.addChild(text);
        const buttonsContainer = new PIXI.Container();
        buttonContainerBackSprite.addChild(buttonsContainer);
      
        buttonsContainer.x = buttonContainerBackSprite.width / 2;
        buttonsContainer.y = buttonContainerBackSprite.height / 2;
        buttonsContainer.pivot.set(buttonsContainer.width / 2, buttonsContainer.height / 2);
        
        
        const textStyle1 = new PIXI.TextStyle({
          fontFamily: 'Arial',
          fontSize: 40,
          fill: '#ffffff',
          align: 'center'
        });
       const style = new TextStyle({
          fontFamily: "Arial",
          fontSize: 250,
          fontStyle: "italic",
          fontWeight: "bold",
          fill: { fill },
          stroke: { color: 0x4a1850, width: 5 },
          dropShadow: {
            color: 0x000000,
            angle: Math.PI / 6,
            blur: 4,
            distance: 6,
          },
          wordWrap: true,
          wordWrapWidth: 440,
        })
       
        const buttonTextDen = new BitmapText(`${coinDenonimation}\u20B5`, textStyle1);
      
      for (let i = 0; i < filteredArr.length; i++) {
        if (filteredArr[i].enabled) {
          const buttonBackTexture = await PIXI.Assets.load(`/assets/Denominations/Denom-Button-${i+1}.png`);
            const button = PIXI.Sprite.from(buttonBackTexture);
            button.x = i * (button.width + 10); 
            button.interactive = true;
            button.buttonMode = true;
            // eslint-disable-next-line no-loop-func
            button.on('pointerdown', () => {
              console.log(`Button clicked: ${filteredArr[i].stringAmount}`);
              buttonTextDen.text = `${filteredArr[i].stringAmount*100}\u20B5`
              buttonContainer.visible = !buttonContainer.visible;

              /************************************************ */
        
              coinDenonimation = filteredArr[i].stringAmount*100;
              betAmountsubText.text = `$${formatNumber((betVal/100)*coinDenonimation)}`;
              BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
              MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
              stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
              MiniText.text = `$${formatter.format(
                (betVal / 5) * coinDenonimation
              )}.00`;
              stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(
                2
              )}`;

              creditsBtnHandle(Math.floor(creditsVal / coinDenonimation),creditsVal);
              removingfunction();
              winBlackBg.visible = false;
              creditsValue.x =
                creditsValue.text.length === 3
                  ? X1percent * 30.8
                  : creditsValue.text.length === 12
                  ? X1percent * 27
                  : creditsValue.text.length === 5
                  ? X1percent * 30
                  : creditsValue.text.length === 6
                  ? X1percent * 29.5
                  : creditsValue.text.length === 7
                  ? X1percent * 28.8
                  : creditsValue.text.length === 8
                  ? X1percent * 29
                  : creditsValue.text.length === 9
                  ? X1percent * 28.3
                  : creditsValue.text.length === 11
                  ? X1percent * 27.5
                  : creditsValue.text.length === 10
                  ? X1percent * 27.5
                  : creditsValue.text.length === 2
                  ? X1percent * 31.5
                  : X1percent * 32;
              responsiveTextMiniMinor();

              /************************************************** */
          });
            const buttonText = new PIXI.Text(`${filteredArr[i].stringAmount * 100}\u20B5`,  style);
            buttonText.anchor.set(0.5);
            buttonText.x = button.width / 2;
            buttonText.y = button.height / 2;
            button.addChild(buttonText);
            buttonsContainer.addChild(button);
        }
      }
      console.log(coinDenonimation,'coinDenonimation')
        buttonsContainer.pivot.set(buttonsContainer.width/ 1.68, buttonsContainer.height / 2);
        const denominationBtnTexture = await PIXI.Assets.load("/assets/Backgrounds/Hud_tutoriales.png");
        const denominationBtn = PIXI.Sprite.from(denominationBtnTexture);
        denominationBtn.interactive = true;
        denominationBtn.buttonMode = true;
        denominationBtn.width = app.screen.height / 7; 
        denominationBtn.height = app.screen.height / 7; 
        denominationBtn.x = (app.screen.width - denominationBtn.width) / 10;
        denominationBtn.y = (app.screen.height - denominationBtn.height) / 1;
        denominationBtn.on('pointerdown', () => {
        buttonContainer.visible = !buttonContainer.visible;
        });
        
        buttonTextDen.anchor.set(0.5);
        buttonTextDen.x = 90;
        buttonTextDen.y = 85;
        denominationBtn.addChild(buttonTextDen);
        app.stage.addChild(denominationBtn);
      }
      
      handleDenominationWithBtn(filteredArr);




      // ********* Circular Image/Denomination Image imported
      const DenominationImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );
      DenominationImg.height = app.screen.height / 7;
      DenominationImg.width = app.screen.height / 7;
      DenominationImg.x = (app.screen.width - DenominationImg.width) / 10;
      DenominationImg.y = (app.screen.height - DenominationImg.height) / 1;
    //   app.stage.addChild(DenominationImg);

      // Circular Image/Denomination text added
      const DenominationText = new BitmapText(`${1}\u20B5`, coinDenomTextStyle);
      DenominationText.x =
        window.innerWidth > 736 ? X1percent * 11.4 : X1percent * 11.7;
      DenominationText.y =
        (app.screen.height - DenominationText.height) / 1.065;
    //   app.stage.addChild(DenominationText);

      // ********* Info Image imported
      const InfoImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_Game1of10.png")
      );
      InfoImg.height = app.screen.height / 16;
      InfoImg.width =
        defaultLang == "es" ? app.screen.width / 9 : app.screen.width / 20;
      InfoImg.x =
        defaultLang == "es"
          ? (app.screen.width - InfoImg.width) / 14
          : (app.screen.width - InfoImg.width) / 9;
      InfoImg.y = (app.screen.height - InfoImg.height) / 1.2;
      app.stage.addChild(InfoImg);

      const infoTextStyle = new TextStyle({
        fontSize: app.screen.width / 90,
        fontWeight: 400,
        fill: "#fff",
        fontFamily: "roboto, sans-serif , Arial",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      // Info text added
      const InfoText = new BitmapText(
        getMessage(defaultLang, "info").toUpperCase(),
        infoTextStyle
      );
      InfoText.x =
        defaultLang == "es"
          ? (app.screen.width - InfoText.width) / 11.5
          : (app.screen.width - InfoText.width) / 8.25;
      InfoText.y = (app.screen.height - InfoImg.height) / 1.175;
      app.stage.addChild(InfoText);

      // ********* FoF/Flames of Fortune Image imported
      const FoFImg = new Sprite(Texture.from("/assets/Link/FoF-Logo.png"));
      FoFImg.height = app.screen.height / 10;
      FoFImg.width = app.screen.width / 8;
      FoFImg.x = (app.screen.width - FoFImg.width) / 1.15;
      FoFImg.y = (app.screen.height - FoFImg.height) / 1.02;
      // FoFImg.visible = false;

      // Spin Button added here
      const SpinbuttonBg2 = Texture.from("/assets/Icons/spin.png");
      const SpinBtn2 = new Sprite(SpinbuttonBg2);
      SpinBtn2.alpha = 1;
      SpinBtn2.height = app.screen.height / 11;
      SpinBtn2.width = app.screen.width / 14;
      SpinBtn2.y = (app.screen.height - SpinBtn2.height) / 2.2;
      SpinBtn2.x = Math.round((bottom.width * 4 - SpinBtn2.width * 1.5) / 4.93);
      app.stage.addChild(SpinBtn2);
      SpinBtn2.visible = false;

      const skipButton = new Sprite(Texture.from("/assets/Link/next.png"));
      skipButton.visible = false;
      skipButton.alpha = 1;
      skipButton.height = app.screen.height / 11;
      skipButton.width = app.screen.width / 14;
      skipButton.y = (app.screen.height - skipButton.height) / 2.2;
      skipButton.x = Math.round(
        (bottom.width * 4 - skipButton.width * 1.5) / 4.93
      );
      app.stage.addChild(skipButton);
      skipButton.eventMode = "static";
      skipButton.cursor = "pointer";
      skipButton.addListener(
        app.screen.width > 992 ? "click" : "tap",
        async (event) => {
          skipStickyKey();
        }
      );


      // function to skip sticky spin winning number counter 
      function skipStickyKey() {
        clearInterval(stickywiningInterval);
        // showWinValue(Number(totalStickySpinAmountInCredits));
        clearInterval(freestickyInterval);
        clearInterval(freestickyIntervaldollar);
        (totalStickySpinAmountInCredits > 0) ? showWinValue(Number(totalStickySpinAmountInCredits)) : showWinValue(Number(totalFireAmountInDollar.toFixed(2)) * 100); 
        (totalStickySpinAmountInCredits > 0) ? winBtnHandle(Number(totalStickySpinAmountInCredits)) : winBtnHandle(Number(totalFireAmountInDollar.toFixed(2)) * 100); 
        backToPreviousLayout();
      }

      // const stickytBtn2 = new Sprite(Texture.from("/assets/Icons/spin.png")); //winBtnHandle
      // stickytBtn2.alpha = 1;
      // stickytBtn2.height = app.screen.height / 11;
      // stickytBtn2.width = app.screen.width / 14;
      // stickytBtn2.y = 0;
      // stickytBtn2.x = 0;
      // app.stage.addChild(stickytBtn2);
      // stickytBtn2.eventMode = "static";
      // stickytBtn2.cursor = "pointer";
      // stickytBtn2.addListener(
      //   app.screen.width > 992 ? "click" : "tap",
      //   async (event) => {
      //     testKeyVar = 1;
      //   }
      // );

      // Spin Button added here
      const SpinbuttonBg = Texture.from("/assets/Icons/spin.png");
      const SpinBtn = new Sprite(SpinbuttonBg);
      SpinBtn.alpha = 1;
      SpinBtn.height = app.screen.height / 11;
      SpinBtn.width = app.screen.width / 14;
      SpinBtn.y = (app.screen.height - SpinBtn.height) / 2.2;
      SpinBtn.x = Math.round((bottom.width * 4 - SpinBtn.width * 1.5) / 4.93);
      bottom.addChild(SpinBtn);

      const reelSymbolsContainer = new Container();
      reelSymbolsContainer.height =
        window.innerWidth > 480 ? app.screen.height / 3 : app.screen.height / 2;
      reelSymbolsContainer.mask = new Graphics();
      reelSymbolsContainer.mask.beginFill(0xffffff);
      reelSymbolsContainer.mask.drawRect(
        0,
        (app.screen.height - reelSymbolsContainer.height) / 5.1,
        (app.screen.width - reelSymbolsContainer.width) / 1,
        window.innerWidth > 480
        ? (app.screen.height - reelSymbolsContainer.height) / 1.65
          : (app.screen.height - reelSymbolsContainer.height) / 1.62
      ); 

      // reelSymbolsContainer.mask.drawRect(
      //   0,
      //   0,
      //   (app.screen.width - reelSymbolsContainer.width) / 1,
      //   window.innerWidth > 480
      //   ? (app.screen.height - reelSymbolsContainer.height) / 1
      //     : (app.screen.height - reelSymbolsContainer.height) / 1.62
      // ); 
      
      
      
      // set the mask to a rectangle with a width of 400 and a height of 300
      reelSymbolsContainer.mask.endFill();
      reelSymbolsContainer.hitArea = new Rectangle(
        0,
        0,
        (app.screen.width - reelSymbolsContainer.width) / 2,
        (app.screen.height - reelSymbolsContainer.height) / 1.35
      );

      const winBlackBg = new Sprite(
        Texture.from("/assets/MainBG/Dorado_background.png")
      );
      winBlackBg.height = app.screen.height;
      winBlackBg.width = app.screen.width;
      app.stage.addChildAt(winBlackBg, 20);
      winBlackBg.visible = false;
      winBlackBg.x = 0;
      winBlackBg.y = 0;

      app.stage.addChildAt(reelSymbolsContainer, 20);
      // app.stage.addChildAt(stickySpinBg, 21);

      // Build the reels
      let rc;
      const reels = [];
      let fireiconPos = [
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
        {x:X1percent * 6.4,y:Y1percent * 12},
      ];
      let running = false;
      const reelContainer = new Container();
      let reelDataIndex = 0;
      for (let i = 0; i < 5; i++) {
        rc = new Container();

        rc.x = window.innerWidth <= 480 ? i * REEL_WIDTH * 1.5 : i * REEL_WIDTH;
        rc.y = window.innerWidth <= 480 ? 50 : 0;

        reelContainer.addChild(rc);

        const reel = {
          container: rc,
          symbols: [],
          position: 0,
          previousPosition: 0,
          visible: "true",
          blur: new BlurFilter(),
        };
        // Build the symbols
        for (let j = 0; j < 5; j++) {
          let data =
            slotTextures?.length > 0 && modifyGameLoad?.length > 0
              ? slotTextures?.filter(
                  (r) => r?.name == modifyGameLoad[reelDataIndex]
                )[0]
              : [];
          // const rndm = Math.floor(Math.random() * reelBuildData.length);
          // const symbol = new Sprite(reelBuildData[rndm]);
          const symbol = new Sprite(data);
          symbol.y =
            window.innerWidth <= 480 ? j * SYMBOL_SIZE * 2.6 : j * SYMBOL_SIZE;
          // symbol.scale.x = symbol.scale.y = Math.min(
          //   SYMBOL_SIZE / symbol.width,
          //   SYMBOL_SIZE / symbol.height
          // );
          symbol.width = X1percent * 10;
          symbol.height = Y1percent * 22;
          symbol.x = Math.round((SYMBOL_SIZE - symbol.width) / 2);
          animationSize.width = symbol.width;
          animationSize.height = symbol.height;
          reel.symbols.push(symbol);
          rc.addChild(symbol);
          if (modifyGameLoad?.length == reelDataIndex) {
            reelDataIndex = 0;
          } else {
            reelDataIndex++;
          }
        }
        reels.push(reel);
      }
      reelSymbolsContainer.addChild(reelContainer);

      // Build top & bottom covers and position reelContainer
      reelContainer.height = app.screen.height / 1.2;
      // reelContainer.y = (app.screen.height - reelContainer.height) / 1.36;
      // reelContainer.x = (app.screen.width - reelContainer.width) / 2;
      reelContainer.y = (app.screen.height - reelContainer.height) / 1;
      reelContainer.x = (app.screen.width - reelContainer.width) / 2.05;

      // Create gradient fill
      const fill = new FillGradient(0, 0, 0, 36 * 1.7);

      const colors = [0xffffff, 0x00ff99].map((color) =>
        Color.shared.setValue(color).toNumber()
      );

      colors.forEach((number, index) => {
        const ratio = index / colors.length;

        fill.addColorStop(ratio, number);
      });
      app.stage.addChild(top);
      app.stage.addChild(bottom);
      // Set the interactivity.
      bottom.eventMode = "static";
      bottom.cursor = "pointer";
      bottom.addListener(
        app.screen.width > 992 ? "click" : "tap",
        async (event) => {
          const modal = document.querySelectorAll("addCredit");

          if (creditsVal >= BetSuboutput * coinDenonimation * 100) {
            modal.visible = false;
            payLineTrueHandle();
            startPlay();
            await gameSpinApi(false);
          } else {
            modalContainer.visible = true;
            setTimeout(() => {
              modalContainer.visible = false;
            }, 1500);
          }
        }
      );

      const creditPopup = new TextStyle({
        fontSize: app.screen.width / 60,
        align: "center", // set text alignment to center
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "normal",
        fill: "#fff",
        // stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
        lineHeight: 60, // set line height
      });

      const modalContainer = new Container();
      const modalBackground = new Sprite(
        Texture.from("/assets/MainBG/modalBg.png")
      );
      modalBackground.scale.set(0.6);
      modalContainer.addChild(modalBackground);
      modalContainer.addChild(modalBackground);
      const modalContent = new Text(
        `Not enough Credits! Please,${
          window.innerWidth > 1200 && window.innerWidth < 1400 ? "\n" : ""
        }  Add credits to play.`,
        creditPopup
      );
      modalContent.anchor.x =
        window.innerWidth > 1600
          ? -0.3
          : window.innerWidth > 1366 && window.innerWidth < 1600
          ? -0.9 / 5
          : -0.5;
      modalContent.anchor.y = -0.8;
      modalContainer.addChild(modalContent);
      modalBackground.width = app.screen.width / 3;
      modalBackground.height = app.screen.height / 3;
      modalContainer.x = app.screen.width / 2;
      modalContainer.y = app.screen.height / 2;
      modalContainer.x = (app.screen.width - modalContainer.width) / 2;
      modalContainer.y = (app.screen.height - modalContainer.height) / 2;

      modalBackground.x =
        window.innerWidth > 1600
          ? (app.screen.width - modalBackground.width) / 90
          : window.innerWidth > 1366 && window.innerWidth < 1600
          ? (app.screen.width - modalBackground.width) / 80
          : (app.screen.width - modalBackground.width) / 15;
      modalBackground.y = (app.screen.height - modalBackground.height) / 15;
      app.stage.addChildAt(modalContainer, 30);

      modalContainer.visible = false;

      // play spin audio
      const spinAudio = new Audio("/assets/Audios/railsStartSpinning.wav");
      spinAudio.volume = 0.5;

      // stop reel spin audio
      const stopReel = new Audio("/assets/Audios/railStop.wav");
      stopReel.volume = 0.5;

      const stopReel1 = new Audio("/assets/Audios/railStop.wav");
      stopReel.volume = 0.5;

      const stopReel2 = new Audio("/assets/Audios/railStop.wav");
      stopReel.volume = 0.5;

      const stopReel3 = new Audio("/assets/Audios/railStop.wav");
      stopReel.volume = 0.5;

      const stopReel4 = new Audio("/assets/Audios/railStop.wav");
      stopReel.volume = 0.5;

      // Win audio
      const winAudio1 = new Audio("/assets/Audios/BaseCelebration1.wav");
      winAudio1.volume = 1;

      const winAudio2 = new Audio("/assets/Audios/BaseCelebration2.wav");
      winAudio2.volume = 1;

      const winAudio3 = new Audio("/assets/Audios/BaseCelebration3.wav");
      winAudio3.volume = 1;

      // Win audio
      const freeWinAudio1 = new Audio(
        "/assets/Audios/FreeGames/FreeGamesCelebration/FreeGamesCelebration1.wav"
      );
      freeWinAudio1.volume = 1;

      const freeWinAudio2 = new Audio(
        "/assets/Audios/FreeGames/FreeGamesCelebration/FreeGamesCelebration2.wav"
      );
      freeWinAudio2.volume = 1;

      const freeWinAudio3 = new Audio(
        "/assets/Audios/FreeGames/FreeGamesCelebration/FreeGamesCelebration3.wav"
      );
      freeWinAudio3.volume = 1;

      const freeWinAudio4 = new Audio(
        "/assets/Audios/FreeGames/FreeGamesCelebration/FreeGamesCelebration4.wav"
      );
      freeWinAudio4.volume = 1;

      //free Spin Audio
      // play spin audio
      const freeSpinRing = new Audio(
        "/assets/Audios/FreeGames/FreeGamesChicharra.wav"
      );
      freeSpinRing.volume = 0.5;

      const freeSpinMusic = new Audio(
        "/assets/Audios/FreeGames/FreeGamesMusic.wav"
      );
      freeSpinMusic.volume = 0.5;

      const freeSpinScream = new Audio(
        "/assets/Audios/FreeGames/FreeGamesScream.wav"
      );
      freeSpinScream.volume = 0.5;

      //free Spin Audio
      // play spin audio
      const LinkHitRing = new Audio("/assets/Audios/Link/LinkGolpeMeteoro.wav");
      LinkHitRing.volume = 0.5;

      const LinkHitRing2 = new Audio(
        "/assets/Audios/Link/LinkMeteoroSubiendo.wav"
      );
      LinkHitRing2.volume = 0.5;

      const LinkHitMusic = new Audio("/assets/Audios/Link/LinkMusic.wav");
      LinkHitMusic.volume = 0.5;

      const LinkHitScream = new Audio("/assets/Audios/Link/LinkScream.mp3");
      LinkHitScream.volume = 0.5;

      // // spin button play on press of Space button
      // window.addEventListener("keydown", async (e) => {
      //   // if(e.keyCode === 32){
      //   if (reelrunning == false && disableSpace == false) {
      //     if (freeGamesWin === true && currentfreeSpin === 5) {
      //       if (e.key === " " || e.key === "Spacebar") {
      //         start();
      //         startFreeSpin.visible = false;
      //         console.log('this is working')
      //       }
      //     } else {
      //       if (e.key === " " || e.key === "Spacebar") {
      //         const modal = document.querySelectorAll("addCredit");

      //         if (creditsVal >= BetSuboutput * coinDenonimation * 100) {
      //           // if (creditsVal >= BetSuboutput * coinDenonimation * 100) {
      //           modal.visible = false;
      //           payLineTrueHandle();
      //           startPlay();
      //           winBlackBg.visible = false;
      //           await gameSpinApi(false);
      //           // updateCredits(Math.floor(creditsVal / coinDenonimation));
      //           // updateCreditsSubVal(creditsVal);
      //         } else {
      //           modalContainer.visible = true;
      //           setTimeout(() => {
      //             modalContainer.visible = false;
      //           }, 1500);
      //         }
      //       }
      //     }
      //   }
      // });

      // let reelArr = [];
      function startPlay() {
       
        setTimeout(() => {
          hitApi = false;
        }, 300);
          reels.forEach((reel) => {
            reel.container.children.forEach((e) => {
             e.visible = true;
            });
          });
        if(freeSpin > 0){
          freeSpinText.text = `${freeSpin}`
          console.log(freeSpinText.text,'inside this --- 2')

        }else if(currentfreeSpinGameLoad > 0){

          if(freeGamesWin === true && remaingFreeSpin === 1){
            freeSpinText.text = 0
            console.log(freeSpinText.text,'inside this --- 55')
          }else{
            freeSpinText.text = `${Number(currentfreeSpinGameLoad)-1}`
            console.log(freeSpinText.text,'inside this --- 3')
          }
        }

        if (freeGamesWin == false && freeSpin <= 0 && spaceFilledWtihFire < 6) {
          // console.log('start play')
          stickyspinType = false;
          totalWinstickyamount = 0;
          totalWinstickyamountdollar = 0;
          clearInterval(basewiningInterval)
          UpdateWinValue(0,0)
        }
        
        reelrunning = true;
        winBlackBg.visible = false;

        if(remaningStickeySpin !== 0){
          stickyremainingCount.text = remaningStickeySpin - 1;
        }
        if (freespinTypebool === false && stickyspinType === false) {

        //   updateCredits(Math.round(creditsVal / coinDenonimation - betVal));
          creditsBtnHandle(Math.round(creditsVal / coinDenonimation - betVal),((Math.round(creditsVal / coinDenonimation - betVal) *
          coinDenonimation)));
          let input =
            ((Math.round(creditsVal / coinDenonimation - betVal) *
              coinDenonimation) /
            100);
          // console.log(creditsVal?.text, "creditsVal ----- 11", coinDenonimation);
          let output =
            input % 1 === 0
              ? input.toFixed(2).replace(/\.0$/, "")
              : input.toFixed(2);

              
          let outputNumber = formatter.format(output);
          creditsSubValue.text = `$${formatNumber(output)}`;
          creditsValue.x =
            creditsValue.text.length === 3
              ? X1percent * 30.8
              : creditsValue.text.length === 12
              ? X1percent * 27
              : creditsValue.text.length === 5
              ? X1percent * 30
              : creditsValue.text.length === 6
              ? X1percent * 29.5
              : creditsValue.text.length === 7
              ? X1percent * 28.8
              : creditsValue.text.length === 8
              ? X1percent * 29
              : creditsValue.text.length === 9
              ? X1percent * 28.3
              : creditsValue.text.length === 11
              ? X1percent * 27.5
              : creditsValue.text.length === 10
              ? X1percent * 27.5
             : creditsValue.text.length === 2
              ? X1percent * 31.5
              : X1percent * 32;
        }

        removeNumberonFire();
        occurenceArr = "";
        IsspinReelAnimation = false;
        if (currentfreeSpin > 0) {
          SpinBtn.visible = false;
          SpinBtn2.visible = false;
          // bottom.eventMode = "none";
          // bottom.cursor = "none";
        } else {
          SpinBtn2.visible = false;
        }

        winAudio1.pause();
        winAudio2.pause();
        winAudio3.pause();
        spinAudio.play();
        playStopReel();
        subWinTextvalue.x =
          window.innerWidth > 480
            ? (app.screen.width - subWinTextvalue.width) / 2
            : X1percent * 50;
        payLineTrueHandle();
        winBlackBg.visible = false;
        strickfordoblecallFunction = true;
        bottom.visible = false;
        if (running) return;
        running = true;
        removingfunction();
        reelSymbolsContainer.addChild(reelContainer);
        for (let i = 0; i < reels.length; i++) {
          const r = reels[i];
          var target = 0;
          target = r.position + 30;
          const time = 2800 + i * 300;
          tweenTo(
            r,
            "position",
            target,
            time,
            backout(0.5),
            null,
            i === reels.length - 1 ? reelsComplete : null
          );
          // if (reelArr.lenght < 5) {
          //   reelArr.splice(0, 5);
          // }

          // reelArr.push(r);
          // target = r.position + 30;
        }
       
  
      }

    
      //when play game add static text on each fire symbols 
      // function addtextstartPlay(container){
      //   console.log("43523452")
      //   let text = new BitmapText(
      //     '99',
      //     new TextStyle({
      //       fontSize: window.innerWidth > 480 ? 50 : 60,
      //       fill: "#fff",
      //       fontFamily: "sans-serif",
      //       align: "center",
      //       fontWeight: "bolder",
      //       stroke: { color: "#fff", lineWidth: 4 },
      //     })
      //   );
      //   text.anchor.set(0.5);
      //   text.position.set(125, 123);
      //   //for (var i = 0; i < reels.length; i++) {
      //     //for (let j = 0; j <= 4; j++) {
      //       //console.log(reels[i].container.children[j].texture.name,"111111")
      //       //if(reels[i].container.children[j].texture.name == 'TT'){   
      //        if(container.children.length == 0) container.addChild(text);
      //      // }
      //     //}
      //   //}

      // }

      if (freeGamesWin == true && remaingFreeSpin >= 0) {
        winAudio1.destroy();
        winAudio2.destroy();
        winAudio3.destroy();
      }

      // Reels done handler.
      async function reelsComplete() {
        reelrunning = false;
        running = false;
        testKeyVar = 0;
       
        if (winValue > 0 && freespinTypebool === false && finalFreeSpinWinning === 0) {
          UpdateWinValue(winValue,dollarWinValue);
          creditsBtnHandle(Math.round(creditsVal / coinDenonimation),creditsVal);
        //   updateCreditsSubVal(creditsVal);
          // console.log(freespinTypebool,'freespinTypebool++1')
          playWinAudio();
        }else if(freespinTypebool === true && winValue > 0 && finalFreeSpinWinning > 0){
          // console.log(winCount,'winCount----  1   coinDenonimation', coinDenonimation)
          UpdateWinValue(winValue,dollarWinValue);
          creditsBtnHandle(Math.round(creditsVal / coinDenonimation),creditsVal);
        //   updateCreditsSubVal(creditsVal);
          if(StickeySpinWon == false && winCount > 0 && dollarWinValue > 0) {
            playFreeWinAudio()
          } 
        }
        
        setTimeout(
          () => {
            if (remaingFreeSpin == 0 && spaceFilledWtihFire <= 6) {
              bottom.visible = true;
              SpinBtn2.visible = false;
            }
          },
          !occurenceArr ? 200 : 1200
        );
        await getProgressiveJackpot();
      }

      function playStopReel() {
        setTimeout(() => {
          stopReel.play();
        }, 2800);
        setTimeout(() => {
          stopReel1.play();
        }, 3100);
        setTimeout(() => {
          stopReel2.play();
        }, 3400);
        setTimeout(() => {
          stopReel3.play();
        }, 3700);
        setTimeout(() => {
          stopReel4.play();
        }, 4000);
      }
      function playWinAudio() {
        setTimeout(() => {
          if (winCount <= 20) {
            winAudio1.play();
          } else if (winCount >= 21 && winCount <= 50) {
            winAudio2.play();
          } else if (winCount >= 51) {
            winAudio3.play();
          }
        }, 100);
      }

      function playFreeWinAudio() {
        setTimeout(() => {
          if (winCount <= 300) {
            freeWinAudio1.play();
          } else if (winCount >= 301 && winCount <= 1500) {
            freeWinAudio2.play();
          } else if (winCount >= 1501 && winCount <= 5000) {
            freeWinAudio3.play();
          } else if (winCount >= 5001) {
            freeWinAudio4.play();
          }
        }, 100);
      }

      function playFreeSpinAudio() {
        setTimeout(() => {
          freeSpinScream.play();
          freeSpinMusic.play();
          freeSpinRing.play();
          freeSpinMusic.loop = true;
        }, 2000);
      }

      // // free spin oK button code starts here
      // function freespinOkButton() {
      //   freeSpinType = false;
      //   freespinWinvalue = 0;
      //   clearInterval(freeSpinInterval);
      //   clearInterval(stickywiningInterval);
      //   showWinValue(finalFreeSpinWinning);
      // }

      // fire animation number/data removing function
      function removeNumberonFire() {
        for (var i = 0; i < reels.length; i++) {
          for (let j = 0; j < 3; j++) {
            if (reels[i].container.children[j]){
             // reels[i].container.children[j].removeChildren(); //  remove overlapping Text after Completed Fever Respin
            }
          }
        }
      }

      // Listen for animate update.

      var dot = 0;
      var hitflag = false;

      app.ticker.add(() => {
        // Update the slots.
        //  addtextstartPlay();
        for (let i = 0; i < reels.length; i++) {
          const r = reels[i];
          r.blur.blurY = (r.position - r.previousPosition) * 8;
          r.previousPosition = r.position;

          // Update symbol positions on reel.
          for (let j = 0; j < r.symbols.length; j++) {
            const s = r.symbols[j];
          
            reels.forEach((reel)=>{
              reel.container.children.forEach((e)=>{
                // e.visible = true;
                // if(e.texture.name == 'TT' && hitApi === false){
                //   e.texture = fireIconwithBorder
                // }
              })
            })
            const prevy = s.y;
            if (running) {
              dot += 1;
              payLineTrueHandle();
              if (dot === 40) {
                dot = 0;
                hitflag = false;
                return setsymbols();
              }
            }
            s.y =
              ((r.position + j) % r.symbols.length) * 1.25 * SYMBOL_SIZE -
              SYMBOL_SIZE;
            if (s.y < 0 && prevy > SYMBOL_SIZE) {
              s.texture =
                slotTextures[Math.floor(Math.random() * slotTextures.length)];
              // s.scale.x = s.scale.y = Math.min(
              //   SYMBOL_SIZE / s.texture.width,
              //   SYMBOL_SIZE / s.texture.height
              // );
              // s.x = Math.round((SYMBOL_SIZE - s.width) / 2);
            }
          }
        }
      });

      // function addnumberonIcon(){
      //   reels.forEach((element) => {  
      //     element.container.children.forEach((el,index) => { 
      //       el.visible = true; 
      //       el.removeChildren() 
      //       if(el.texture.name == "TT"){
             
      //         let text;
      //     text = new BitmapText(
      //       '100',
      //       new TextStyle({
      //         fontSize: window.innerWidth > 480 ? 50 : 60,
      //         fill: "#fff",
      //         fontFamily: "sans-serif",
      //         align: "center",
      //         fontWeight: "bolder",
      //         stroke: { color: "#fff", lineWidth: 4 },
      //       })
      //     );
      //     text.anchor.set(0.5);
      //     text.position.set(125, 123);
      //     el.addChild(text)
      //       }
      //     }); 
      //   }); 
           
      // }

      var fireCount = 0;
      // let addnumberonFire = false;
      // let fireIconwithoutBorder = Texture.from("/assets/Slots/Slot_Sprite/SunFire_01.png");
      // fireIconwithoutBorder.name ='TT'

      // function swapfireicon(reels){
      //   reels.container.children.forEach((e,i)=>{
      //     if(e.texture.name == 'TT' && i !== 0 && i !== 4){
      //       e.texture = fireIconwithoutBorder
      //     }
      //   })
      // }
      function setsymbols() {
        fireCount += 1;
        // if(fireCount == 10 && addnumberonFire == false){
        //   addnumberonIcon()
        //   addnumberonFire = true;
        // }
        if (hitflag === false) {
          setTimeout(() => {
            reels[0].container.children[0].texture = imgArrSlot[5];
            reels[0].container.children[1].texture = imgArrSlot[0];
            reels[0].container.children[2].texture = imgArrSlot[1];
            reels[0].container.children[3].texture = imgArrSlot[2];  
            reels[0].container.children[4].texture = imgArrSlot[13];
            // swapfireicon(reels[0])
          }, 500);

          setTimeout(() => {

            reels[1].container.children[0].texture = imgArrSlot[13];

            reels[1].container.children[1].texture = imgArrSlot[3];

            reels[1].container.children[2].texture = imgArrSlot[4];

            reels[1].container.children[3].texture = imgArrSlot[5];

            reels[1].container.children[4].texture = imgArrSlot[2];
            // swapfireicon(reels[1])
          }, 900);

          setTimeout(() => {

            reels[2].container.children[0].texture = imgArrSlot[1];

            reels[2].container.children[1].texture = imgArrSlot[6];

            reels[2].container.children[2].texture = imgArrSlot[7];

            reels[2].container.children[3].texture = imgArrSlot[8];

            reels[2].container.children[4].texture = imgArrSlot[12];
            // swapfireicon(reels[2])
          }, 1200);

          setTimeout(() => {

            reels[3].container.children[0].texture = imgArrSlot[3];

            reels[3].container.children[1].texture = imgArrSlot[9];

            reels[3].container.children[2].texture = imgArrSlot[10];

            reels[3].container.children[3].texture = imgArrSlot[11];

            reels[3].container.children[4].texture = imgArrSlot[6];
            // swapfireicon(reels[3])
          }, 1500);

          setTimeout(() => {

            reels[4].container.children[0].texture = imgArrSlot[4];

            reels[4].container.children[1].texture = imgArrSlot[12];

            reels[4].container.children[2].texture = imgArrSlot[13];

            reels[4].container.children[3].texture = imgArrSlot[14];

            reels[4].container.children[4].texture = imgArrSlot[9];
            // swapfireicon(reels[4])
          }, 1800);
        }
        if (fireCount === 19) {
          fireCount = 0;
          if (IsspinReelAnimation == false) {
            setTimeout(() => {
              spinReelAnimation();
            }, 2800);
            IsspinReelAnimation = true;
            // addnumberonFire = false;
          }

          // spinReelAnimation();
          setTimeout(() => {
            if (StickeySpinWon == true) {
              SpinBtn.visible = false;
              SpinBtn2.visible = false;
              clearInterval(freeSpinInterval);
              setTimeout(() => {
                if (IsstickySpin == false) {
                  IsstickySpin = true;
                  triggerStikySpin();
                  stickySpinBootomTextCountHandle(spaceFilledWtihFire);
                  stickyremainingCount.text = remaningStickeySpin;
                }
              }, 4000);
            }
          }, 3000);
        }
      }

      var fireIconArray = [];
      let firiconindex = [];
   
      function addStickyNumber(reel, el, prize, prizetype) {
        firiconindex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        fireIconArray = [];
        let i;
        if (reel.length > 0 && el.length > 0) {
          for (let index = 0; index < reel.length; index++) {
            if (reel[index] == 0) {
              i = el[index];
            } else if (reel[index] == 1) {
              i = el[index] + 3;
            } else if (reel[index] == 2) {
              i = el[index] + 6;
            } else if (reel[index] == 3) {
              i = el[index] + 9;
            } else if (reel[index] == 4) {
              i = el[index] + 12;
            }
            fireIconArray.push(i);
          }
          for (let index = 0; index < fireIconArray.length; index++) {
            firiconindex[fireIconArray[index]] =
              prizetype[index] != "RegularPrize"
                ? prizetype[index]
                : prize[index];
          }
        } else if (prize?.length >= 15) {
          for (let index = 0; index < prizetype.length; index++) {
            if (prizetype[index] != "RegularPrize") {
              firiconindex[index] = prizetype[index];
            } else {
              firiconindex[index] = prize[index];
            }
          }
        } else if (linkPrizeAmount?.length >= 15) {
          for (
            let index = 0;
            index < prizeTypeForStickeySpin?.length;
            index++
          ) {
            if (prizeTypeForStickeySpin[index] != "RegularPrize") {
              firiconindex[index] = prizeTypeForStickeySpin[index];
            } else {
              firiconindex[index] = linkPrizeAmount[index];
            }
          }
        }
        setTimeout(() => {
          setPrizeinreel();
        },50);

       
      }

      //
      function setPrizeinreeladdd() {
        for (var i = 0; i < 5; i++) {
          for (let j = 1; j < 4; j++) {
            const stickySpinBgNew = new Sprite(
              Texture.from("/assets/MainBG/Dorado_Sticky_background.png")
            );
            stickySpinBgNew.label="stickySpinBgNew"
            stickySpinBgNew.height = app.screen.height/2;
            stickySpinBgNew.width = 297;
            stickySpinBgNew.x = -25;
            stickySpinBgNew.y = -25;
            if(stickyFlag == true){
              reels[i].container.children[j].addChild(stickySpinBgNew);
            }else{
              reels[i].container.removeChild(stickySpinBgNew);
            }
            
          }
        }
      }


      // Set number on spin firesymbols********************
      function setPrizeinreel() {
        let ind = 0;
        for (var i = 0; i < reels.length; i++) {
          for (let j = 1; j < 4; j++) {
          
            if (firiconindex[ind] != 0) {
              if (reels[i].container.children[j])
                if(stickyFlag == false){
                  reels[i].container.children[j].removeChildren();
                }
              colorizedText(
                firiconindex[ind],
                40,
                reels[i].container.children[j],
                reels[i].container,
                ind
              );
            } else {
              if (reels[i].container.children[j])
                if(stickyFlag == false){
                  reels[i].container.children[j].removeChildren();
                }
            }
            ind += 1;
          }
        }
      }

          
      // function for colorised text
      function colorizedText(val, gapInDigits, container,fireContainer,ind) { 
        
        try {
          let text;
          if (val != "Mini" && val != "Minor" && val != "Major") {
            text = new BitmapText(
              val.toString(),
              new TextStyle({
                fontSize: window.innerWidth > 480 ? 50 : 60,
                fill: "#fff",
                fontFamily: "sans-serif",
                align: "center",
                fontWeight: "bolder",
                stroke: { color: "#fff", lineWidth: 4 },
              })
            );
          } else if (val == "Mini") {
            text = new BitmapText(
              "MINI",
              new TextStyle({
                fontSize: window.innerWidth > 480 ? 50 : 50,
                fill: "#6356f3",
                fontFamily: "sans-serif",
                align: "center",
                fontWeight: "bolder",
                stroke: { color: "#6356f3", lineWidth: 4 },
              })
            );
          } else if (val == "Minor") {
            text = new BitmapText(
              "MINOR",
              new TextStyle({
                fontSize: window.innerWidth > 480 ? 50 : 45,
                fill: "green",
                fontFamily: "sans-serif",
                align: "center",
                fontWeight: "bolder",
                stroke: { color: "green", lineWidth: 4 },
              })
            );
          } else if (val == "Major") {
            text = new BitmapText(
              "MAJOR",
              new TextStyle({
                fontSize: window.innerWidth > 480 ? 50 : 45,
                fill: "yellow",
                fontFamily: "sans-serif",
                align: "center",
                fontWeight: "bolder",
                stroke: { color: "yellow", lineWidth: 4 },
              })
            );
          }
          text.anchor.set(0.5);
          text.position.set(125, 105);

          if(stickyContainerindex[ind] == 0){
            addfireicon(container,text,fireContainer,ind,false)
            if (stickyFlag == false) {
              spriteB00.removeChild(SunFireText);
              spriteB01.removeChild(SunFireText1);
              spriteB02.removeChild(SunFireText2);
              spriteB10.removeChild(SunFireText3);
              spriteB11.removeChild(SunFireText4);
              spriteB12.removeChild(SunFireText5);
              spriteB20.removeChild(SunFireText6);
              spriteB21.removeChild(SunFireText7);
              spriteB22.removeChild(SunFireText8);
              spriteB30.removeChild(SunFireText9);
              spriteB31.removeChild(SunFireText10);
              spriteB32.removeChild(SunFireText11);
              spriteB40.removeChild(SunFireText12);
              spriteB41.removeChild(SunFireText13);       
              spriteB42.removeChild(SunFireText14);            
              }
          }
        } catch (e) {
          console.log(e);
        }
      }




        //add fire icon animation on reels
        function addfireicon(container, text, fireContainer, index,bool) {

          // container.removeChild();
          let fire = new PIXI.Container();
          fire.label = "fire";

          if(text == '' && bool){
                text = new BitmapText(
                  " ",
                  new TextStyle({
                    fontSize: window.innerWidth > 480 ? 50 : 60,
                    fill: "#fff",
                    fontFamily: "sans-serif",
                    align: "center",
                    fontWeight: "bolder",
                    stroke: { color: "#fff", lineWidth: 4 },
                  })
                );
              }
   
        
  
          switch (index) {
            case 0:
                setSpriteProperties(fire,container,spriteA00, spriteB00, 0);
                break;
            case 1:
                setSpriteProperties(fire,container,spriteA01, spriteB01, 1);
                break;
            case 2:
                setSpriteProperties(fire,container,spriteA02, spriteB02, 2);
                break;
            case 3:
                setSpriteProperties(fire,container,spriteA10, spriteB10, 3);
                break;
            case 4:
                setSpriteProperties(fire,container,spriteA11, spriteB11, 4);
                break;
            case 5:
                setSpriteProperties(fire,container,spriteA12, spriteB12, 5);
                break;
            case 6:
                setSpriteProperties(fire,container,spriteA20, spriteB20, 6);
                break;
            case 7:
                setSpriteProperties(fire,container,spriteA21, spriteB21, 7);
                break;
            case 8:
                setSpriteProperties(fire,container,spriteA22, spriteB22, 8);
                break;
            case 9:
                setSpriteProperties(fire,container,spriteA30, spriteB30, 9);
                break;
            case 10:
                setSpriteProperties(fire,container,spriteA31, spriteB31, 10);
                break;
            case 11:
                setSpriteProperties(fire,container,spriteA32, spriteB32, 11);
                break;
            case 12:
                setSpriteProperties(fire,container,spriteA40, spriteB40, 12);
                break;
            case 13:
                setSpriteProperties(fire,container,spriteA41, spriteB41, 13);
                break;
            case 14:
                setSpriteProperties(fire,container,spriteA42, spriteB42, 14);
                break;
        }
  
          container.addChild(fire);
          container.addChild(text);
        }
  
        function setSpriteProperties(fire,container,spriteA, spriteB, posIndex) {
          // let fireSize=[248,248]
          let fireSize=[260,270]
          spriteA.x = 120;
          spriteA.y = 105;
          spriteA.anchor.set(0.5);
          spriteA.animationSpeed = 0.5;
          spriteA.loop = false;
          spriteA.play();
          // spriteA.width = container.texture.orig.width + 10;
          // spriteA.height =container.texture.orig.height + 30; 
          spriteA.width = fireSize[0]
          spriteA.height = fireSize[1]
  
  
          spriteB.x = 3;
          spriteB.y = -3;
          spriteB.anchor.set(0.5);
          spriteB.animationSpeed = 1.5;
          // spriteB.width = (spriteA.width - 40) * 2;
          // spriteB.height = spriteA.height * 1.5   
          spriteB.width = spriteA.width * 1.5;
            spriteB.height = spriteA.height * 1.5;
  
          spriteB.play();
          spriteA.addChild(spriteB);
          fire.addChild(spriteA);
        }
  

      function spinReelAnimation() {
        if(spaceFilledWtihFire < 6) return;
        setTimeout(() => {
          
          let ttArr = [];
          const sunFireData = [];

          if (sunFireDataObj?.prizeAmount?.length >= 0) {
            for (let i = 0; i < sunFireDataObj?.prizeAmount?.length; i++) {
              sunFireData.push({
                prizeAmount: sunFireDataObj?.prizeAmount[i],
                prizeType: sunFireDataObj?.prizeType[i],
                reelIndex: sunFireDataObj?.reelIndex[i],
                slotIndex: sunFireDataObj?.slotIndex[i],
              });
            }
          }

          let index = 0;
          reels.forEach((element) => {
            element.container.children.forEach((el) => {
              index = index + 1;
              if (el.texture.name == "TT" && (index != 1 || index != 6 || index != 11 || index != 16 || index != 21
                || index != 5 || index != 10 || index != 15 || index != 20 || index != 25
              )) {
                el.visible = true;
                showstickyAnimation(index, sunFireData);
              }
            });
          });
        }, 1800);
      }


      function showstickyAnimation(value, sunFireData) {
        switch (value) {
          case 2:
            if (
              reels[0].container.children[1].visible === true &&
              reels[0].container.children[1].texture.name === "TT"
            ) {
              // spriteA00.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 3.5) + 1
              //     : Y1percent * 38;
              // spriteA00.x =
              //   window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 11;
              spriteAOverlay00.y =
                window.innerWidth > 480
                  ? app.screen.height / 3.5
                  : Y1percent * 38.5;
              spriteAOverlay00.x =
                window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 11;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 0 && item.slotIndex === 0
              );
              prizeData?.prizeAmount > 0
                ? animateSpin(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[0]
                      : linkPrizeAmount[0],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[0]
                      : ""
                  );
            }
            break;
          case 3:
            if (
              reels[0].container.children[2].visible === true &&
              reels[0].container.children[2].texture.name === "TT"
            ) {
              // spriteA01.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 2.05)+5
              //     : Y1percent * 48;
              // spriteA01.x =
              //   window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 11;
              spriteAOverlay01.y =
                window.innerWidth > 480
                  ? app.screen.height / 2.05
                  : Y1percent * 48.5;
              spriteAOverlay01.x =
                window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 11;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 0 && item.slotIndex === 1
              );
              prizeData?.prizeAmount > 0
                ? animateSpin1(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin1(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[1]
                      : linkPrizeAmount[1],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[1]
                      : ""
                  );
            }
            break;
          case 4:
            if (
              reels[0].container.children[3].visible === true &&
              reels[0].container.children[3].texture.name === "TT"
            ) {
              // spriteA02.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 1.435)+5
              //     : Y1percent * 58;
              // spriteA02.x =
              //   window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 11;
              spriteAOverlay02.x =
                window.innerWidth > 480 ? app.screen.width / 4 : X1percent * 11;
              spriteAOverlay02.y =
                window.innerWidth > 480
                  ? app.screen.height / 1.435
                  : Y1percent * 58.5;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 0 && item.slotIndex === 2
              );
              prizeData?.prizeAmount > 0
                ? animateSpin2(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin2(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[2]
                      : linkPrizeAmount[2],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[2]
                      : ""
                  );
            }
            break;
          case 7:
            if (
              reels[1].container.children[1].visible === true &&
              reels[1].container.children[1].texture.name === "TT"
            ) {
              // spriteA10.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 3.5) + 1
              //     : Y1percent * 38;
              // spriteA10.x =
              //   window.innerWidth > 480
              //     ? app.screen.width / 2.67
              //     : X1percent * 30;
              spriteAOverlay10.y =
                window.innerWidth > 480
                  ? app.screen.height / 3.5
                  : Y1percent * 38.5;
              spriteAOverlay10.x =
                window.innerWidth > 480
                  ? app.screen.width / 2.67
                  : X1percent * 30;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 1 && item.slotIndex === 0
              );
              prizeData?.prizeAmount > 0
                ? animateSpin3(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin3(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[3]
                      : linkPrizeAmount[3],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[3]
                      : ""
                  );
            }
            break;
          case 8:
            if (
              reels[1].container.children[2].visible === true &&
              reels[1].container.children[2].texture.name === "TT"
            ) {
              // spriteA11.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 2.05)+5
              //     : Y1percent * 48;
              // spriteA11.x =
              //   window.innerWidth > 480
              //     ? app.screen.width / 2.67
              //     : X1percent * 30;
              spriteAOverlay11.y =
                window.innerWidth > 480
                  ? app.screen.height / 2.05
                  : Y1percent * 48.5;
              spriteAOverlay11.x =
                window.innerWidth > 480
                  ? app.screen.width / 2.67
                  : X1percent * 30;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 1 && item.slotIndex === 1
              );
              prizeData?.prizeAmount > 0
                ? animateSpin4(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin4(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[4]
                      : linkPrizeAmount[4],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[4]
                      : ""
                  );
            }
            break;
          case 9:
            if (
              reels[1].container.children[3].visible === true &&
              reels[1].container.children[3].texture.name === "TT"
            ) {
              // spriteA12.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 1.435)+4
              //     : Y1percent * 58;
              // spriteA12.x =
              //   window.innerWidth > 480
              //     ? (app.screen.width / 2.67) - 3
              //     : X1percent * 30;
              spriteAOverlay12.y =
                window.innerWidth > 480
                  ? app.screen.height / 1.435
                  : Y1percent * 58.5;
              spriteAOverlay12.x =
                window.innerWidth > 480
                  ? app.screen.width / 2.67
                  : X1percent * 30;

              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 1 && item.slotIndex === 2
              );
              prizeData?.prizeAmount > 0
                ? animateSpin5(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin5(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[5]
                      : linkPrizeAmount[5],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[5]
                      : ""
                  );
            }
            break;
          case 12:
            if (
              reels[2].container.children[1].visible === true &&
              reels[2].container.children[1].texture.name === "TT"
            ) {
              // spriteA20.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 3.5) + 1
              //     : Y1percent * 38;
              // spriteA20.x =
              //   window.innerWidth > 480 ? (app.screen.width / 2) - 7 : X1percent * 50;
              spriteAOverlay20.y =
                window.innerWidth > 480
                  ? app.screen.height / 3.5
                  : Y1percent * 38.5;
              spriteAOverlay20.x =
                window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;

              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 2 && item.slotIndex === 0
              );
              prizeData?.prizeAmount > 0
                ? animateSpin6(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin6(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[6]
                      : linkPrizeAmount[6],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[6]
                      : ""
                  );
            }
            break;
          case 13:
            if (
              reels[2].container.children[2].visible === true &&
              reels[2].container.children[2].texture.name === "TT"
            ) {
              // spriteA21.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 2.05)+5
              //     : Y1percent * 48;
              // spriteA21.x =
              //   window.innerWidth > 480 ? (app.screen.width / 2) - 7 : X1percent * 50;
              spriteAOverlay21.y =
                window.innerWidth > 480
                  ? app.screen.height / 2.05
                  : Y1percent * 48.5;
              spriteAOverlay21.x =
                window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 2 && item.slotIndex === 1
              );
            
              prizeData?.prizeAmount > 0
                ? animateSpin7(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin7(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[7]
                      : linkPrizeAmount[7],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[7]
                      : ""
                  );
            }
            break;
          case 14:
            if (
              reels[2].container.children[3].visible === true &&
              reels[2].container.children[3].texture.name === "TT"
            ) {
              // spriteA22.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 1.435)+7
              //     : Y1percent * 58;
              // spriteA22.x =
              //   window.innerWidth > 480 ? (app.screen.width / 2) - 7 : X1percent * 50;
              spriteAOverlay22.y =
                window.innerWidth > 480
                  ? app.screen.height / 1.435
                  : Y1percent * 58.5;
              spriteAOverlay22.x =
                window.innerWidth > 480 ? app.screen.width / 2 : X1percent * 50;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 2 && item.slotIndex === 2
              );
              prizeData?.prizeAmount > 0
                ? animateSpin8(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin8(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[8]
                      : linkPrizeAmount[8],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[8]
                      : ""
                  );
            }
            break;
          case 17:
            if (
              reels[3].container.children[1].visible === true &&
              reels[3].container.children[1].texture.name === "TT"
            ) {
              // spriteA30.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 3.5) + 1
              //     : Y1percent * 38;
              // spriteA30.x =
              //   window.innerWidth > 480
              //     ? (app.screen.width / 1.6) - 7
              //     : X1percent * 70;
             spriteAOverlay30.y =
                window.innerWidth > 480
                  ? app.screen.height / 3.5
                  : Y1percent * 38.5;
              spriteAOverlay30.x =
                window.innerWidth > 480
                  ? app.screen.width / 1.6
                  : X1percent * 70;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 3 && item.slotIndex === 0
              );
              prizeData?.prizeAmount > 0
                ? animateSpin9(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin9(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[9]
                      : linkPrizeAmount[9],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[9]
                      : ""
                  );
            }
            break;
          case 18:
            if (
              reels[3].container.children[2].visible === true &&
              reels[3].container.children[2].texture.name === "TT"
            ) {
              // spriteA31.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 2.05)+5
              //     : Y1percent * 48;
              // spriteA31.x =
              //   window.innerWidth > 480
              //     ? (app.screen.width / 1.6) - 7
              //     : X1percent * 70;
              spriteAOverlay31.y =
                window.innerWidth > 480
                  ? app.screen.height / 2.05
                  : Y1percent * 48.5;
              spriteAOverlay31.x =
                window.innerWidth > 480
                  ? app.screen.width / 1.6
                  : X1percent * 70;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 3 && item.slotIndex === 1
              );
              prizeData?.prizeAmount > 0
                ? animateSpin10(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin10(
                   sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[10]
                      : linkPrizeAmount[10],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[10]
                      : ""
                  );
            }
            break;
          case 19:
            if (
              reels[3].container.children[3].visible === true &&
              reels[3].container.children[3].texture.name === "TT"
            ) {
              // spriteA32.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 1.435) + 7
              //     : Y1percent * 58;
              // spriteA32.x =
              //   window.innerWidth > 480
              //     ? (app.screen.width / 1.6) - 8
              //     : X1percent * 70;
              spriteAOverlay32.y =
                window.innerWidth > 480
                  ? app.screen.height / 1.435
                  : Y1percent * 58.5;
              spriteAOverlay32.x =
                window.innerWidth > 480
                  ? app.screen.width / 1.6
                  : X1percent * 70;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 3 && item.slotIndex === 2
              );
              prizeData?.prizeAmount > 0
                ? animateSpin11(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin11(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[11]
                      : linkPrizeAmount[11],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[11]
                      : ""
                  );
            }
            break;
          case 22:
            if (
              reels[4].container.children[1].visible === true &&
              reels[4].container.children[1].texture.name === "TT"
            ) {
              // spriteA40.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 3.5) + 1
              //     : Y1percent * 38;
              // spriteA40.x =
              //   window.innerWidth > 480
              //     ? app.screen.width / 1.335
              //     : X1percent * 89;
              spriteAOverlay40.y =
                window.innerWidth > 480
                  ? app.screen.height / 3.5
                  : Y1percent * 38.5;
              spriteAOverlay40.x =
                window.innerWidth > 480
                  ? app.screen.width / 1.335
                  : X1percent * 89;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 4 && item.slotIndex === 0
              );
              prizeData?.prizeAmount > 0
                ? animateSpin12(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin12(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[12]
                      : linkPrizeAmount[12],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[12]
                      : ""
                  );
            }
            break;
          case 23:
            if (
              reels[4].container.children[2].visible === true &&
              reels[4].container.children[2].texture.name === "TT"
            ) {
              // spriteA41.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 2.05)+5
              //     : Y1percent * 48;
              // spriteA41.x =
              //   window.innerWidth > 480
              //     ? app.screen.width / 1.335
              //     : X1percent * 89;
              spriteAOverlay41.y =
                window.innerWidth > 480
                  ? app.screen.height / 2.05
                  : Y1percent * 48.5;
              spriteAOverlay41.x =
                window.innerWidth > 480
                  ? app.screen.width / 1.335
                  : X1percent * 89;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 4 && item.slotIndex === 1
              );
              prizeData?.prizeAmount > 0
                ? animateSpin13(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin13(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[13]
                      : linkPrizeAmount[13],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[13]
                      : ""
                  );
            }
            break;
          case 24:
            if (
              reels[4].container.children[3].visible === true &&
              reels[4].container.children[3].texture.name === "TT"
            ) {
              // spriteA42.y =
              //   window.innerWidth > 480
              //     ? (app.screen.height / 1.435)+7
              //     : Y1percent * 58;
              // spriteA42.x =
              //   window.innerWidth > 480
              //     ? app.screen.width / 1.335
              //     : X1percent * 89;
              spriteAOverlay42.y =
                window.innerWidth > 480
                  ? app.screen.height / 1.435
                  : Y1percent * 58.5;
              spriteAOverlay42.x =
                window.innerWidth > 480
                  ? app.screen.width / 1.335
                  : X1percent * 89;
              const prizeData = sunFireData.find(
                (item) => item.reelIndex === 4 && item.slotIndex === 2
              );
              prizeData?.prizeAmount > 0
                ? animateSpin14(prizeData?.prizeAmount, prizeData?.prizeType)
                : animateSpin14(
                    sunFireDataObj?.prizeAmount?.length >= 15
                      ? sunFireDataObj?.prizeAmount[14]
                      : linkPrizeAmount[14],
                    prizeTypeForStickeySpin?.length > 0
                      ? prizeTypeForStickeySpin[14]
                      : ""
                  );
              // }
            }
        }
      }


      function showStickyScreen(bool) {
        stickyFlag=true;
        setPrizeinreeladdd();

        winBlackBg.visible = bool;
        stickySpinBg.visible = bool;
        stickyBackgroundImg.visible = bool;

        MinorStickyTextFrame.visible = bool;
        MinorStickyTextFrameBG.visible = bool;
        MinorStickyTextImg.visible = bool;
        // MinorStickyFrameDesign.visible = bool;
        MinorContainerSticky.addChild(MinorStickyTextFrame);
        MinorContainerSticky.addChild(MinorStickyTextFrameBG);
        MinorContainerSticky.addChild(MinorStickyTextImg);
        // MinorContainerSticky.addChild(MinorStickyFrameDesign);
        top.removeChild(stickyMinorText);
        stickyMinorText.visible = bool;
        MinorContainerSticky.addChild(stickyMinorText);
        app.stage.addChild(MinorContainerSticky);

        MajorstickyTextFrame.visible = bool;
        MajorstickyTextFrameBG.visible = bool;
        MajorstickyTextImg.visible = bool;
        MajorStickyFrameDesign.visible = bool;
        MajorContainerSticky.addChild(MajorstickyTextFrame);
        MajorContainerSticky.addChild(MajorstickyTextFrameBG);
        MajorContainerSticky.addChild(MajorstickyTextImg);
        MajorContainerSticky.addChild(MajorStickyFrameDesign);

        top.removeChild(MajorText);
        MajorContainerSticky.addChild(MajorText);
        app.stage.addChild(MajorContainerSticky);

        GrandstickyTextFrame.visible = bool;
        GrandstickyTextFrameBG.visible = bool;
        GrandstickyTextImg.visible = bool;
        GrandStickyFrameDesign.visible = bool;
        GrandContainerSticky.addChild(GrandstickyTextFrame);
        GrandContainerSticky.addChild(GrandstickyTextFrameBG);
        GrandContainerSticky.addChild(GrandstickyTextImg);
        GrandContainerSticky.addChild(GrandStickyFrameDesign);
        top.removeChild(GrandText);
        GrandContainerSticky.addChild(GrandText);
        app.stage.addChild(GrandContainerSticky);
        MinistickyTextFrame.visible = bool;
        MinistickyTextImg.visible = bool;
        MiniContainerSticky.addChild(MinistickyTextFrame);
        MiniContainerSticky.addChild(MinistickyTextImg);
        top.removeChild(stickyMiniText);
        stickyMiniText.visible = bool;
        MiniContainerSticky.addChild(stickyMiniText);
        app.stage.addChild(MiniContainerSticky);
        playLinkHitAnimation();
        linkHitImg.visible = true;
        stickyGameFrame.visible = bool;
        stickySpaceFilledCountContainer.visible = bool;

        if (window.innerWidth < 480) {
          stickySpinBootomTextCount.text.length == 2
            ? (stickySpinBootomTextCount.x = X1percent * 61)
            : (stickySpinBootomTextCount.x = X1percent * 63);
        } else {
          stickySpinBootomTextCount.x =
            stickySpinBootomTextCount.text.length == 2
              ? X1percent * 88.2
              : X1percent * 88.8;
        }

        winnerTextBoxTop.visible = bool;
      }
      function hideStickyScreen(bool) {
        removingfunction();
        removeAllSprites();
        // bottom.eventMode = "none";
        // bottom.cursor = "none";
        InfoImg.eventMode = "none";
        InfoImg.cursor = "none";
        // dollarBtn1.eventMode = "none";
        // dollarBtn1.cursor = "none";

        app.stage.removeChild(removePy);
        MiniText.visible = bool;
        MinorText.visible = bool;
        BackgroundImg.visible = bool;
        GameFrame.visible = bool;
        GameBG.visible = bool;
        MinorTextFrameBG.visible = bool;
        MinorTextFrame.visible = bool;
        MinorTextImg.visible = bool;
        GrandTextFrame.visible = bool;
        GrandTextFrameBG.visible = bool;
        GrandTextImg.visible = bool;
        MajorTextFrame.visible = bool;
        MajorTextFrameBG.visible = bool;
        MajorTextImg.visible = bool;
        MiniTextFrame.visible = bool;
        MiniTextFrameBG.visible = bool;
        MiniTextImg.visible = bool;
        // FoFImg.visible = bool;
        stickyspinTotalwinImg.visible = false;
      }

      
      //trigger sticky spin
      function triggerStikySpin() {
        freeSpinMusic.pause();
        IsstickySpin = true;
        backtofreegameafterSticky = false;
        if (IsstickySpin == true) {
          FoFImg.visible = false;
          freeSpinRemainingText.visible = false;
          freeSpinImg.visible = false;
        }
        IsStickeySpinWon = true;
        showStickyScreen(true);
        hideStickyScreen(false);
        stickyremainingCount.visible = true;
        stickyremainingText.visible = true;
        stickyremainingText.x = X1percent * 41.6;
        remainingStickyCountainer.visible = true;
        stickyspinBGFrame.visible = false;
        GrandContainerSticky.x = 0;
        MinorContainerSticky.x = 0;
        MajorContainerSticky.x = 0;
        MiniContainerSticky.x = 0;
        GrandContainerSticky.y = 0;
        MinorContainerSticky.y = 0;
        MajorContainerSticky.y = 0;
        MiniContainerSticky.y = 0;
        if (window.innerWidth > 480) {
          stickyspinBGFrame.width = X1percent * 30;
          stickyspinBGFrame.height = Y1percent * 8;
          stickyspinBGFrame.x = X1percent * 35;
          stickyspinBGFrame.y = Y1percent * 13;
          stickyspinoverlayFrame.width = X1percent * 31;
          stickyspinoverlayFrame.height = Y1percent * 8;
          stickyspinoverlayFrame.x = X1percent * 35;
          stickyspinoverlayFrame.y = Y1percent * 2;
          MajorstickyTextFrameBG.x = X1percent * 50;
          MajorstickyTextFrameBG.y = Y1percent * 3.5;
          MajorstickyTextFrame.x =
            (app.screen.width - MajorstickyTextFrame.width) / 1.6;
          MajorstickyTextFrame.y = Y1percent * 3.5;
          MajorstickyTextImg.x = X1percent * 55;
          MajorstickyTextImg.y = Y1percent * -1.3;
          MajorStickyFrameDesign.x = X1percent * 50;
          MajorStickyFrameDesign.y = Y1percent * 3.2;
        
          MinistickyTextFrame.x =
            (app.screen.width - MinistickyTextFrame.width) / 1.1675;
          MinistickyTextFrame.y = Y1percent * 3.9;
          MinistickyTextImg.x =
            (app.screen.width - MinistickyTextImg.width) / 1.2;
          MinistickyTextImg.y =
            (app.screen.height - MinistickyTextImg.height) / 40;
          MajorstickyTextFrameBG.x = X1percent * 50;
          MajorstickyTextFrameBG.y = Y1percent * 3.5;
          MajorstickyTextFrame.x =
            (app.screen.width - MajorstickyTextFrame.width) / 1.6;
          MajorstickyTextFrame.y = Y1percent * 3.5;
          MajorstickyTextImg.x = X1percent * 55;
          MajorstickyTextImg.y = Y1percent * -1.3;
          MajorStickyFrameDesign.x = X1percent * 50;
          MajorStickyFrameDesign.y = Y1percent * 3.2;
          GrandstickyTextFrameBG.x = X1percent * 29.5;
          GrandstickyTextFrameBG.y = Y1percent * 2.5;
          GrandstickyTextFrame.x =
            (app.screen.width - GrandstickyTextFrame.width) / 2.7;
          GrandstickyTextFrame.y =
            (app.screen.height - GrandstickyTextFrame.height) / 30;
          GrandstickyTextImg.x = X1percent * 34;
          GrandstickyTextImg.y = Y1percent * -2;
          GrandStickyFrameDesign.x = X1percent * 29.5;
          GrandStickyFrameDesign.y = Y1percent * 2.7;
        } else {
          stickyspinBGFrame.width = X1percent * 82;
          stickyspinBGFrame.height = Y1percent * 8;
          stickyspinBGFrame.x = X1percent * 9;
          stickyspinBGFrame.y = Y1percent * 29;
          stickyspinoverlayFrame.width = X1percent * 82;
          stickyspinoverlayFrame.height = Y1percent * 8;
          stickyspinoverlayFrame.x = X1percent * 10;
          stickyspinoverlayFrame.y = Y1percent * 30;
          MajorstickyTextFrameBG.x = X1percent * 66;
          MajorstickyTextFrameBG.y = Y1percent * 7.5;
          MajorstickyTextFrame.x = X1percent * 66;
          MajorstickyTextFrame.y = Y1percent * 7;
          MajorstickyTextImg.x = X1percent * 70;
          MajorstickyTextImg.y = Y1percent * 5.5;
          MajorStickyFrameDesign.x = X1percent * 66;
          MajorStickyFrameDesign.y = Y1percent * 6.8;
          MiniTextFrameBG.x = X1percent * 2;
          MiniTextFrameBG.y = Y1percent * 22;
          MiniTextFrame.x = X1percent * 1 - 10;
          MiniTextFrame.y = Y1percent * 21.4;
          MiniTextImg.x = X1percent * 5.5;
          MiniTextImg.y = Y1percent * 19.5;
          stickyMiniText.x = X1percent * 6.5;
          stickyMiniText.y = Y1percent * 23.6;
          GrandstickyTextFrame.x = X1percent * 2;
          GrandstickyTextFrame.y = Y1percent * 7;
          GrandstickyTextImg.x = X1percent * 5;
          GrandstickyTextImg.y = Y1percent * 5.5;
          GrandStickyFrameDesign.x = X1percent * 2;
          GrandStickyFrameDesign.y = Y1percent * 6.7;
          GrandstickyTextFrameBG.x = X1percent * 2.2;
          GrandstickyTextFrameBG.y = Y1percent * 7.2;
          MinorStickyTextFrameBG.x = X1percent * 72;
          MinorStickyTextFrameBG.y = Y1percent * 22;
          MinorStickyTextFrame.x = X1percent * 72;
          MinorStickyTextFrame.y = Y1percent * 21;
          MinorStickyTextImg.x = X1percent * 72;
          MinorStickyTextImg.y = Y1percent * 20;
        }

        if (remainStickySpin == true) {
          // stickyremainingCount.text = currentStickySpinGameLoad;
          stickyremainingCount.x = X1percent * 57.6;
        } else {
          // stickyremainingCount.text = remaningStickeySpin;
          
          stickyremainingCount.x = X1percent * 57.6;
          stickyremainingCount.y = Y1percent * 16;


        }
        setTimeout(() => {
          linkHitImg.visible = false;
        }, 2600);

        stickySpininterval = setInterval(async () => {

          if (remaningStickeySpin > 0 && countTTSym < 15) {
            currentStickySpinGameLoad = 0;
            if (countTTSym == 15) {
              clearInterval(stickySpininterval);
              jackpotWinContainer.visible = true;
              jackpotWinContainer.children[1].text =
                grandJackpotAmount.toFixed(3);
              IsStickeySpinWon = false;
              setTimeout(() => {
                jackpotWinContainer.visible = false;
                hidestickyHeader();
              }, 4000);
              return;
            }
            startPlay();
            await gameSpinApi(false);
            // stickyremainingCount.text = remaningStickeySpin;
            stickyremainingCount.x = X1percent * 57.6;
            stickyremainingCount.y = Y1percent * 16;
            
            setTimeout(() => {
              stickySpaceFilledCountContainer.children[2].text =
                spaceFilledWtihFire;
                stickyremainingCount.text = currentStickySpinGameLoad;
            }, 4400);

            setTimeout(() => {
              imgArrSlot?.forEach((element, index) => {
                if (element?.name == "TT") {
                  checkFIreIcon = checkFIreIcon + 1;
                  // stickyremainingCount.text = remaningStickeySpin;
                  setTimeout(() => {
                    stickySpaceFilledCountContainer.children[2].text =
                      spaceFilledWtihFire;
                  }, 3000);

                  if (spaceFilledWtihFire == 15 || remaningStickeySpin == 0) {
                    currentStickySpinGameLoad = 0;
                    remaningStickeySpin = 0;
                  }
                }
              });
            }, 1000);
          } else if (currentStickySpinGameLoad > 0) {
            if (countTTSym == 15) {
              clearInterval(stickySpininterval);
              jackpotWinContainer.visible = true;
              jackpotWinContainer.children[1].text =
                grandJackpotAmount.toFixed(3);
              IsStickeySpinWon = false;
              setTimeout(() => {
                jackpotWinContainer.visible = false;
                hidestickyHeader();
              }, 4000);
              return;
            }
            startPlay();
            await gameSpinApi(false);
            // stickyremainingCount.text = remaningStickeySpin;

            setTimeout(() => {
              stickySpaceFilledCountContainer.children[2].text =
                spaceFilledWtihFire;
            }, 3000);

            setTimeout(() => {
              imgArrSlot?.forEach((element, index) => {
                if (element?.name == "TT") {
                  checkFIreIcon = checkFIreIcon + 1;
                  // stickyremainingCount.text = remaningStickeySpin;

                  setTimeout(() => {
                    stickySpaceFilledCountContainer.children[2].text =
                      spaceFilledWtihFire;
                  }, 3000);
                }
              });
            }, 1000);
          } else {
            if (countTTSym == 15) {
              clearInterval(stickySpininterval);
              jackpotWinContainer.visible = true;
              jackpotWinContainer.children[1].text =
                grandJackpotAmount.toFixed(3);
              IsStickeySpinWon = false;
              setTimeout(() => {
                jackpotWinContainer.visible = false;
                hidestickyHeader();
              }, 4000);
              return;
            }
            clearInterval(stickySpininterval);
            hidestickyHeader();
            if (remainStickySpin == true) {
              // stickyremainingCount.text = currentStickySpinGameLoad;
            } else {
              // stickyremainingCount.text = remaningStickeySpin;
            }

            stickyMinorText.visible = false;
            stickyMiniText.visible = false;
            IsStickeySpinWon = false;
            checkFIreIcon = 0;
            let stickyspinTotalAmount = [];
            if (spriteB00.children.length > 0)
              stickyspinTotalAmount.push(spriteB00.children[0]);
            if (spriteB01.children.length > 0)
              stickyspinTotalAmount.push(spriteB01.children[0]);
            if (spriteB02.children.length > 0)
              stickyspinTotalAmount.push(spriteB02.children[0]);
            if (spriteB10.children.length > 0)
              stickyspinTotalAmount.push(spriteB10.children[0]);
            if (spriteB11.children.length > 0)
              stickyspinTotalAmount.push(spriteB11.children[0]);
            if (spriteB12.children.length > 0)
              stickyspinTotalAmount.push(spriteB12.children[0]);
            if (spriteB20.children.length > 0)
              stickyspinTotalAmount.push(spriteB20.children[0]);
            if (spriteB21.children.length > 0)
              stickyspinTotalAmount.push(spriteB21.children[0]);
            if (spriteB22.children.length > 0)
              stickyspinTotalAmount.push(spriteB22.children[0]);
            if (spriteB30.children.length > 0)
              stickyspinTotalAmount.push(spriteB30.children[0]);
            if (spriteB31.children.length > 0)
              stickyspinTotalAmount.push(spriteB31.children[0]);
            if (spriteB32.children.length > 0)
              stickyspinTotalAmount.push(spriteB32.children[0]);
            if (spriteB40.children.length > 0)
              stickyspinTotalAmount.push(spriteB40.children[0]);
            if (spriteB41.children.length > 0)
              stickyspinTotalAmount.push(spriteB41.children[0]);
            if (spriteB42.children.length > 0)
              stickyspinTotalAmount.push(spriteB42.children[0]);
          }
        }, 6500);
      }

      function hidestickyHeader() {
        gsap.to(MiniContainerSticky, { x: app.stage.width + 100, duration: 3 });
        gsap.to(MajorContainerSticky, {
          x: -app.stage.width,
          y: 100,
          duration: 3,
        });
        gsap.to(GrandContainerSticky, {
          x: app.stage.width + 100,
          y: 100,
          duration: 3,
        });
        gsap.to(MinorContainerSticky, {
          x: -app.stage.width,
          duration: 3.5,
          onComplete: () => {
            showstickyWin();
          },
        });
      }

      function showstickyWin() {
        winnerTextBoxTop.y = -100;
        winnerTextBoxTop.visible = true;
        stickyspinTotalwinImg.visible = true;
        stickyspinBGFrame.visible = true;
        winnerTextBoxTop.children[0].height = Y1percent * 15;
        winnerTextBoxTop.children[0].y = Y1percent * 2;
        winnerTextBoxTop.children[1].y = Y1percent * 2;
        winnerTextBoxTop.children[1].height = Y1percent * 15;
        winnerTextBoxTop.children[3].visible = true;
        stickyremainingText.visible = false;
        remainingStickyCountainer.visible = false;
        stickyremainingCount.visible = false;
        gsap.to(winnerTextBoxTop, {
          y: Y1percent,
          x: X1percent,
          onStart() {
            Stickywin.visible = true;
          },
          duration: 3.5,
          onComplete: () => {
            stickyfun();
          },
        });
      }

      // }
      function stickyfun() {
        var SunArray = [];
        var squares = new Container();
        for (let i = 0; i < 10; i++) {
          const square = new PIXI.Graphics();
          square.beginFill(0xff0000);
          square.drawRect(0, 0, 10, 10);
          square.endFill();
          square.x = Math.random() * 50;
          square.y = Math.random() * 50;
          squares.addChild(square);
        }

        app.stage.addChild(squares);
        for (var x = 0; x < imgArrSlot.length; x++) {
          if (imgArrSlot[x].name == "TT") {
            switch (x) {
              case 0:
                SunArray.push({ img: spriteAOverlay00, index: x });
                break;
              case 1:
                SunArray.push({ img: spriteAOverlay01, index: x });
                break;
              case 2:
                SunArray.push({ img: spriteAOverlay02, index: x });
                break;
              case 3:
                SunArray.push({ img: spriteAOverlay10, index: x });
                break;
              case 4:
                SunArray.push({ img: spriteAOverlay11, index: x });
                break;
              case 5:
                SunArray.push({ img: spriteAOverlay12, index: x });
                break;
              case 6:
                SunArray.push({ img: spriteAOverlay20, index: x });
                break;
              case 7:
                SunArray.push({ img: spriteAOverlay21, index: x });
                break;
              case 8:
                SunArray.push({ img: spriteAOverlay22, index: x });
                break;
              case 9:
                SunArray.push({ img: spriteAOverlay30, index: x });
                break;
              case 10:
                SunArray.push({ img: spriteAOverlay31, index: x });
                break;
              case 11:
                SunArray.push({ img: spriteAOverlay32, index: x });
                break;
              case 12:
                SunArray.push({ img: spriteAOverlay40, index: x });
                break;
              case 13:
                SunArray.push({ img: spriteAOverlay41, index: x });
                break;
              case 14:
                SunArray.push({ img: spriteAOverlay42, index: x });
                break;
            }
          }
        }
        runflyAnimation(SunArray, SunArray[0]?.index, 0, squares);
        playLinkHitRing = setInterval(() => {
          LinkHitRing.play();
        }, 40);
      }

      let playLinkHitRing;
      function runflyAnimation(SunArray, index, val, squares) {
        squares.x = SunArray[val].img.x;
        squares.y = SunArray[val].img.y;
        gsap.to(squares, {
          x:
            window.innerWidth > 480
              ? stickyspinoverlayFrame.x + stickyspinoverlayFrame.width / 2
              : stickyspinoverlayFrame.x + stickyspinoverlayFrame.width / 2,
          y: stickyspinoverlayFrame.y + stickyspinoverlayFrame.height / 2,
          onStart() {},
          duration: 1.9,
          onComplete: async () => {
            if (val !== SunArray.length - 1) {
              stickyWinAmount =
                stickyWinAmount +
                (Number(linkPrizeAmount[SunArray[val].index]) / 100) * coinDenonimation;
              runflyAnimation(SunArray, SunArray[val].index, val + 1, squares);
              
              showStickywinAmount();
            } else {
              stickyWinAmount =
                stickyWinAmount +
                (Number(linkPrizeAmount[SunArray[val].index]) / 100) * coinDenonimation;
              showStickywinAmount();
              app.stage.removeChild(squares);
              skipButton.visible = true;

              // UpdateWinValue(Number(totalStickySpinAmountInCredits));
              // (totalStickySpinAmountInCredits > 0) ? UpdateWinValue(Number(totalStickySpinAmountInCredits)) : UpdateWinValue(totalFireAmount,Number(totalFireAmountInDollar.toFixed(2))); 
              (totalFreeStickyAmountInCredits > 0) ? UpdateWinValue(totalFreeStickyAmountInCredits,totalFreeStickyAmountInCreditsInDollar) : UpdateWinValue(totalFireAmount,totalFireAmountInDollar);
              clearInterval(playLinkHitRing);
            }
          },
        });
      }

      async function backToPreviousLayout() {
        creditsBtnHandle(Math.round(creditsVal / coinDenonimation),creditsVal);
        // updateCreditsSubVal(creditsVal);
        clearInterval(stickySpininterval);
        skipButton.visible = false;
        checkFIreIcon = 0;
        reels.forEach((el) => {
          el.container.children.forEach((el) => {
            el.removeChildren();
          });
        });

        stickyContainerindex = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

        IsstickySpin = false;

        setTimeout(() => {
          if (IsstickySpin == true) {
            FoFImg.visible = false;
          } else {
            FoFImg.visible = true;
          }
          switchNormalSpin();
          SpinBtn.visible = true;
          SpinBtn2.visible = true;
          bottom.eventMode = "static";
          bottom.cursor = "pointer";
          IsstickySpin = false;
        }, 5000);
        setTimeout(() => {
          app.stage.removeChild(linkHitImg);
          if (currentfreeSpinGameLoad > 0) {
            noOfFreeSpinWonHandleGameLoad();
            freeSpinType = true;

            if (freeSpinType === true) {
              setTimeout(() => {
                FoFImg.visible = false;
              }, 0);
            }

            currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin;
            freeSpinText.text = `${currentfreeSpinGameLoad}`;
            console.log(freeSpinText.text,'inside this --- 4')

            freeSpinMesg.text =
              defaultLang == "es"
                ? `Te quedan ${currentfreeSpinGameLoad} giros gratis. 
        Haz clic en jugar para consumir el giro gratis restante.`
                : `You have ${currentfreeSpinGameLoad} free Spins left. 
        Click on play to consume the remaining free spin.`;
          } else {
            setTimeout(() => {
              if (running === false) {
                bottom.eventMode = "static";
                bottom.cursor = "pointer";
              }
            }, 50);
          }
        }, 7000);

        let backStickylayouticon = await backToPrevLayoutIcon(3);
        backStickylayouticon = backStickylayouticon?.resultData?.winningCombination?.split(",");

        setTimeout(() => {
          jackpotWinContainer.visible = false;
          imgArrSlot = [];
          function getImgWithUrl(name) {
            return slotTextures.filter((el, i) => (el.name === name ? el : ""));
          }
          for (let i = 0; i < backStickylayouticon?.length; i++) {
            imgArrSlot?.push(getImgWithUrl(backStickylayouticon[i])[0]);
          }
          top.addChild(MajorText);
          top.addChild(GrandText);
          let index = 0;
          stickyFlag=false;
        
          setPrizeinreeladdd();

         setTimeout(() => {
          reels[0].container.children[1].texture = imgArrSlot[0];
          reels[0].container.children[2].texture = imgArrSlot[1];
          reels[0].container.children[3].texture = imgArrSlot[2]; 
          reels[1].container.children[1].texture = imgArrSlot[3];
          reels[1].container.children[2].texture = imgArrSlot[4];
          reels[1].container.children[3].texture = imgArrSlot[5];
          reels[2].container.children[1].texture = imgArrSlot[6];
          reels[2].container.children[2].texture = imgArrSlot[7];
          reels[2].container.children[3].texture = imgArrSlot[8];
          reels[3].container.children[1].texture = imgArrSlot[9];
          reels[3].container.children[2].texture = imgArrSlot[10];
          reels[3].container.children[3].texture = imgArrSlot[11];
          reels[4].container.children[1].texture = imgArrSlot[12];
          reels[4].container.children[2].texture = imgArrSlot[13];
          reels[4].container.children[3].texture = imgArrSlot[14];
          for (var i = 0; i < reels.length; i++) {
            for (let j = 1; j < 4; j++) {
                  reels[i].container.children[j].removeChildren();
                  if(reels[i].container.children[j].texture.name == "TT"){
                    // let fireIconwithoutBorder = Texture.from("/assets/Slots/Slot_Sprite/SunFire_01.png");
                    // fireIconwithoutBorder.name ='TT'
                    // reels[i].container.children[j].texture = fireIconwithoutBorder
                    addfireicon(reels[i].container.children[j],"",reels[i].container,index,true)
                  }
                  index = index + 1;
            }
          }
          //addStickyNumber([],[],[],[])
         }, 100);

          spaceFilledWtihFire = 0;
          // sunFireDataObj = {
          //   prizeAmount: [
          //     50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
          //     50, 50, 50, 50,
          //   ],
          //   prizeType: [
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //     "RegularPrize",
          //   ],
          //   reelIndex: [0, 1, 2, 3, 4, 0, 1, 2, 3, 4],
          //   slotIndex: [1, 1, 1, 1, 1, 2, 2, 2, 2, 2],
          // };

        //   dollarBtn1.eventMode = "static";
        //   dollarBtn1.cursor = "pointer";
          InfoImg.eventMode = "static";
          InfoImg.cursor = "pointer";
          bottom.eventMode = "static";
          bottom.cursor = "pointer";

          MiniText.visible = true;
          MinorText.visible = true;
          BackgroundImg.visible = true;
          GameFrame.visible = true;
          GameBG.visible = true;
          MinorTextFrameBG.visible = true;
          MinorTextFrame.visible = true;
          MinorTextImg.visible = true;
          GrandTextFrame.visible = true;
          GrandTextFrameBG.visible = true;
          GrandTextImg.visible = true;
          GrandText.visible = true;
          MajorTextFrame.visible = true;
          MajorTextFrameBG.visible = true;
          MajorTextImg.visible = true;
          MajorText.visible = true;
          MiniTextFrame.visible = true;
          MiniTextFrameBG.visible = true;
          MiniTextImg.visible = true;
          // FoFImg.visible = true;

          stickySpinBg.visible = false;
          stickyBackgroundImg.visible = false;

          MinorStickyTextFrame.visible = false;
          MinorStickyTextFrameBG.visible = false;
          MinorStickyTextImg.visible = false;
          // MinorStickyFrameDesign.visible = false;
          stickyMinorText.visible = false;
          MajorstickyTextFrame.visible = false;
          MajorstickyTextFrameBG.visible = false;
          MajorstickyTextImg.visible = false;
          MajorStickyFrameDesign.visible = false;
          GrandstickyTextFrame.visible = false;
          GrandstickyTextFrameBG.visible = false;
          GrandstickyTextImg.visible = false;
          GrandStickyFrameDesign.visible = false;
          MinistickyTextFrame.visible = false;
          // MinistickyTextFrameBG.visible = false;
          MinistickyTextImg.visible = false;
          // MiniStickyFrameDesign.visible = false;
          stickyMiniText.visible = false;
          stickyGameFrame.visible = false;
          stickySpaceFilledCountContainer.visible = false;
          winnerTextBoxTop.visible = false;
          Stickywin.text = 0;
          stickyWinAmount = 0;
          Stickywin.visible = false;
          jackpotWinContainer.visible = false;
        }, 5000);

        creditsBtnHandle(creditsVal,creditsVal);
        // updateCreditsSubVal();
      }

      const tweening = [];
      function tweenTo(
        object,
        property,
        target,
        time,
        easing,
        onchange,
        oncomplete
      ) {
        const tween = {
          object,
          property,
          propertyBeginValue: object[property],
          target,
          easing,
          time,
          change: onchange,
          complete: oncomplete,
          start: Date.now(),
        };

        tweening.push(tween);
        return tween;
      }
      // Listen for animate update.
      app.ticker.add(() => {
        const now = Date.now();
        const remove = [];

        for (let i = 0; i < tweening.length; i++) {
          const t = tweening[i];
          const phase = Math.min(1, (now - t.start) / t.time);
          t.object[t.property] = lerp(
            t.propertyBeginValue,
            t.target,
            t.easing(phase)
          );
          if (t.change) t.change(t);
          if (phase === 1) {
            t.object[t.property] = t.target;
            if (t.complete) t.complete(t);
            remove.push(t);
          }
        }
        for (let i = 0; i < remove.length; i++) {
          tweening.splice(tweening.indexOf(remove[i]), 1);
        }
      });

      // Basic lerp funtion.
      function lerp(a1, a2, t) {
        return a1 * (1 - t) + a2 * t;
      }

      // Backout function from tweenjs.
      function backout(amount) {
        return (t) => +t * ((amount + 1) * t + amount) + 1;
      }

      const lightImgBG = new Sprite(
        Texture.from("/assets/Slots/Slot_Sprite/Calavera.png")
      );

      const paylineBG = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );
      paylineBG.height = app.screen.height / 1.3 - 100;
      paylineBG.width = app.screen.width / 1.5 - 50;
      paylineBG.x = (app.screen.width - paylineBG.width) / 2;
      paylineBG.y = (app.screen.height - paylineBG.height) / 2;
      app.stage.addChild(paylineBG);

      const imagesPerRow = 10; // Number of images per row
      const imgSize = 50; // Size of each image
      const startX = paylineBG.x - 100; // Starting X position
      const rowY = paylineBG.y + 280; // Starting Y position for the first row

      const textOptions = {
        fontFamily: "fantasy",
        fontSize: 80,
        fontWeight: "300",
        fill: 0xdddddd, // White color
        align: "center",
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
      };

      const gameRulesBG = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );

      const gameRules = [
        "Select credits per line to start betting.",
        "${} Substitute for all symbols except scatter symbols ${} & Symbols.",
        "A player can't get ${} on the first reel on base game.",
        "Can only appear on the second, third, fourth and fifth reel.",
      ];

      gameRulesBG.height = app.screen.height / 1.3 - 100;
      gameRulesBG.width = app.screen.width / 1.5 - 50;
      gameRulesBG.x = (app.screen.width - gameRulesBG.width) / 2;
      gameRulesBG.y = (app.screen.height - gameRulesBG.height) / 2;
      app.stage.addChild(gameRulesBG);

      const gameRuleStyle = {
        fontFamily: "fantasy",
        fontSize: 60,
        fontWeight: "100",
        fill: 0xdddddd, // White color
        align: "center",
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
      };

      const gameRulesText = new Text("Rules", gameRuleStyle);
      gameRulesText.x = (gameRulesBG.width - gameRulesText.width) / 1.15; // X position
      gameRulesText.y = gameRulesBG.height / 2.5; // Y position
      gameRulesText.anchor.set(0.5); // Center the text
      gameRulesBG.addChild(gameRulesText);

      const gameRuleContainer = new Container();
      gameRuleContainer.x = app.screen.height / 8;
      gameRuleContainer.y = app.screen.height / 3;
      gameRulesBG.addChild(gameRuleContainer);

      gameRules.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: "fantasy",
          fontSize: 24,
          fontWeight: "100",
          fill: 0xdddddd, // White color
          align: "center",
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2,
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        text.y = app.screen.width / 1;
        // Position the text object
        text.x = (gameRulesBG.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        gameRuleContainer.addChild(text);
      });

      const freeGameBouns = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );

      const rules1 = [
        "5 Free Games are awareded by 3 or more scatter symbols.",
        `5 ${lightImgBG} Pays bet x 100.`,
        `4 ${lightImgBG} Pays bet x 10.`,
        `3 ${lightImgBG} Pays bet x 2.`,
        "During the free games bonus, all of the symbols in the first & last reels will become wilds.",
        "During the free games bonus, 3 scatter symbols ${} will award 5 additional free games.",
      ];

      freeGameBouns.height = app.screen.height / 1.3 - 100;
      freeGameBouns.width = app.screen.width / 1.5 - 50;
      freeGameBouns.x = (app.screen.width - freeGameBouns.width) / 2;
      freeGameBouns.y = (app.screen.height - freeGameBouns.height) / 2;
      app.stage.addChild(freeGameBouns);

      const freeGameBounsText = {
        fontFamily: "fantasy",
        fontSize: 40,
        fontWeight: "100",
        fill: "#dda40e", // White color
        align: "center",
        stroke: { color: "#622e0c", width: 5 },
        dropShadow: {
          color: "#71420a",
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
      };

      const gameBounsText = new Text(
        "Free games bonus".toUpperCase(),
        freeGameBounsText
      );
      gameBounsText.x = (freeGameBouns.width - gameBounsText.width) / 0.95; // X position
      gameBounsText.y = freeGameBouns.height / 2.5; // Y position
      gameBounsText.anchor.set(0.5); // Center the text
      freeGameBouns.addChild(gameBounsText);

      const firstContainer = new Container();
      firstContainer.y = app.screen.height / 3.3;
      firstContainer.x = app.screen.height / 8;
      freeGameBouns.addChild(firstContainer);

      rules1.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: "fantasy",
          fontSize: 24,
          fontWeight: "100",
          fill: 0xdddddd, // White color
          align: "center",
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2,
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        // Position the text object
        text.x = (freeGameBouns.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        firstContainer.addChild(text);
      });

      freeGameBouns.visible = false;
      paylineBG.visible = false;
      // payOutBG.visible = false
      gameRulesBG.visible = false;

      InfoImg.eventMode = "static";
      InfoImg.cursor = "pointer";
      InfoImg.addListener("pointerdown", () => {
        setPayoutTable(!isPayoutTable);
        // let defaultLang='es'
        localStorage.setItem(defaultLang, "language");
      });

      //doller btn container
      // Add play text
      const style = new TextStyle({
        fontFamily: "Arial",
        fontSize: 56,
        fontStyle: "italic",
        fontWeight: "bold",
        fill: { fill },
        stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
     });

      const style1 = new TextStyle({
        fontFamily: "Arial",
        fontSize: 250,
        fontStyle: "italic",
        fontWeight: "bold",
        fill: 0xffffff,
        stroke: { color: "#008000", width: 5 },
        dropShadow: {
          color: "#008000",
          angle: Math.PI / 6,
          blur: 14,
          distance: 6,
        },
      });
    //   const dollarBg = new Sprite(
    //     Texture.from(
    //       window.innerWidth > 480
    //         ? `/assets/Denominations/Denom-Slide.png`
    //         : `/assets/Denominations/Denom-Slide_mobile.png`
    //     )
    //   );

    //   const dollarbGText = new Text(
    //     getMessage(defaultLang, "denominationText").toUpperCase(),
    //     style1
    //   );

    //   dollarbGText.x = window.screen.width / 2;
    //   dollarbGText.y = window.screen.height / 3;

    //   const dollarText1 = new Text(`${1}\u20B5`, style);
    //   const dollarText2 = new Text(`${2}\u20B5`, style);
    //   const dollarText3 = new Text(`${5}\u20B5`, style);
    //   const dollarText4 = new Text(`${10}\u20B5`, style);
    //   const dollarText5 = new Text(`${25}\u20B5`, style);

    //   const dollarBtn1 = new Sprite(
    //     Texture.from("/assets/Denominations/Denom-Button-1.png")
    //   );
    //   dollarBtn1.addChild(dollarText1);
    //   dollarText1.width = 400;
    //   dollarText1.height = 300;
    //   dollarText1.x = 240;
    //   dollarText1.y = 155;

    //   const dollarBtn2 = new Sprite(
    //     Texture.from("/assets/Denominations/Denom-Button-2.png")
    //   );
    //   dollarBtn2.addChild(dollarText2);
    //   dollarText2.width = 400;
    //   dollarText2.height = 300;
    //   dollarText2.x = 240;
    //   dollarText2.y = 155;

    //   const dollarBtn3 = new Sprite(
    //     Texture.from("/assets/Denominations/Denom-Button-3.png")
    //   );
    //   dollarBtn3.addChild(dollarText3);
    //   dollarText3.width = 400;
    //   dollarText3.height = 300;
    //   dollarText3.x = 240;
    //   dollarText3.y = 155;

    //   const dollarBtn4 = new Sprite(
    //     Texture.from("/assets/Denominations/Denom-Button-4.png")
    //   );
    //   dollarBtn4.addChild(dollarText4);
    //   dollarText4.width = 400;
    //   dollarText4.height = 300;
    //   dollarText4.x = 240;
    //   dollarText4.y = 155;

    //   const dollarBtn5 = new Sprite(
    //     Texture.from("/assets/Denominations/Denom-Button-5.png")
    //   );
    //   dollarBtn5.addChild(dollarText5);
    //   dollarText5.width = 400;
    //   dollarText5.height = 300;
    //   dollarText5.x = 240;
    //   dollarText5.y = 155;

    //   dollarBtn1.width = 900;
    //   dollarBtn1.height = 520;
    //   dollarBtn1.x = dollarBg.width / 10;
    //   dollarBtn1.y = dollarBg.height / 2;
    //   dollarBtn1.eventMode = "static";
    //   dollarBtn1.cursor = "pointer";
    //   dollarBtn1.addEventListener("pointerdown", () => {
    //     dollarBg.visible = false;
    //     coinDenonimation = 1;
    //     BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
    //     MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
    //     stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
    //     MiniText.text = `$${formatter.format(
    //       (betVal / 5) * coinDenonimation
    //     )}.00`;
    //     stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(
    //       2
    //     )}`;
    //     // updateCredits(creditsVal/coinDenonimation);
    //     updateCredits(Math.floor(creditsVal / coinDenonimation));
    //     DenominationText.text = `${1}\u20B5`;
    //     DenominationText.x =
    //       window.innerWidth > 480
    //         ? (app.screen.width - DenominationImg.width) / 8
    //         : DenominationText.x;
    //     removingfunction();

    //     winBlackBg.visible = false;
    //     creditsValue.x =
    //       creditsValue.text.length === 3
    //         ? X1percent * 30.8
    //         : creditsValue.text.length === 12
    //         ? X1percent * 27
    //         : creditsValue.text.length === 5
    //         ? X1percent * 30
    //         : creditsValue.text.length === 6
    //         ? X1percent * 29.5
    //         : creditsValue.text.length === 7
    //         ? X1percent * 28.8
    //         : creditsValue.text.length === 8
    //         ? X1percent * 29
    //         : creditsValue.text.length === 9
    //         ? X1percent * 28.3
    //         : creditsValue.text.length === 11
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 10
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 2
    //         ? X1percent * 31.5
    //         : X1percent * 32;
    //     responsiveTextMiniMinor();
    //   });

    //   dollarBtn2.width = 900;
    //   dollarBtn2.height = 520;
    //   dollarBtn2.x = dollarBg.width / 3.8;
    //   dollarBtn2.y = dollarBg.height / 2;
    //   dollarBtn2.eventMode = "static";
    //   dollarBtn2.cursor = "pointer";
    //   dollarBtn2.addEventListener("pointerdown", () => {
    //     dollarBg.visible = false;
    //     coinDenonimation = 2;
    //     MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
    //     stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
    //     MiniText.text = `$${formatter.format(
    //       (betVal / 5) * coinDenonimation
    //     )}.00`;
    //     stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(
    //       2
    //     )}`;
    //     BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
    //     DenominationText.text = `${2}\u20B5`;
    //     // updateCredits(creditsVal/coinDenonimation);
    //     updateCredits(Math.floor(creditsVal / coinDenonimation));
    //     DenominationText.x =
    //       window.innerWidth > 480
    //         ? (app.screen.width - DenominationImg.width) / 8
    //         : DenominationText.x;
    //     removingfunction();

    //     winBlackBg.visible = false;
    //     creditsValue.x =
    //       creditsValue.text.length === 3
    //         ? X1percent * 30.8
    //         : creditsValue.text.length === 12
    //         ? X1percent * 27
    //         : creditsValue.text.length === 5
    //         ? X1percent * 30
    //         : creditsValue.text.length === 6
    //         ? X1percent * 29.5
    //         : creditsValue.text.length === 7
    //         ? X1percent * 28.8
    //         : creditsValue.text.length === 8
    //         ? X1percent * 29
    //         : creditsValue.text.length === 9
    //         ? X1percent * 28.3
    //         : creditsValue.text.length === 11
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 10
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 2
    //         ? X1percent * 31.5
    //         : X1percent * 32;
    //     responsiveTextMiniMinor();
    //   });

    //   dollarBtn3.width = 900;
    //   dollarBtn3.height = 520;
    //   dollarBtn3.x = dollarBg.width / 2.35;
    //   dollarBtn3.y = dollarBg.height / 2;
    //   dollarBtn3.eventMode = "static";
    //   dollarBtn3.cursor = "pointer";
    //   dollarBtn3.addEventListener("pointerdown", () => {
    //     dollarBg.visible = false;
    //     coinDenonimation = 5;
    //     MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
    //     stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
    //     MiniText.text = `$${formatter.format(
    //       (betVal / 5) * coinDenonimation
    //     )}.00`;
    //     stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(
    //       2
    //     )}`;
    //     BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
    //     DenominationText.text = `${5}\u20B5`;
    //     updateCredits(Math.floor(creditsVal / coinDenonimation));
    //     DenominationText.x =
    //       window.innerWidth > 480
    //         ? (app.screen.width - DenominationImg.width) / 8
    //         : DenominationText.x;
    //     removingfunction();

    //     winBlackBg.visible = false;
    //     creditsValue.x =
    //       creditsValue.text.length === 3
    //         ? X1percent * 30.8
    //         : creditsValue.text.length === 12
    //         ? X1percent * 27
    //         : creditsValue.text.length === 5
    //         ? X1percent * 30
    //         : creditsValue.text.length === 6
    //         ? X1percent * 29.5
    //         : creditsValue.text.length === 7
    //         ? X1percent * 28.8
    //        : creditsValue.text.length === 8
    //         ? X1percent * 29
    //         : creditsValue.text.length === 9
    //         ? X1percent * 28.3
    //         : creditsValue.text.length === 11
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 10
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 2
    //         ? X1percent * 31.5
    //         : X1percent * 32;
    //     responsiveTextMiniMinor();
    //   });

    //   dollarBtn4.width = 900;
    //   dollarBtn4.height = 520;
    //   dollarBtn4.x = dollarBg.width / 1.7;
    //   dollarBtn4.y = dollarBg.height / 2;
    //   dollarBtn4.eventMode = "static";
    //   dollarBtn4.cursor = "pointer";
    //   dollarBtn4.addEventListener("pointerdown", () => {
    //     dollarBg.visible = false;
    //     coinDenonimation = 10;
    //     MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
    //     stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
    //     MiniText.text = `$${formatter.format(
    //       (betVal / 5) * coinDenonimation
    //     )}.00`;
    //     stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(
    //       2
    //     )}`;
    //     BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
    //     DenominationText.text = `${10}\u20B5`;
    //     updateCredits(Math.floor(creditsVal / coinDenonimation));
    //     DenominationText.x =
    //       window.innerWidth > 480
    //         ? (app.screen.width - DenominationImg.width) / 8.3
    //         : DenominationText.x;
    //     removingfunction();
    //     winBlackBg.visible = false;
    //     creditsValue.x =
    //       creditsValue.text.length === 3
    //         ? X1percent * 30.8
    //         : creditsValue.text.length === 12
    //         ? X1percent * 27
    //         : creditsValue.text.length === 5
    //         ? X1percent * 30
    //         : creditsValue.text.length === 6
    //         ? X1percent * 29.5
    //         : creditsValue.text.length === 7
    //         ? X1percent * 28.8
    //         : creditsValue.text.length === 8
    //         ? X1percent * 29
    //         : creditsValue.text.length === 9
    //         ? X1percent * 28.3
    //         : creditsValue.text.length === 11
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 10
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 2
    //         ? X1percent * 31.5
    //         : X1percent * 32;
    //     responsiveTextMiniMinor();
    //   });

    //   dollarBtn5.width = 900;
    //   dollarBtn5.height = 520;
    //   dollarBtn5.x = dollarBg.width / 1.33;
    //   dollarBtn5.y = dollarBg.height / 2;
    //   dollarBtn5.eventMode = "static";
    //   dollarBtn5.cursor = "pointer";
    //   dollarBtn5.addEventListener("pointerdown", () => {
    //     dollarBg.visible = false;
    //     coinDenonimation = 25;
    //     MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
    //     MinorText.anchor.set(0.43, 0.5);
    //     stickyMinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
    //     MiniText.text = `$${formatter.format(
    //       (betVal / 5) * coinDenonimation
    //     )}.00`;
    //     stickyMiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
    //     BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
    //     DenominationText.text = `${25}\u20B5`;
    //     updateCredits(Math.floor(creditsVal / coinDenonimation));
    //     DenominationText.x =
    //       window.innerWidth > 480
    //         ? (app.screen.width - DenominationImg.width) / 8.8
    //         : DenominationText.x;
    //     removingfunction();
    //     winBlackBg.visible = false;
    //     BetSubValue.x = (app.screen.width - BetSubValue.width) / 1.425;
    //     creditsValue.x =
    //       creditsValue.text.length === 3
    //         ? X1percent * 30.8
    //         : creditsValue.text.length === 12
    //         ? X1percent * 27
    //         : creditsValue.text.length === 5
    //         ? X1percent * 30
    //         : creditsValue.text.length === 6
    //         ? X1percent * 29.5
    //         : creditsValue.text.length === 7
    //         ? X1percent * 28.8
    //         : creditsValue.text.length === 8
    //         ? X1percent * 29
    //         : creditsValue.text.length === 9
    //         ? X1percent * 28.3
    //         : creditsValue.text.length === 11
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 10
    //         ? X1percent * 27.5
    //         : creditsValue.text.length === 2
    //         ? X1percent * 31.5
    //         : X1percent * 32;

    //     responsiveTextMiniMinor();
    //   });

      function responsiveTextMiniMinor() {
        //minor text responsive text
        if (window.innerWidth > 480) {
          if (MinorText.text.length >= 9) {
            window.innerWidth > 1440 && window.innerWidth < 1366
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : window.innerWidth >= 1366 && window.innerWidth < 1600
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : window.innerWidth >= 667 && window.innerWidth <= 932
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : (MinorText.x = (app.screen.width - MinorText.width) / 5.05);
          } else if (MinorText.text.length >= 8) {
            window.innerWidth > 1440
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : window.innerWidth >= 667 && window.innerWidth <= 932
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : (MinorText.x = (app.screen.width - MinorText.width) / 5.2);
          } else if (MinorText.text.length >= 7) {
            window.innerWidth > 1440
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.1)
              : window.innerWidth >= 667 && window.innerWidth <= 932
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : (MinorText.x = (app.screen.width - MinorText.width) / 5.2);
          } else if (MinorText.text.length >= 6) {
            window.innerWidth > 1440
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : (MinorText.x = (app.screen.width - MinorText.width) / 5.2);
          } else if (MinorText.text.length == 5) {
            window.innerWidth > 1440
              ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : (MinorText.x = (app.screen.width - MinorText.width) / 5);
          } else if (MinorText.text.length <= 4) {
            window.innerWidth > 1440
             ? (MinorText.x = (app.screen.width - MinorText.width) / 5.2)
              : (MinorText.x = (app.screen.width - MinorText.width) / 5.2);
          }
          //mini text responsive text
          if (MiniText.text.length >= 8) {
            window.innerWidth > 1440
              ? (MiniText.x = X1percent * 81.5)
              : window.innerWidth >= 667 && window.innerWidth <= 932
              ? (MiniText.x = X1percent * 81.5)
              : (MiniText.x = X1percent * 81.5);
          } else if (MiniText.text.length >= 7) {
            window.innerWidth > 1440
              ? (MiniText.x = X1percent * 81.5)
              : window.innerWidth >= 667 && window.innerWidth <= 932
              ? (MiniText.x = X1percent * 81.5)
              : (MiniText.x = X1percent * 81.5);
          } else if (MiniText.text.length >= 6) {
            window.innerWidth > 1440
              ? (MiniText.x = X1percent * 81.5)
              : window.innerWidth >= 667 && window.innerWidth <= 932
              ? (MiniText.x = X1percent * 81.5)
              : (MiniText.x = X1percent * 81.5);
          } else if (MiniText.text.length == 5) {
            window.innerWidth > 1440
              ? (MiniText.x = X1percent * 81.5)
              : (MiniText.x = X1percent * 81.5);
          } else if (MiniText.text.length == 4) {
            window.innerWidth > 1440
              ? (MiniText.x = X1percent * 81.5)
              : (MiniText.x = X1percent * 81.5);
          } else if (MiniText.text.length <= 3) {
            window.innerWidth > 1440
              ? (MiniText.x = X1percent * 81.5)
              : (MiniText.x = X1percent * 81.5);
          }

          //denomination responsive text
          if (DenominationText.text.length >= 3) {
            window.innerWidth > 1440
              ? (DenominationText.x = X1percent * 11.2)
              : window.innerWidth <= 932 && window.innerWidth > 736
              ? (DenominationText.x = X1percent * 11)
              : (DenominationText.x = X1percent * 11.5);
          } else {
            window.innerWidth > 1440
              ? (DenominationText.x = X1percent * 11.8)
              : window.innerWidth <= 932 && window.innerWidth > 736
              ? (DenominationText.x = X1percent * 11.5)
              : (DenominationText.x = X1percent * 12);
          }
          if (window.innerWidth >= 932 && window.innerWidth < 1024) {
            stickySpinBootomTextCount.text.length == 2
              ? (stickySpinBootomTextCount.x = X1percent * 89)
              : (stickySpinBootomTextCount.x = X1percent * 88);
          }
        } else {
          responsiveLandscap();
        }
      }

      function removingfunction() {
        setTimeout(() => {
          for (let i = 0; i < removePy.length; i++) {
            app.stage.removeChild(removePy[i]);
          }
        }, 0);
        clearInterval(intervalPayline);
        removePayLines();
        startCount = 0;
        clearInterval(payLineIntervel);
        popupContainer.visible = false;
        removeAllSprites();
      }

      function payLineTrueHandle() {
        reels[0].container.children[0].visible = true;
        reels[0].container.children[1].visible = true;
        reels[0].container.children[2].visible = true;
        reels[1].container.children[0].visible = true;
        reels[1].container.children[1].visible = true;
        reels[1].container.children[2].visible = true;
        reels[2].container.children[0].visible = true;
        reels[2].container.children[1].visible = true;
        reels[2].container.children[2].visible = true;
        reels[3].container.children[0].visible = true;
        reels[3].container.children[1].visible = true;
        reels[3].container.children[2].visible = true;
        reels[4].container.children[0].visible = true;
        reels[4].container.children[1].visible = true;
        reels[4].container.children[2].visible = true;

        // if (checkFIreIcon <= 6) {
          app.stage.removeChild(spriteA00);
          app.stage.removeChild(spriteA01);
          app.stage.removeChild(spriteA02);
          app.stage.removeChild(spriteA10);
          app.stage.removeChild(spriteA11);
          app.stage.removeChild(spriteA12);
          app.stage.removeChild(spriteA20);
          app.stage.removeChild(spriteA21);
          app.stage.removeChild(spriteA22);
          app.stage.removeChild(spriteA30);
          app.stage.removeChild(spriteA31);
          app.stage.removeChild(spriteA32);
          app.stage.removeChild(spriteA40);
          app.stage.removeChild(spriteA41);
          app.stage.removeChild(spriteA42);
          app.stage.removeChild(SunFireText);
        // }
      }

    //   dollarBg.height = app.screen.height / 2.5;
    //   dollarBg.width = app.screen.width / 2;
    //   dollarBg.x = app.screen.height / 2;
    //   dollarBg.y = app.screen.width / 7;
    //   dollarBg.addChild(dollarbGText);
    //   dollarBg.addChild(dollarBtn1);
    //   dollarBg.addChild(dollarBtn2);
    //   dollarBg.addChild(dollarBtn3);
    //   dollarBg.addChild(dollarBtn4);
    //   dollarBg.addChild(dollarBtn5);
    //   app.stage.addChild(dollarBg);
    //   dollarBg.visible = false;
      DenominationImg.eventMode = "static";
      DenominationImg.cursor = "pointer";
      DenominationImg.addListener("pointerdown", () => {
        if (
          remaingFreeSpin > 0 ||
          spaceFilledWtihFire >= 6 ||
          currentfreeSpinGameLoad > 0
        ) {
          return;
        }

        // dollarBg.visible = true;
      });
      BetBg.eventMode = "static";
      BetBg.cursor = "pointer";
      var DeltaXBetValue =
        window.innerWidth > 480
          ? (app.screen.width - BetText.width) / 1.462
          : BetValue.x + 50;
      var DeltaXSubValue =
        window.innerWidth > 480 ? BetSubValue.x : BetSubValue.x + 60;
      BetBg.addEventListener("pointerdown", () => {
        if (
          remaingFreeSpin > 0 ||
          spaceFilledWtihFire >= 6 ||
          currentfreeSpinGameLoad
        ) {
          return;
        }
        winBlackBg.visible = false;
        if (betVal <= 50 || betVal < 150) {
          betVal += 50;
          BetValue.x =
            defaultLang == "es"
              ? window.innerWidth > 480
                ? (app.screen.width - BetText.width) / 1.39
                : DeltaXBetValue
              : window.innerWidth > 480
              ? (app.screen.width - BetText.width) / 1.46
              : DeltaXBetValue;
          BetSubValue.x =
            defaultLang == "es"
              ? (app.screen.width - BetText.width) / 1.39
              : (app.screen.width - BetText.width) / 1.43;
          responsiveTextMiniMinor();
        } else if (betVal === 150 && betVal < 250) {
          betVal += 100;
          BetValue.x =
            defaultLang == "es"
              ? window.innerWidth > 480
                ? (app.screen.width - BetText.width) / 1.39
                : DeltaXBetValue
              : window.innerWidth > 480
              ? (app.screen.width - BetText.width) / 1.46
              : DeltaXBetValue;
          BetSubValue.x =
            defaultLang == "es"
              ? window.innerWidth > 480
                ? BetSubValue.x
                : DeltaXSubValue
              : window.innerWidth > 480
              ? BetSubValue.x
              : DeltaXSubValue;
          responsiveTextMiniMinor();
        } else if (betVal >= 250 && betVal < 500) {
          betVal += 250;
          BetValue.x =
            defaultLang == "es"
              ? window.innerWidth > 480
                ? (app.screen.width - BetText.width) / 1.39
                : DeltaXBetValue
              : window.innerWidth > 480
              ? (app.screen.width - BetText.width) / 1.462
              : DeltaXBetValue;
          BetSubValue.x =
            defaultLang == "es"
              ? window.innerWidth > 480
                ? (app.screen.width - BetText.width) / 1.39
                : DeltaXSubValue
              : window.innerWidth > 480
              ? (app.screen.width - BetText.width) / 1.43
              : DeltaXSubValue;
          responsiveTextMiniMinor();
        } else {
          betVal = 50;
          if (betVal === 50) {
            BetValue.x =
              defaultLang == "es"
                ? window.innerWidth > 480
                  ? (app.screen.width - BetText.width) / 1.39
                  : DeltaXBetValue
                : window.innerWidth > 480
                ? (app.screen.width - BetText.width) / 1.455
                : DeltaXBetValue;
            BetSubValue.x =
              defaultLang == "es"
                ? window.innerWidth > 480
                  ? (app.screen.width - BetText.width) / 1.39
                  : DeltaXSubValue
                : window.innerWidth > 480
                ? (app.screen.width - BetText.width) / 1.43
                : DeltaXSubValue;
            responsiveTextMiniMinor();
          }
        }
        MinorText.text = `$${formatter.format(betVal * coinDenonimation)}.00`;
        MiniText.text = `$${formatter.format(
          (betVal / 5) * coinDenonimation
        )}.00`;

        BetValue.text = `${betVal}`;

        let input = parseFloat(betVal / 100);
        BetSuboutput =
          input % 1 === 0
            ? input.toFixed(2).replace(/\.0$/, "")
            : input.toFixed(2);
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        removingfunction();

        responsiveTextMiniMinor();
      });

      // // Payllines
      function indexVal(index) {
        if (index === 0) {
          return 3;
        }
        if (index === 1) {
          return 2;
        }
        if (index === 2) {
          return 1;
        }
      }
      let payLine;

      var intervalPayline;
      const createSinglePayline = (paylinesNum) => {
        clearInterval(intervalPayline);
        intervalPayline = setInterval(() => {
          if (paylinesNum?.length > 0) {
            payLine = new Sprite(
              Texture?.from(
                getPaylines(
                  `/assets/PayLines/PayLine${paylinesNum[startCount]}.png`
                )
              )
            );

            if (window.innerWidth > 480) {
              updatePopupUI(paylinesNum[startCount], winAmountShow[startCount]);
            }
            payLineAnimateArr[startCount]?.splice(occurenceArr[startCount]);
            payLineAnimateArr[startCount]?.forEach((el, i) => {
              let index = indexVal(el);
              if (reels[i]?.container?.children[index]?.texture?.name === "WW") {
                reels[i].container.children[index].visible = false;
              }
              if (reels[i]?.container?.children[index]?.texture?.name === winningSymbolArr[startCount]) {
                if (winningSymbolArr[startCount]) {
                  reels[i].container.children[index].visible = false;
                }
              }
            });

            payLine.width =
              window.innerWidth > 480
                ? ((app.screen.width - GameFrame.width) * 3.48) / 2
                : X1percent * 95;
            payLine.height =
              window.innerWidth > 480
                ? app.screen.height / 1.62
                : Y1percent * 28;
            payLine.x =
              window.innerWidth > 480
                ? (app.screen.width - payLine.width) / 2
                : X1percent * 2.5;
            payLine.y =
              window.innerWidth > 480
                ? (app.screen.height - payLine.height) / 2
                : Y1percent * 35;
            app.stage.addChildAt(payLine, 35);
            removePy.push(payLine);
            setTimeout(() => {
              for (let i = 0; i < removePy.length; i++) {
                app.stage.removeChild(removePy[i]);
              }

              if (startCount === paylinesNum?.length - 1) {
                startCount = 0;
                comingImg();
                removeAllSprites();
              } else {
                startCount++;
                comingImg();
                removeAllSprites();
              }
            }, 2000);
          } else {
            clearInterval(intervalPayline);
          }
          comingSpriteAnimation();
        }, 2400);
        comingSpriteAnimation();
      };

      function getPaylines(line) {
        return seperatePayline.find((item) => item === line);
      }
      function comingImg() {
        for (let i = 0; i < 5; i++) {
          for (let j = 1; j < 4; j++) {
            if (
              reels[i]?.container?.children[j]?.visible === false &&
              reels[i]?.container?.children[j]?.texture?.name !== "TT"
            ) {
              reels[i].container.children[j].visible = true;
            }
          }
        }
      }


      function comingSpriteAnimation() {
        if (
         reels[0].container.children[1].visible === false &&
          reels[0].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[0].container.children[1].texture.name,
            window.innerWidth > 480
              ? { xvalue: 4, yvalue: 3.5 }
              : { xvalue: 9, yvalue: 2.62 }
          );
          //animateSpinSprite(reels[0].container.children[0].texture.name, 4, 3.5);
        }
        if (
          reels[0].container.children[2].visible === false &&
          reels[0].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[0].container.children[2].texture.name,
            window.innerWidth > 480
              ? { xvalue: 4, yvalue: 2.05 }
              : { xvalue: 9, yvalue: 2.07 }
          );
          //animateSpinSprite(reels[0].container.children[1].texture.name, 4, 2.05);
        }
        if (
          reels[0].container.children[3].visible === false &&
          reels[0].container.children[3].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[0].container.children[3].texture.name,
            window.innerWidth > 480
              ? { xvalue: 4, yvalue: 1.435 }
              : { xvalue: 9, yvalue: 1.72 }
          );
          // animateSpinSprite(reels[0].container.children[2].texture.name, 4, 1.435);
        }
        if (
          reels[1].container.children[1].visible === false &&
          reels[1].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[1].container.children[1].texture.name,
            window.innerWidth > 480
              ? { xvalue: 2.67, yvalue: 3.5 }
              : { xvalue: 3.2, yvalue: 2.62 }
          );
          // animateSpinSprite(reels[1].container.children[0].texture.name, 2.67, 3.5);
        }
        if (
          reels[1].container.children[2].visible === false &&
          reels[1].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[1].container.children[2].texture.name,
            window.innerWidth > 480
              ? { xvalue: 2.67, yvalue: 2.05 }
              : { xvalue: 3.2, yvalue: 2.07 }
          );
          // animateSpinSprite(reels[1].container.children[1].texture.name, 2.67, 2.05);
        }
        if (
          reels[1].container.children[3].visible === false &&
          reels[1].container.children[3].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[1].container.children[3].texture.name,
            window.innerWidth > 480
              ? { xvalue: 2.67, yvalue: 1.435 }
              : { xvalue: 3.2, yvalue: 1.72 }
          );
          // animateSpinSprite(reels[1].container.children[2].texture.name, 2.67, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[2].container.children[1].visible === false &&
          reels[2].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[2].container.children[1].texture.name,
            window.innerWidth > 480
              ? { xvalue: 2, yvalue: 3.5 }
              : { xvalue: 2, yvalue: 2.62 }
          );
          // animateSpinSprite(reels[2].container.children[0].texture.name, 2, 3.5);
        }
        if (
          reels[2].container.children[2].visible === false &&
          reels[2].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[2].container.children[2].texture.name,
            window.innerWidth > 480
              ? { xvalue: 2, yvalue: 2.05 }
              : { xvalue: 2, yvalue: 2.07 }
          );
          // animateSpinSprite(reels[2].container.children[1].texture.name, 2, 2.05);
        }
        if (
          reels[2].container.children[3].visible === false &&
          reels[2].container.children[3].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[2].container.children[3].texture.name,
            window.innerWidth > 480
              ? { xvalue: 2, yvalue: 1.435 }
              : { xvalue: 2, yvalue: 1.72 }
          );
          // animateSpinSprite(reels[2].container.children[2].texture.name, 2, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        // x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[3].container.children[1].visible === false &&
          reels[3].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[3].container.children[1].texture.name,
            window.innerWidth > 480
              ? { xvalue: 1.6, yvalue: 3.5 }
              : { xvalue: 1.45, yvalue: 2.62 }
          );
          // animateSpinSprite(reels[3].container.children[0].texture.name, 1.6, 3.5);
        }
        if (
          reels[3].container.children[2].visible === false &&
          reels[3].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[3].container.children[2].texture.name,
            window.innerWidth > 480
              ? { xvalue: 1.6, yvalue: 2.05 }
              : { xvalue: 1.45, yvalue: 2.07 }
          );
          // animateSpinSprite(reels[3].container.children[1].texture.name, 1.6, 2.05);
        }
        if (
          reels[3].container.children[3].visible === false &&
          reels[3].container.children[3].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[3].container.children[3].texture.name,
            window.innerWidth > 480
              ? { xvalue: 1.6, yvalue: 1.435 }
              : { xvalue: 1.45, yvalue: 1.72 }
         );
          // animateSpinSprite(reels[3].container.children[2].texture.name, 1.6, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[4].container.children[1].visible === false &&
          reels[4].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[4].container.children[1].texture.name,
            window.innerWidth > 480
              ? { xvalue: 1.335, yvalue: 3.5 }
              : { xvalue: 1.12, yvalue: 2.62 }
          );
          // animateSpinSprite(reels[4].container.children[0].texture.name, 1.335, 3.5);
        }
        if (
          reels[4].container.children[2].visible === false &&
          reels[4].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[4].container.children[2].texture.name,
            window.innerWidth > 480
              ? { xvalue: 1.335, yvalue: 2.05 }
              : { xvalue: 1.12, yvalue: 2.07 }
          );
          // animateSpinSprite(reels[4].container.children[1].texture.name, 1.335, 2.05);
        }
        if (
          reels[4].container.children[3].visible === false &&
          reels[4].container.children[3].texture.name !== "TT"
        ) {
          animateSpinSprite(
            reels[4].container.children[3].texture.name,
            window.innerWidth > 480
              ? { xvalue: 1.335, yvalue: 1.435 }
              : { xvalue: 1.12, yvalue: 1.72 }
          );
          // animateSpinSprite(reels[4].container.children[2].texture.name, 1.335, 1.435);
        }
      }


      
      // function comingSpriteAnimation() {
      //   if (
      //   // //   reels[0].container.children[0].visible === false &&
      //   // //   reels[0].container.children[0].texture.name !== "TT"
      //   // // ) {
      //   // //   animateSpinSprite(
      //   // //     reels[0].container.children[0].texture.name,
      //   //     window.innerWidth > 480
      //   //       ? { xvalue: 4, yvalue: 3.5 }
      //   //       : { xvalue: 9, yvalue: 2.62 }
      //   //   );
      //   // }
      //   // if (
      //     reels[0].container.children[1].visible === false &&
      //     reels[0].container.children[1].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[0].container.children[1].texture.name,
      //       window.innerWidth > 480 ? { xvalue: 4, yvalue: 3.5 } : { xvalue: 9, yvalue: 2.62 }
      //     );
      //   }
      //   if (
      //     reels[0].container.children[2].visible === false &&
      //     reels[0].container.children[2].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[0].container.children[2].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 4, yvalue: 2.05 }
      //         : { xvalue: 9, yvalue: 2.07 }
      //     );
      //   }
      //   if (reels[0].container.children[3].visible === false && reels[0].container.children[3].texture.name !== "TT") {
      //     animateSpinSprite(reels[0].container.children[3].texture.name, window.innerWidth > 480 ? { xvalue: 4, yvalue: 1.435 } : { xvalue: 9, yvalue: 1.72 });
      //   }
      //   if (
      //     reels[1].container.children[1].visible === false &&
      //     reels[1].container.children[1].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[1].container.children[1].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 2.67, yvalue: 3.5 }
      //         : { xvalue: 3.2, yvalue: 2.62 }
      //     );
      //   }
      //   if (
      //     reels[1].container.children[2].visible === false &&
      //     reels[1].container.children[2].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[1].container.children[2].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 2.67, yvalue: 2.05 }
      //         : { xvalue: 3.2, yvalue: 2.07 }
      //     );
      //   }
      //   if (reels[1].container.children[3].visible === false && reels[1].container.children[3].texture.name !== "TT")  {
      //     animateSpinSprite(
      //       reels[1].container.children[3].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 2.67, yvalue: 1.435 }
      //         : { xvalue: 3.2, yvalue: 1.72 }
      //     );
      //   }
      //   if (
      //     reels[2].container.children[1].visible === false &&
      //     reels[2].container.children[1].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[2].container.children[1].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 2, yvalue: 3.5 }
      //         : { xvalue: 2, yvalue: 2.62 }
      //     );
      //   }
      //   if (
      //     reels[2].container.children[2].visible === false &&
      //     reels[2].container.children[2].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[2].container.children[2].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 2, yvalue: 1.435 }
      //         : { xvalue: 2, yvalue: 1.72 }
      //     );
      //   }

      //   if (
      //     reels[3].container.children[0].visible === false &&
      //     reels[3].container.children[0].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[3].container.children[0].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 1.6, yvalue: 3.5 }
      //         : { xvalue: 1.45, yvalue: 2.62 }
      //     );
      //   }
      //   if (
      //     reels[3].container.children[1].visible === false &&
      //     reels[3].container.children[1].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[3].container.children[1].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 1.6, yvalue: 2.05 }
      //         : { xvalue: 1.45, yvalue: 2.07 }
      //     );
      //   }
      //   if (
      //     reels[3].container.children[2].visible === false &&
      //     reels[3].container.children[2].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[3].container.children[2].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 1.6, yvalue: 1.435 }
      //         : { xvalue: 1.45, yvalue: 1.72 }
      //     );
      //   }

      //   if (
      //     reels[4].container.children[0].visible === false &&
      //     reels[4].container.children[0].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[4].container.children[0].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 1.335, yvalue: 3.5 }
      //         : { xvalue: 1.12, yvalue: 2.62 }
      //     );
      //   }
      //   if (
      //     reels[4].container.children[1].visible === false &&
      //     reels[4].container.children[1].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[4].container.children[1].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 1.335, yvalue: 2.05 }
      //         : { xvalue: 1.12, yvalue: 2.07 }
      //     );
      //   }
      //   if (
      //     reels[4].container.children[2].visible === false &&
      //     reels[4].container.children[2].texture.name !== "TT"
      //   ) {
      //     animateSpinSprite(
      //       reels[4].container.children[2].texture.name,
      //       window.innerWidth > 480
      //         ? { xvalue: 1.335, yvalue: 1.435 }
      //         : { xvalue: 1.12, yvalue: 1.72 }
      //     );
      //   }
      // }

      var removePy = [];
      const createLinesBunch_No = (paylinesNum) => {
        if (IsstickySpin !== true) {
          setTimeout(() => {
            winBlackBg.visible = true;
            paylinesNum.map((item) => {
              payLine = new Sprite(
                Texture.from(`/assets/PayLines/PayLine${item}.png`)
              );
              payLine.width =
                window.innerWidth > 480
                  ? ((app.screen.width - GameFrame.width) * 3.48) / 2
                  : X1percent * 95;
              payLine.height =
                window.innerWidth > 480
                  ? app.screen.height / 1.62
                  : Y1percent * 28;
              payLine.x =
                window.innerWidth > 480
                  ? (app.screen.width - payLine.width) / 2
                  : X1percent * 2.5;
              payLine.y =
                window.innerWidth > 480
                  ? (app.screen.height - payLine.height) / 2
                  : Y1percent * 35;

              app.stage.addChildAt(payLine, 30);
              removePy.push(payLine);
            });
          }, 2000);

          setTimeout(() => {
            for (let i = 0; i < removePy.length; i++) {
              app.stage.removeChild(removePy[i]);
            }
          }, 2500);

          setTimeout(() => {
            createSinglePayline(paylinesNum);
          }, 600);
        } else {
          removePy.push(payLine);
        }
      };
      const removePayLines = () => {
        removePy.visible = false;
        app.stage.removeChild(removePy);
        paylinesNum = [];
      };

      // **************************** History code************************** //
      // Create the pop-up container
      const popupContainer = new PIXI.Container();
      app.stage.addChild(popupContainer);

      // Add the pop-up background
      const popupBackground = new PIXI.Graphics();
      popupBackground.x =
        window.innerWidth > 1440
          ? (app.screen.width - popupBackground.width) / 7
          : window.innerWidth > 1366 && window.innerWidth < 1440
          ? app.screen.width / 35
          : window.innerWidth > 800 && window.innerWidth < 1367
          ? (app.screen.width - popupBackground.width) / 8
          : app.screen.width / 50;
      popupBackground.y =
        window.innerWidth > 1440
          ? (app.screen.height - popupBackground.height) / 2.2
          : window.innerWidth > 1366 && window.innerWidth < 1440
          ? (app.screen.height - popupBackground.height) / 2.2
          : window.innerWidth > 800 && window.innerWidth < 1367
          ? (app.screen.height - popupBackground.height) / 2.5
          : app.screen.height / 2;
      popupBackground.beginFill("000", 0.4);
      popupBackground.drawRect(0, 0, 100, 100);
      popupBackground.endFill();
      popupContainer.addChild(popupBackground);

      const contentText = new PIXI.Text("Line: 0\nPay: 0", {
        fontSize: 18,
        fill: "#b38b00",
      });
      contentText.x = 15;
      contentText.y = 25;
      popupBackground.addChild(contentText);
      popupContainer.visible = false;
      function updatePopupUI(line, pay) {
        popupContainer.visible = true;
        contentText.text = `Line: ${line}\nPay:  ${pay}`;
      }

      const historyBtn = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales1.png")
      );
      historyBtn.height = app.screen.height / 7;
      historyBtn.width = app.screen.height / 7;
      historyBtn.y = (app.screen.height - historyBtn.height) / 1.36;
      historyBtn.x = (app.screen.width - historyBtn.width) / 10;
      app.stage.addChild(historyBtn);
      historyBtn.eventMode = "static";
      historyBtn.cursor = "pointer";
      historyBtn.on("pointerdown", () => {
        setHtmlVisible(!isHtmlVisible); // Toggle HTML visibility
      });

      const spinRemainText = new TextStyle({
        fontSize:
          window.innerWidth > 1600
            ? app.screen.width / 70
            : window.innerWidth > 1366 && window.innerWidth < 1600
            ? app.screen.width / 70
            : window.innerWidth > 800 && window.innerWidth < 1367
            ? app.screen.width / 70
            : window.innerWidth <= 480
            ? Y1percent * 2
            : app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      const spinText = new TextStyle({
        fontSize:
          window.innerWidth > 1600
            ? app.screen.width / 40
            : window.innerWidth > 1366 && window.innerWidth < 1599
            ? app.screen.width / 30
            : window.innerWidth > 800 && window.innerWidth < 1367
            ? app.screen.width / 30
            : window.innerWidth <= 480
            ? Y1percent * 8
            : app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      const freeSpinImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );

      var freeSpinRemainingText = new Text(
        getMessage(defaultLang, "RemainingFreeSpinText"),
        spinRemainText
      );
      freeSpinRemainingText.x = app.screen.width / 1.33;
      freeSpinRemainingText.y = app.screen.height / 1.115;
      app.stage.addChild(freeSpinRemainingText);

      freeSpinImg.scale.set(0.6);
      freeSpinImg.x =
        window.innerWidth > 1600
          ? (app.screen.width - freeSpinImg.width) / 1.12
          : window.innerWidth > 1366 && window.innerWidth < 1599
          ? (app.screen.width - freeSpinImg.width) / 1.1
          : window.innerWidth > 800 && window.innerWidth < 1367
          ? (app.screen.width - freeSpinImg.width) / 1.07
          : app.screen.width / 50;
      freeSpinImg.y = (app.screen.height - freeSpinImg.height) / 1.02;
      app.stage.addChild(freeSpinImg);
      const freeSpinText = new Text(`${freeSpin || 0}`, spinText);

      if (freeSpinText.text.length === 2) {
        freeSpinText.x =
          window.innerWidth > 1600
            ? X1percent * 3.5
            : window.innerWidth > 1366 && window.innerWidth < 1599
            ? X1percent * 4.3
            : window.innerWidth > 800 && window.innerWidth < 1367
            ? (freeSpinImg.width - freeSpinText.width) / 0.8
            : app.screen.width / 50;
      } else {
        freeSpinText.x =
          window.innerWidth > 1600
            ? X1percent * 4
            : window.innerWidth > 1366 && window.innerWidth < 1599
            ? X1percent * 5
            : window.innerWidth > 800 && window.innerWidth < 1367
            ? (freeSpinImg.width - freeSpinText.width) / 1
            : app.screen.width / 50;
      }
      freeSpinText.y =
        window.innerWidth > 1600
          ? (freeSpinImg.height - freeSpinText.height) / 0.85
          : window.innerWidth > 1366 && window.innerWidth < 1599
          ? (freeSpinImg.height - freeSpinText.height) / 0.85
          : window.innerWidth > 800 && window.innerWidth < 1367
          ? (freeSpinImg.height - freeSpinText.height) / 0.9
          : app.screen.width / 50;
      freeSpinImg.addChild(freeSpinText);
      freeSpinImg.visible = false;
      freeSpinRemainingText.visible = false;

      const startFreeSpin = new Sprite(
        Texture.from(
          window.innerWidth > 480
            ? "/assets/FreeGames/DoradoPressPlay.png"
            : "/assets/FreeGames/DoradoPressPlay_mobile.png"
        )
      );
      startFreeSpin.scale.set(0.12);
      startFreeSpin.x = (app.screen.width - startFreeSpin.width) / 1.75;
      startFreeSpin.y = (app.screen.height - startFreeSpin.height) / 1.5;
      startFreeSpin.anchor.set(0.5);
      app.stage.addChild(startFreeSpin);
      startFreeSpin.visible = false;


      if (IsstickySpin === true || freeSpinType === true) {
        FoFImg.visible = false;
      } else {
        setTimeout(() => {
          if (
            currentfreeSpinGameLoad > 0 ||
            remaingFreeSpin > 0 ||
            freeSpin > 0
          ) {
            FoFImg.visible = false;
            freeSpinRemainingText.visible = false;
            freeSpinImg.visible = false;
          } else if (freeSpin < 1) {
            FoFImg.visible = true;
          } else {
            FoFImg.visible = true;
          }
        }, 500);
      }

      app.stage.addChild(FoFImg);

      let isRunning = false; // Flag to control the execution of the function
      let freeSpinInterval = null;

      function start() {
        freeSpinType = true;
        startFreeSpin.visible = false;
        freeSpinModalContainer.visible = false;
        freeSpinInterval = setInterval(freeSpinHandle, 500);
        // setTimeout(()=>{
        //   freeWinAudio1.play()
        // },4200)
      }

      // let spinReelNum;
      async function freeSpinHandle() {
        clearInterval(freeSpinInterval);
        if (isRunning) {
          return; // Exit the function if isRunning is true
        }
        freeSpin--;
        removingfunction();
        payLineTrueHandle();
        startPlay();
        await gameSpinApi(true);
        // spinReelNum = parseInt(freeSpinText.text);
        setTimeout(()=>{

          if(freeSpin === 0){
            FoFImg.visible = true;
            freeSpinText.text = `${remaingFreeSpin}`;
            console.log(freeSpinText.text, 'inside this --- 8')
          }else{
            freeSpinText.text = `${freeSpin}`;
            console.log(freeSpinText.text, 'inside this --- 9')
            FoFImg.visible = false;
          }
          freeSpinType = false;
          disableSpace = true;
          console.log(freeSpinText.text,'inside this --- 1', freeSpinType, freeGamesWin, disableSpace)
        }, 3600)
        if (freeSpin < 1) {
          clearInterval(freeSpinInterval); // Stop the interval
          if (winValue > 0 && StickeySpinWon == false) {
            setTimeout(() => {
              freeSpinPopupContainer.visible = true;
              showWinValue(finalFreeSpinWinning);
              winBtnHandle(finalFreeSpinWinning)
              freeSpinCredits.text = `${finalFreeSpinWinning}`;
              // bottom.eventMode = "none";
              // bottom.cursor = "none";
              SpinBtn.visible = false;
              clearInterval(freeSpinInterval);
            }, 5000);
          } else {
            freeSpinPopupContainer.visible = false;
          }
          currentfreeSpinGameLoad = 0;

          freeSpinImg.visible = false;
          freeSpinRemainingText.visible = false;
          // freeSpinMusic.pause();
          bottom.eventMode = "static";
          bottom.cursor = "pointer";
          SpinBtn.visible = true;
          setTimeout(() => {
            freeSpinType = false;
            freeSpinMusic.pause();
            creditsBtnHandle((creditsVal/coinDenonimation),creditsVal);
          }, 4000);

          return; // Exit the function
        }
        freeSpinInterval = setInterval(freeSpinHandle, 7000);
      }

      startFreeSpin.eventMode = "static";
      startFreeSpin.cursor = "pointer";
      startFreeSpin.addListener("pointerdown", () => {
        start();
      });
      setTimeout(() => {
        if (currentfreeSpinGameLoad > 0 && !currentStickySpinGameLoad) {
          noOfFreeSpinWonHandleGameLoad();
          freeSpinText.text = `${currentfreeSpinGameLoad}`;
          console.log(freeSpinText.text, 'inside this --- 10')
        } else {
          setTimeout(() => {
            if (running === false) {
              bottom.eventMode = "static";
              bottom.cursor = "pointer";
            }
          }, 2500);
        }
      }, 500);

      const freeSpinStyle = new TextStyle({
        fontSize:
          window.innerWidth > 480 ? app.screen.width / 40 : Y1percent * 3,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        align: "center",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6,
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      // free spin modal container code start here
      const freeSpinModalContainer = new Container();
      const freeSpinModalBackground = new Sprite(
        Texture.from("/assets/MainBG/modalBg.png")
      );
      freeSpinModalBackground.scale.set(0.6);
      freeSpinModalBackground.x =
        (app.screen.width - freeSpinModalBackground.width) / 2;
      freeSpinModalBackground.y =
        (app.screen.height - freeSpinModalBackground.height) / 2;
      freeSpinModalContainer.addChild(freeSpinModalBackground);
      app.stage.addChild(freeSpinModalContainer);

      const freeSpinMesg = new Text(
        defaultLang == "es"
          ? `Te quedan ${currentfreeSpinGameLoad} giros gratis. 
        Haz clic en jugar para consumir el giro gratis restante.`
          : `You have ${currentfreeSpinGameLoad} free Spins left. 
        Click on play to consume the remaining free spin.`,
        {
          font: freeSpinStyle.font,
          fontSize: window.innerWidth > 480 ? Y1percent * 3 : Y1percent * 2,
          fill: freeSpinStyle.fill,
          align: "center",
          wordWrap: true, // Add this line
          wordWrapWidth: X1percent * 30, // Adjust the width to your liking
        }
      );

      freeSpinMesg.y = Y1percent * 38;
      freeSpinMesg.x =
        window.innerWidth > 1600
          ? X1percent * 35
          : window.innerWidth > 1440 && window.innerWidth <= 1599
          ? X1percent * 38
          : window.innerWidth > 1024 && window.innerWidth <= 1366
          ? X1percent * 38
          : X1percent * 38;
      if (window.innerWidth <= 480) {
        freeSpinMesg.x = X1percent * 15;
        freeSpinMesg.style.wordWrapWidth = X1percent * 70;
      }
      freeSpinModalContainer.addChild(freeSpinMesg);
      freeSpinModalContainer.visible = false;

      // free Spin win score score
      const freeSpinPopupContainer = new Container();
      const freeSpinPopupBackground = new Sprite(
        Texture.from("/assets/MainBG/freeWinBg.png")
      );
      freeSpinPopupBackground.scale.set(0.6);
      freeSpinPopupBackground.width = app.screen.width / 3.5;
      freeSpinPopupBackground.height = app.screen.height / 2.8;
      freeSpinPopupBackground.x =
        (app.screen.width - freeSpinPopupBackground.width) / 2;
      freeSpinPopupBackground.y =
        (app.screen.height - freeSpinPopupBackground.height) / 2;
      freeSpinPopupContainer.addChild(freeSpinPopupBackground);
      app.stage.addChild(freeSpinPopupContainer);

      const freeSpinPopupMesg = new Text(`CONGRATULATIONS!`, {
        fontSize: app.screen.width / 60,
        fontFamily: "Arial , roboto, sans-serif",
        fill: freeSpinStyle.fill,
        align: "center",
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20, // Adjust the width to your liking
      });
      freeSpinPopupBackground.addChild(freeSpinPopupMesg);
      freeSpinPopupMesg.anchor.set(0.5);
      freeSpinPopupMesg.position.set(280, 80);

      const freeSpinWon = new Text(`You Won Free Spin`, {
        fontSize: app.screen.width / 70,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "orange",
        align: "center",
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20, // Adjust the width to your liking
      });
      freeSpinPopupBackground.addChild(freeSpinWon);
      freeSpinWon.anchor.set(0.5);
      freeSpinWon.position.set(280, 140);

     const freeSpinReward = new Text(`Your Rewards:`, {
        fontSize: app.screen.width / 90,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#fff",
        align: "center",
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20, // Adjust the width to your liking
      });
      freeSpinPopupBackground.addChild(freeSpinReward);
      freeSpinReward.anchor.set(0.5);
      freeSpinReward.position.set(275, 200);

      const freeSpinWinBg = new Sprite(
        Texture.from("/assets/MainBG/freeSpinWinBg.png")
      );
      freeSpinWinBg.width = X1percent * 10;
      freeSpinWinBg.x = X1percent * 45;
      freeSpinWinBg.y = Y1percent * 52;
      freeSpinWinBg.height =
        (freeSpinPopupBackground.height - freeSpinWinBg.height) / 5;
      freeSpinPopupContainer.addChild(freeSpinWinBg);
      const freeSpinCoins = new Sprite(
        Texture.from("/assets/MainBG/coins.png")
      );
      freeSpinCoins.scale.set(0.8);
      freeSpinCoins.position.set(
        (freeSpinWinBg.width - freeSpinCoins.width) / 30,
        (freeSpinWinBg.height - freeSpinCoins.height) / 1
      );
      freeSpinWinBg.addChild(freeSpinCoins);

      // freeSpinCredits = new Text(`90000000`, {
      freeSpinCredits = new Text(`${freespinWinvalue}`, {
        fontSize: app.screen.width / 75,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#fff",
        align: "center",
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20, // Adjust the width to your liking
      });
      console.log(freespinWinvalue,'Free Spin winValue+++  7')

      freeSpinWinBg.addChild(freeSpinCredits);
      freeSpinCredits.anchor.set(0.5);
      freeSpinCredits.position.set((freeSpinCredits.text.length===8 ? 108 : freeSpinCredits.text.length===7 ? 108 : freeSpinCredits.text.length=== 6 ? 106 : freeSpinCredits.text.length===5 ? 103 : freeSpinCredits.text.length===4 ? 102 : freeSpinCredits.text.length <= 3 && freeSpinCredits.text.length >= 1 ? 96 : 90), 28);

      console.log(freeSpinCredits.text.length,'freeSpin credit', freeSpinCredits)
      const freeSpinPopupBtn = new Sprite(
        Texture.from("/assets/MainBG/freeSpinBtn.png")
      );
      freeSpinPopupBtn.width =
        (freeSpinPopupBackground.width - freeSpinPopupBtn.width) / 4;
      freeSpinPopupBtn.height =
        (freeSpinPopupBackground.height - freeSpinPopupBtn.height) / 5;
      freeSpinPopupBtn.x = X1percent * 47;
      freeSpinPopupBtn.y = Y1percent * 59;
      freeSpinPopupContainer.addChild(freeSpinPopupBtn);

      const closeFreeSpinPop = new Text(`OK`, {
        fontSize: app.screen.width / 70,
        fontFamily: "ADLaM Display, sans-serif",
        fill: "green",
        align: "center",
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinPopupBackground.width - 20, // Adjust the width to your liking
      });

      freeSpinPopupBtn.eventMode = "static";
      freeSpinPopupBtn.cursor = "pointer";
      freeSpinPopupBtn.addListener("pointerdown", () => {
        freeSpinPopupContainer.visible = false;
        bottom.eventMode = "static";
        bottom.cursor = "pointer";
        SpinBtn.visible = true;
        SpinBtn2.visible = true;
        winBlackBg.visible = false;
        //freespinOkButton();
      
        freespinWinvalue = 0;
        freespinWinvaluedollar = 0;
        freeSpinType = false;
    
        clearInterval(freeSpinInterval);
        clearInterval(stickywiningInterval);
        showWinValue(finalFreeSpinWinning);
        winBtnHandle(finalFreeSpinWinning)
        disableSpace = false;
        stickyspinType = false;
        freespinTypebool = false;
      });
      freeSpinPopupBtn.addChild(closeFreeSpinPop);
      closeFreeSpinPop.anchor.set(0.5);
      closeFreeSpinPop.position.set(45, 20);
      freeSpinPopupContainer.visible = false;

      const playFreeSpin = new Text(`Play`, freeSpinStyle);
      playFreeSpin.y = Y1percent * 55;
      playFreeSpin.x =
        window.innerWidth > 1600
          ? X1percent * 47
          : window.innerWidth > 1440 && window.innerWidth <= 1599
          ? X1percent * 47
          : window.innerWidth > 1024 && window.innerWidth <= 1366
          ? X1percent * 47
          : X1percent * 47;
      if (window.innerWidth <= 480) {
      }
      playFreeSpin.style.fontSize = freeSpinStyle.fontSize;
      freeSpinModalContainer.addChild(playFreeSpin);

      playFreeSpin.eventMode = "static";
      playFreeSpin.cursor = "pointer";
      playFreeSpin.addListener("pointerdown", () => {
        start();
        freespinTypebool=true;
        playFreeSpinAudio();
        freeSpinType = true;
        let checkTTSymbolCount = 0;
        reels.forEach((reel)=>{
          reel.container.children.forEach((e)=>{
            if(e.texture.name == 'TT'){
              checkTTSymbolCount = checkTTSymbolCount + 1;
              if(checkTTSymbolCount>=6) {
                backtofreegameafterSticky = true
              }else{
                totalWinstickyamount = 0;
              }
            }
          })
        })
      });

      // remaining sticky spin popup starts here
      const StickySpinModalContainer = new Container();
      const StickySpinModalBackground = new Sprite(
        Texture.from("/assets/MainBG/modalBg.png")
      );
      StickySpinModalBackground.scale.set(0.6);
      StickySpinModalBackground.x =
        (app.screen.width - StickySpinModalBackground.width) / 2;
      StickySpinModalBackground.y =
        (app.screen.height - StickySpinModalBackground.height) / 2;
      StickySpinModalContainer.addChild(StickySpinModalBackground);
      app.stage.addChild(StickySpinModalContainer);

      const StickySpinMesg = new Text(
        defaultLang == "es"
          ? `Te quedan ${currentStickySpinGameLoad} giros adhesivos. 
        Haz clic en reproducir para consumir los giros adhesivos restantes.`
          : `You have ${currentStickySpinGameLoad} sticky Spins left. 
        Click on play to consume the remaining sticky spins.`,
        {
          font: freeSpinStyle.font,
          fontSize: window.innerWidth > 480 ? Y1percent * 3 : Y1percent * 2,
          fill: freeSpinStyle.fill,
          align: "center",
          wordWrap: true, // Add this line
          wordWrapWidth: X1percent * 30, // Adjust the width to your liking
        }
      );
      StickySpinMesg.y = Y1percent * 38;
      StickySpinMesg.x =
        window.innerWidth > 1600
          ? X1percent * 35
          : window.innerWidth > 1440 && window.innerWidth <= 1599
          ? X1percent * 38
          : window.innerWidth > 1024 && window.innerWidth <= 1366
          ? X1percent * 38
          : X1percent * 38;
      if (window.innerWidth <= 480) {
        StickySpinMesg.x = X1percent * 15;
        StickySpinMesg.style.wordWrapWidth = X1percent * 70;
      }
      StickySpinModalContainer.addChild(StickySpinMesg);

      const playStickySpin = new Text(`Play`, freeSpinStyle);
      playStickySpin.y = Y1percent * 55;
      playStickySpin.x =
        window.innerWidth > 1600
          ? X1percent * 47
          : window.innerWidth > 1440 && window.innerWidth <= 1599
          ? X1percent * 47
          : window.innerWidth > 1024 && window.innerWidth <= 1366
          ? X1percent * 47
          : X1percent * 47;
      if (window.innerWidth <= 480) {
        // playStickySpin.x = X1percent * 0.1
      }
      playStickySpin.style.fontSize = freeSpinStyle.fontSize;
      StickySpinModalContainer.addChild(playStickySpin);

      playStickySpin.eventMode = "static";
      playStickySpin.cursor = "pointer";
      playStickySpin.addListener("pointerdown", () => {
        stickyspinType = true;
        IsstickySpin = true;
        triggerStikySpin();
        gameLoadComingStickSpin(gameLoadComingStickData);
        StickySpinModalContainer.visible = false;
      });
      if (remainStickySpin == true) {
        StickySpinModalContainer.visible = true;
      } else {
        StickySpinModalContainer.visible = false;
      }

      function noOfFreeSpinWonHandleGameLoad() {
        freeSpinType = true;

        if (freeSpinType == true) {
          setTimeout(() => {
            FoFImg.visible = false;
          }, 0);
        }
        freeSpinText.text = `${currentfreeSpin}`;
        console.log(freeSpinText.text,'inside this --- 5')

        freeSpinImg.visible = true;
        freeSpinModalContainer.visible = true;

        freeSpinRemainingText.visible = true;
        // bottom.eventMode = "none";
        // bottom.cursor = "none";
        SpinBtn.visible = false;
        freeSpinText.text = `${remaingFreeSpin}`;
        console.log(freeSpinText.text,'inside this --- 6')
        freeSpinText.text = `${currentfreeSpinGameLoad}`;
        console.log(freeSpinText.text,'inside this --- 7')

      }

      function noOfFreeSpinWonHandle() {
        UpdateWinValue(winValue,dollarWinValue);
        setTimeout(() => {
          freeSpinType = true;

          freeSpinType == true
            ? (FoFImg.visible = false)
            : (FoFImg.visible = true);
          playFreeSpinAudio();
          if(currentfreeSpin === 5){
            freeSpinText.text = `${currentfreeSpin}`;
            console.log(freeSpinText.text,'inside this --- 11')
          }else{
            freeSpinText.text = `${remaingFreeSpin}`;
          }
          console.log(freeSpinText.text,'inside this --- 9')

          freeSpinImg.visible = true;
          winBlackBg.visible = true;
          freeSpinRemainingText.visible = true;
          // bottom.eventMode = "none";
          // bottom.cursor = "none";
          SpinBtn.visible = false;
          if(freeGamesWin===true && currentfreeSpin ===5){
            startFreeSpin.visible = true;
          }else{
            startFreeSpin.visible = false;
          }

          FoFImg.visible = false;
          startFreeSpin.y = app.screen.height / 2;
          startFreeSpin.width = 0;
          startFreeSpin.height = 0;

          // Animate rotation and move to the middle of the screen
          gsap.to(startFreeSpin, {
            rotation: 0,
            width: 245.76,
            height: 245.76,
            y: (app.screen.height - startFreeSpin.height) / 2, // Move to the middle of the screen
            duration: 1.5,
            onComplete: () => {
              startFreeSpin.rotation = 0;
            },
          });
        }, 2500);
      }
      // *************** free spin End Code ****************************//

      async function gameLoadComingStickSpin(data) {
        await getProgressiveJackpot();
        prizeTypeForStickeySpin =
          data?.betResponses?.prizeTypeForStickeySpin?.split(",");
        remaningStickeySpin = data?.betResponses?.remaningStickeySpin;

        spaceFilledWtihFire = data?.betResponses?.spaceFilledWtihFire;
        stickySpinBootomTextCountHandle(spaceFilledWtihFire);
        linkPrizeAmount = data?.betResponses?.linkPrizeAmount?.split(",");
        jackpotWinContainer.visible = false;
        var dummuwiningCombination =
          data?.betResponses?.winningCombination?.split(",");
        imgArrSlot = [];
        function getImgWithUrl(name) {
          return slotTextures.filter((el, i) => (el.name === name ? el : ""));
        }
        for (let i = 0; i < dummuwiningCombination?.length; i++) {
          imgArrSlot.push(getImgWithUrl(dummuwiningCombination[i])[0]);
        }
        countTTSym = dummuwiningCombination?.filter(
          (symbol) => symbol === "TT"
        )?.length;
        setsymbols();
        spinReelAnimation();
        addStickyNumber([], [], [], []);
      }

      //responsive for landscape
      app.stage.addChild(jackpotWinContainer);

      function responsiveLandscap() {
        if (window.innerWidth <= 480) {
          BackgroundImg.visible = false;
          BackgroundImgmobile.visible = true;

          ExploradoraSimbolo.visible = true;
          BackgroundImg.visible = false;
          GameFrame.height = Y1percent * 31;
          GameFrame.width = X1percent * 100;
          GameFrame.x = X1percent * 0;
          GameFrame.y = Y1percent * 32.5;
          GameBG.height = Y1percent * 31;
          GameBG.width = X1percent * 100;
          GameBG.x = X1percent * 0;
          GameBG.y = Y1percent * 32.5;
          //sticky

          stickyGameFrame.height = Y1percent * 35;
          stickyGameFrame.width = X1percent * 105;
          stickyGameFrame.x = X1percent * -2.5;
          stickyGameFrame.y = Y1percent * 31;

          //Minor Text Frame Background
          MinorTextFrameBG.height = app.screen.height / 19;
          MinorTextFrameBG.width = X1percent * 23.5;
          MinorTextFrameBG.x = X1percent * 74;
          MinorTextFrameBG.y = Y1percent * 21.5;

          //Minor Text Frame
          MinorTextFrame.height = app.screen.height / 16;
          MinorTextFrame.width = X1percent * 23.5;
          MinorTextFrame.x = X1percent * 74;
          MinorTextFrame.y = Y1percent * 21;

          //Minor Text Png
          MinorTextImg.height = app.screen.height / 40;
          MinorTextImg.width = X1percent * 15;

          if (app.screen.width < 380 && app.screen.width > 340) {
            MinorTextImg.scale.set(0.25);
            MinorTextImg.x = X1percent * 75;
            MinorTextImg.y = Y1percent * 19;
          } else {
            MinorTextImg.scale.set(0.25);
            MinorTextImg.x = X1percent * 77;
            MinorTextImg.y = Y1percent * 20;
          }

          // Minor Text
          MinorText.width = app.screen.width / 8;
          MinorText.height = Y1percent * 2;
          MinorText.x = X1percent * 85;
          MinorText.y = Y1percent * 24;

          MinorStickyTextFrameBG.height = app.screen.height / 19;
          MinorStickyTextFrameBG.width = X1percent * 25;
          MinorStickyTextFrameBG.x = X1percent * 72;
          MinorStickyTextFrameBG.y = Y1percent * 22;

          //Minor Text Frame
          MinorStickyTextFrame.height = app.screen.height / 16;
          MinorStickyTextFrame.width = X1percent * 25;
          MinorStickyTextFrame.x = X1percent * 72;
          MinorStickyTextFrame.y = Y1percent * 21;

          //Minor Text Png
          MinorStickyTextImg.height = app.screen.height / 25;
          MinorStickyTextImg.width = X1percent * 25;
          MinorStickyTextImg.x = X1percent * 72;
          MinorStickyTextImg.y = Y1percent * 20;

          // Minor Text
          MinorText.width = app.screen.width / 8;
          MinorText.height = Y1percent * 2;
          MinorText.x = X1percent * 85;
          MinorText.y = Y1percent * 24;

          ////***************   Grand text Section strats   ***************
          //Grand Text Frame background Image
          GrandTextFrameBG.height = app.screen.height / 14.5;
          GrandTextFrameBG.width = X1percent * 30;
          GrandTextFrameBG.x = X1percent * 3;
          GrandTextFrameBG.y = Y1percent * 7.3;

          //Grand Text Frame
          GrandTextFrame.height = app.screen.height / 13.5;
          GrandTextFrame.width = X1percent * 30;
          GrandTextFrame.x = X1percent * 3;
          GrandTextFrame.y = Y1percent * 6.5;

          if (app.screen.width < 380 && app.screen.width > 340) {
            GrandTextImg.scale.set(0.2);
            GrandTextImg.x = X1percent * 6;
            GrandTextImg.y = Y1percent * 5;
          } else {
            GrandTextImg.scale.set(0.2);
            GrandTextImg.x = X1percent * 9;
            GrandTextImg.y = Y1percent * 6;
          }
          //Grand Text Frame imported here
          GrandTextImg.height = app.screen.height / 25;
          GrandTextImg.width = X1percent * 20;
          GrandTextImg.scale.set(0.12);
          GrandTextImg.x = X1percent * 8;
          GrandTextImg.y = Y1percent * 6.5;

          //sticky grand
          GrandstickyTextFrameBG.height = app.screen.height / 14.2;
          GrandstickyTextFrameBG.width = X1percent * 35;
          GrandstickyTextFrameBG.x = X1percent * 2.2;
          GrandstickyTextFrameBG.y = Y1percent * 7.2;

          //Grand sticky Text Frame
          GrandstickyTextFrame.height = app.screen.height / 13.8;
          GrandstickyTextFrame.width = X1percent * 36;
          GrandstickyTextFrame.x = X1percent * 2;
          GrandstickyTextFrame.y = Y1percent * 7;

          //Grand sticky Text Frame imported here
          GrandstickyTextImg.height = app.screen.height / 20;
          GrandstickyTextImg.width = X1percent * 30;
          GrandstickyTextImg.x = X1percent * 5;
          GrandstickyTextImg.y = Y1percent * 5.5;

          GrandStickyFrameDesign.height = Y1percent * 7.5;
          GrandStickyFrameDesign.width = X1percent * 36.5;
          GrandStickyFrameDesign.x = X1percent * 2;
          GrandStickyFrameDesign.y = Y1percent * 6.7;

          // Add Grand text
          GrandText.scale.set(2.5);
          GrandText.x = X1percent * 17.5;
          GrandText.y = Y1percent * 10.5;

          ////***************   Major text Section starts   ***************
          //Major Text Frame background Image
          MajorTextFrameBG.height = app.screen.height / 15;
          MajorTextFrameBG.width = X1percent * 30;
          MajorTextFrameBG.x = X1percent * 67.5;
          MajorTextFrameBG.y = Y1percent * 7.3;

          //Grand Text Frame
          MajorTextFrame.height = app.screen.height / 14;
          MajorTextFrame.width = X1percent * 30;
          MajorTextFrame.x = X1percent * 67.5;
          MajorTextFrame.y = Y1percent * 7;

          if (app.screen.width < 380 && app.screen.width > 340) {
            MajorTextImg.scale.set(0.25);
            MajorTextImg.x = X1percent * 73;
            MajorTextImg.y = Y1percent * 5;
          } else {
            MajorTextImg.scale.set(0.25);
            MajorTextImg.x = X1percent * 75;
            MajorTextImg.y = Y1percent * 6;
          }
          MajorText.scale.set(2.5);
          MajorText.x = X1percent * 83;
          MajorText.y = Y1percent * 10.5;
          // sticky major
          //Major Text Frame background Image
          MajorstickyTextFrameBG.height = app.screen.height / 16.5;
          MajorstickyTextFrameBG.width = X1percent * 30;
          MajorstickyTextFrameBG.x = X1percent * 66;
          MajorstickyTextFrameBG.y = Y1percent * 7.5;

          //Grand Text Frame
          MajorstickyTextFrame.height = app.screen.height / 14;
          MajorstickyTextFrame.width = X1percent * 32;
          MajorstickyTextFrame.x = X1percent * 66;
          MajorstickyTextFrame.y = Y1percent * 7;

          //Major Text Frame
          MajorstickyTextImg.height = Y1percent * 5;
          MajorstickyTextImg.width = X1percent * 25;
          MajorstickyTextImg.x = X1percent * 70;
          MajorstickyTextImg.y = Y1percent * 5.5;

          MajorStickyFrameDesign.height = Y1percent * 7.1;
          MajorStickyFrameDesign.width = X1percent * 32;
          MajorStickyFrameDesign.x = X1percent * 66;
          MajorStickyFrameDesign.y = Y1percent * 6.8;
          ////***************   Mini text section starts   ***************
          //Mini Text Frame background Image
          MiniTextFrameBG.height = app.screen.height / 19;
          MiniTextFrameBG.width = X1percent * 23.5;
          MiniTextFrameBG.x = X1percent * 3;
          MiniTextFrameBG.y = Y1percent * 21.5;
          //Mini Text Frame
          MiniTextFrame.height = app.screen.height / 16;
          MiniTextFrame.width = X1percent * 23;
          MiniTextFrame.x = X1percent * 3;
          MiniTextFrame.y = Y1percent * 21;

          if (app.screen.width < 380 && app.screen.width > 340) {
            MiniTextImg.scale.set(0.3);
            MiniTextImg.x = X1percent * 5.9;
            MiniTextImg.y = Y1percent * 19;
          } else {
            MiniTextImg.scale.set(0.3);
            MiniTextImg.x = X1percent * 6;
            MiniTextImg.y = Y1percent * 20;
          }

          stickyMiniText.height = Y1percent * 2;
          stickyMiniText.width = app.screen.width / 8;
          stickyMiniText.x = X1percent * 6.5;
          stickyMiniText.y = Y1percent * 23.6;

          stickyMinorText.width = app.screen.width / 8;
          stickyMinorText.height = Y1percent * 2;
          stickyMinorText.x = X1percent * 79;
          stickyMinorText.y = Y1percent * 23.5;

          //Mini Text Frame
          MinistickyTextFrame.height = app.screen.height / 16.5;
          MinistickyTextFrame.width = X1percent * 27;
          MinistickyTextFrame.x = X1percent * 1;
          MinistickyTextFrame.y = Y1percent * 21.4;
          //Mini Text Png
          MinistickyTextImg.height = app.screen.height / 29;
          MinistickyTextImg.width = X1percent * 20;
          MinistickyTextImg.x = X1percent * 4.5;
          MinistickyTextImg.y = Y1percent * 20.5;

          // Add Mini text
          //sticky remianing  count text
          stickyspinoverlayFrame.width = X1percent * 82;
          stickyspinoverlayFrame.height = Y1percent * 8;
          stickyspinoverlayFrame.x = X1percent * 10;
          stickyspinoverlayFrame.y = Y1percent * 30;

          stickyspinBGFrame.width = X1percent * 82;
          stickyspinBGFrame.height = Y1percent * 8;
          stickyspinBGFrame.x = X1percent * 9;
          stickyspinBGFrame.y = Y1percent * 29;

          stickyremainingText.x = X1percent * 18;
          stickyremainingText.y = Y1percent * 31;

          stickyremainingCount.x = X1percent * 72;
          stickyremainingCount.y = Y1percent * 31;

          stickyspinTotalwinImg.width = X1percent * 40;
          stickyspinTotalwinImg.height = Y1percent * 6;
          stickyspinTotalwinImg.x = X1percent * 30;
          stickyspinTotalwinImg.y = Y1percent * 1;

          stickySpinBootomText.x = X1percent * 15;
          stickySpinBootomText.y = Y1percent * 92.5;
          stickySpinBootomText.style.wordWrapWidth = X1percent * 90;

          stickyspinBottomCount.width = X1percent * 20;
          stickyspinBottomCount.height = X1percent * 20;
          stickyspinBottomCount.x = X1percent * 55;
          stickyspinBottomCount.y = Y1percent * 90;

          stickySpinBootomTextCount.text.length == 2
            ? (stickySpinBootomTextCount.x = X1percent * 61)
            : (stickySpinBootomTextCount.x = X1percent * 63);
          stickySpinBootomTextCount.y = Y1percent * 92;

          MiniText.width = app.screen.width / 8;
          MiniText.height = Y1percent * 2;
          MiniText.x = (app.screen.width - GrandTextImg.width) / 6;
          MiniText.y = Y1percent * 24;
          // MiniText.alpha=0

          //spinbutton button
          SpinBtn2.height = Y1percent * 5;
          SpinBtn2.width = X1percent * 25;
          SpinBtn2.y = Y1percent * 64;
          SpinBtn2.x = X1percent * 37.5;

          //spinbutton button
          SpinBtn.height = Y1percent * 5;
          SpinBtn.width = X1percent * 25;
          SpinBtn.y = Y1percent * 64;
          SpinBtn.x = X1percent * 37.5;

          //history button
          historyBtn.height = Y1percent * 8;
          historyBtn.width = Y1percent * 8;
          historyBtn.y = Y1percent * 82;
          historyBtn.x = X1percent * 14;

          //infor button
          InfoImg.height = Y1percent * 6;
          InfoImg.width = X1percent * 25;
          InfoImg.x = X1percent * 38;
          InfoImg.y = Y1percent * 83;

          //infor button text
          InfoText.height = Y1percent * 4;
          InfoText.width = X1percent * 12;
          InfoText.x = X1percent * 44.5;
          InfoText.y = Y1percent * 83.5;

          //denomination btn
          DenominationImg.height = Y1percent * 8;
          DenominationImg.width = Y1percent * 8;
          DenominationImg.x = X1percent * 70;
          DenominationImg.y = Y1percent * 82;

          //denomination text
          window.innerWidth >= 480
            ? (DenominationText.x = X1percent * 75)
            : (DenominationText.x = X1percent * 76);
          DenominationText.y = Y1percent * 84.3;
          DenominationText.height = Y1percent * 2.5;
          DenominationText.width = X1percent * 5;

          //credit box responsive
          CreditsBg.height = Y1percent * 10;
          CreditsBg.width = X1percent * 35;
          CreditsBg.x = X1percent * 5;
          CreditsBg.y = Y1percent * 70;

          CreditText.height = Y1percent * 3;
          CreditText.width = X1percent * 18;
          CreditText.x = X1percent * 14.5;
          CreditText.y = Y1percent * 69;
          CreditText.style.stroke = { color: "#000", width: 2 };

          creditsValue.height = Y1percent * 3.5;
          creditsValue.width =
            creditsValue.text.length == 1 ? X1percent * 10 : X1percent * 22;
          creditsValue.x =
            creditsValue.text.length === 3
              ? X1percent * 10
              : creditsValue.text.length === 12
              ? 10
              : creditsValue.text.length === 5
              ? X1percent * 10
              : creditsValue.text.length === 6
              ? X1percent * 12
              : creditsValue.text.length === 7
              ? X1percent * 12
              : creditsValue.text.length === 8
              ? X1percent * 10
              : creditsValue.text.length === 9
              ? X1percent * 12
              : creditsValue.text.length === 11
              ? X1percent * 10
              : creditsValue.text.length === 10
              ? X1percent * 10
              : creditsValue.text.length === 2
              ? X1percent * 15
              : X1percent * 18;
          creditsValue.y = Y1percent * 72;
          creditsValue.style.stroke = { color: "#000", width: 1 };

          if (window.innerWidth >= 480) {
            creditsSubValue.height = Y1percent * 3;
            creditsSubValue.width = X1percent * 18;
            creditsSubValue.x = X1percent * 14;
            creditsSubValue.y = Y1percent * 75.5;
          } else {
            creditsSubValue.height = Y1percent * 3;
            creditsSubValue.width = X1percent * 18;
            creditsSubValue.x = X1percent * 22;
            creditsSubValue.y = Y1percent * 77;
          }

          //win box responsive
          WinBg.height = Y1percent * 10;
          WinBg.width = X1percent * 25;
          WinBg.x = X1percent * 43;
          WinBg.y = Y1percent * 70;

          WinText.height = Y1percent * 4;
          WinText.width = X1percent * 12;
          WinText.x = X1percent * 50;
          WinText.y = Y1percent * 68.5;

          WinTextvalue.height = Y1percent * 3.5;
          WinTextvalue.width = X1percent * 5;
          WinTextvalue.x = winValue === 0 ? X1percent * 52.5 : X1percent * 52;
          WinTextvalue.y = Y1percent * 72;
          WinTextvalue.style.fontSize = Y1percent * 1;

          subWinTextvalue.height = Y1percent * 3.2;
          subWinTextvalue.width = X1percent * 12;
          subWinTextvalue.x = X1percent * 50;
          subWinTextvalue.y = Y1percent * 75;

          //Bet box
          BetBg.height = Y1percent * 10;
          BetBg.width = X1percent * 23;
          BetBg.x = X1percent * 72;
          BetBg.y = Y1percent * 70;

          BetText.height = Y1percent * 2.5;
          BetText.width = X1percent * 13;
          BetText.x = X1percent * 77.5;
          BetText.y = Y1percent * 69;

          BetValue.height = Y1percent * 2.5;
          BetValue.width = X1percent * 10;
          BetValue.x = X1percent * 79;
          BetValue.y = Y1percent * 72;

          if (window.innerWidth >= 480) {
            BetSubValue.height = Y1percent * 2.5;
            BetSubValue.width = X1percent * 10;
            BetSubValue.x = X1percent * 78;
            BetSubValue.y = Y1percent * 75;
          } else {
            BetSubValue.height = Y1percent * 3;
            BetSubValue.width = X1percent * 18;
            BetSubValue.x = X1percent * 83;
            BetSubValue.y = Y1percent * 77;
          }

          //flame of fortune logo
          FoFImg.height = Y1percent * 8;
          FoFImg.width = X1percent * 80;
          FoFImg.x = X1percent * 10;
          FoFImg.y = Y1percent * 92;

          //denomination box
        //   dollarBg.height = Y1percent * 30;
        //   dollarBg.width = X1percent * 90;
        //   dollarBg.x = X1percent * 5;
        //   dollarBg.y = Y1percent * 30;

          freeSpinImg.x = X1percent * 55;
          freeSpinImg.y = Y1percent * 90;
          freeSpinImg.width = X1percent * 20;
          freeSpinImg.height = X1percent * 20;
          freeSpinText.x = X1percent * 16;
          if (window.innerHeight < 700) {
            freeSpinText.y = Y1percent * 8;
            freeSpinText.x = X1percent * 20;
          } else {
            freeSpinText.y = Y1percent * 4;
          }
          freeSpinText.y = Y1percent * 4;
          freeSpinRemainingText.x = X1percent * 25;
          freeSpinRemainingText.y = Y1percent * 92;
          // popup after free spin popup
          freeSpinPopupBackground.width = X1percent * 80;
          freeSpinPopupBackground.height = Y1percent * 30;
          freeSpinPopupBackground.x = X1percent * 10;
          freeSpinPopupBackground.y = Y1percent * 35;

          freeSpinPopupMesg.style.fontSize = Y1percent * 3;
          freeSpinWon.style.fontSize = Y1percent * 2.5;
          freeSpinWon.style.wordWrapWidth = X1percent * 50;
          freeSpinReward.style.fontSize = Y1percent * 2;
          freeSpinReward.style.wordWrapWidth = X1percent * 50;
          freeSpinWinBg.width = X1percent * 30;
          freeSpinWinBg.x = X1percent * 35;
          freeSpinWinBg.y = Y1percent * 51;

          freeSpinCredits.style.fontSize = Y1percent * 2.5;

          freeSpinPopupBtn.width = X1percent * 25;
          freeSpinPopupBtn.x = X1percent * 37.5;
          freeSpinPopupBtn.y = Y1percent * 57;

          closeFreeSpinPop.style.fontSize = Y1percent * 1.5;

          // modalContent for credit value less then 50
          modalContainer.x = X1percent * 0;
          modalContainer.height = Y1percent * 30;
          modalContainer.width = X1percent * 75;
          modalContainer.y = Y1percent * 35;

          modalBackground.width = X1percent * 60;
          modalBackground.height = Y1percent * 40;
          modalBackground.x = X1percent * 5;
          modalBackground.y = Y1percent * 0;
          modalContent.style.fontSize = Y1percent * 1.8;
          modalContent.x = X1percent * -15;
          modalContent.style.wordWrapWidth = X1percent * 50;
        }
        if (
          window.innerWidth > 480 &&
          window.innerWidth <= 932 &&
          window.innerHeight <= 440
        ) {
          // popup after free spin popup
          freeSpinPopupBackground.width = X1percent * 40;
          freeSpinPopupBackground.height = Y1percent * 60;
          freeSpinPopupBackground.x = X1percent * 30;
          freeSpinPopupBackground.y = Y1percent * 20;
          freeSpinPopupMesg.style.fontSize = Y1percent * 7;
          freeSpinWon.style.fontSize = Y1percent * 5;
          freeSpinWon.style.wordWrapWidth = X1percent * 50;
          freeSpinReward.style.fontSize = Y1percent * 5;
          freeSpinReward.style.wordWrapWidth = X1percent * 50;
          freeSpinWinBg.width = X1percent * 15;
          freeSpinWinBg.height = Y1percent * 12;
          freeSpinWinBg.x = X1percent * 42.5;
          freeSpinWinBg.y = Y1percent * 53;
          freeSpinCredits.style.fontSize = Y1percent * 5;
          freeSpinPopupBtn.width = X1percent * 10;
          freeSpinPopupBtn.height = Y1percent * 10;
          freeSpinPopupBtn.x = X1percent * 45;
          freeSpinPopupBtn.y = Y1percent * 68;
          closeFreeSpinPop.style.fontSize = Y1percent * 4;
          modalBackground.width = X1percent * 60;
          modalBackground.height = Y1percent * 40;
          modalBackground.x = X1percent * 0;
          modalBackground.y = Y1percent * 0;
          modalContent.x = X1percent * -5;
          modalContent.style.wordWrapWidth = X1percent * 50;
        }
      }

      app.stage.addChildAt(remainingStickyCountainer, 50);
      remainingStickyCountainer.visible = false;
      responsiveLandscap();
    })();
  }, []);

  const [loder, setLoder] = useState(false);
  // const [show, setShow] = useState(false);

  useEffect(() => {
    // Function to simulate progress increment
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        return prevProgress; // Increase progress by 1
      });
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div>
        <Preloader
          bgcolor="#19991d"
          progress={progress}
          height="10"
          loader={loder}
          logo={Foff}
        ></Preloader>
      </div>

      <div style={{ position: "relative" }}>
        <div ref={pixiContainer} />
        {isHtmlVisible && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              color: "black",
              backgroundColor: "#27272733",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <GameHistoryTable
              onClick={() => {
                setHtmlVisible(false);
              }}
              
              gameId={gameType.MexTest}
            />
          </div>
        )}
      </div>

      <div style={{ position: "relative" }}>
        <div ref={pixiContainer} />
        {isPayoutTable && (
          <div
            className="gameInfoContainer"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              color: "black",
              backgroundColor: "#272727cc",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <SliderItems onClick={() => setPayoutTable(false)} gameId={gameType.MexTest} />
          </div>
        )}
      </div>

      <div style={{ position: "relative" }}>
        <div ref={pixiContainer} />
        {isSessionExpired && (
          <div
            className="sessionExpired"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              color: "black",
              backgroundColor: "#000",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <SessionExpired
              onClick={() => {
                setSessionExpired(false);
                localStorage.removeItem("token");
                localStorage.removeItem("playerId");
                navigate("/");
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};






 





















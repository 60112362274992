import axios from 'axios';
import React, { useState, useCallback, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import closeBtn from "../assets/close.png";
import { RingLoader } from 'react-spinners';
import moment from 'moment';
import { getMessage } from "../utils/userMessages";



// customs
const indices = [0, 3, 6, 9, 12, 1, 4, 7, 10, 13, 2, 5, 8, 11, 14];


const customStyles = {
  rows: {
    style: {
      minHeight: "52px",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      paddingLeft: "22px",
      FontStyle: "normal",
      justifyContent: "left",
      fontFamily: "'Nunito', sans-serif",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
      fontWeight: "normal",
      paddingLeft: "22px",
      justifyContent: "left",
      fontFamily: "'Nunito', sans-serif",
    },
  },
};


const GameHistoryTable = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [playerId, setPlayerId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [playerData, setPlayerData] = useState([]);
  const [reelComb,setReelComb]=useState([]);


  const defaultLang = localStorage.getItem('defaultLang') 

  console.log(defaultLang,'defaultLang+++++')

  useEffect(() => {
    handlePlayerIdChange()
    getHistoryData();
    
  }, []);


  const token = localStorage.getItem("token");
  console.log(token, 'gamepage  ++ token')


  async function getHistoryData() {
    try {
      const response = await axios?.post(`${process.env.REACT_APP_API_URL}/GamePlay/PlayerSpinHistory?playerId=${playersId}&gameId=${props.gameId}`, false,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      const playerHistoryData = response?.data?.resultData;
      if(playerHistoryData === null){
        setIsLoading(false)
      }else{
        console.log({playerHistoryData});
      console.log(response?.data?.resultData, 'responseHist')
      setPlayerData(playerHistoryData)
      setReelComb(playerData?.winningCombination?.split(","));
      setIsLoading(false)
      }
      

    } catch (error) {
      console.log(error.message)
    }
  }

let img = "/assets/Slots/Slot_Sprite/A_royal_dorado.png";

function imgHandle(img){
if(img === "HH"){
  return "/assets/Slots/Slot_Sprite/A_royal_dorado.png";
}
if(img === "KK"){
  return "/assets/Slots/Slot_Sprite/J_royal_dorado.png";
}
if(img === "II"){
  return "/assets/Slots/Slot_Sprite/K_royal_dorado.png";
}
if(img === "FF"){
  return "/assets/Slots/Slot_Sprite/AntorchaA.png";
}
if(img === "WW"){
  return "/assets/Slots/Slot_Sprite/Calavera.png";
}
if(img === "CC"){
  return "/assets/Slots/Slot_Sprite/Exploradora_Simbolo_2.png";
}
if(img === "DD"){
  return "/assets/Slots/Slot_Sprite/gema.png";
}
if(img === "GG"){
  return "/assets/Slots/Slot_Sprite/Moneda.png";
}
if(img === "SS"){
  return "/assets/Slots/Slot_Sprite/Piramide.png";
}
if(img === "JJ"){
  return "/assets/Slots/Slot_Sprite/Q_royal_dorado.png";
}
if(img === "EE"){
  return "/assets/Slots/Slot_Sprite/quetzal.png";
}
if(img === "TT"){
  return "/assets/Slots/Slot_Sprite/SunFire.png";
}

}



function convertUTCDateToArray(utcDateString) {
  // Create a Date object from the UTC date string
  var local = moment.utc(utcDateString).local().format();
   console.log(local, "- UTC now to local"); 
  // Create a Date object from the UTC date string
  const utcDate = new Date(local);

  
  // Extract components
  const year = utcDate.getFullYear();
  const month = utcDate.getMonth() + 1; // Months are zero-indexed
  const day = utcDate.getDate();
  const hours = utcDate.getHours();
  const minutes = utcDate.getMinutes();
  const seconds = utcDate.getSeconds();

function formatTimeUnit(unit) {
return String(unit).padStart(2, '0');
}

const formattedHours = formatTimeUnit(hours);
const formattedMinutes = formatTimeUnit(minutes);
const formattedSeconds = formatTimeUnit(seconds);
let curTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
let curDate = `${month}/${day}/${year}`;

  return (
    <>
    <p> {defaultLang != 'en' ? 'Fecha:' : 'Date:'}  {curDate} </p>
    <p style={{textAlign:'left'}}>{defaultLang == 'en' ? 'Time:' : 'Tiempo:'} {curTime}</p>
    </>
  );
}


  const columns = [
    {
      name: (getMessage(defaultLang, 'spinIdDateTime')),
      sortable: true,
      minWidth: '180px',
      selector: (playerData) => {
        return(
        <>
        <div title={`${playerData.spinDetailsId}`}><span style={{fontWeight:'800', color:'#ca8c0f'}}>{playerData.spinDetailsId}</span></div>
        <div title={`${playerData.date}`}>{convertUTCDateToArray(playerData.date)}</div>
        </>
        )
      }
    },
    {
      name: (getMessage(defaultLang, 'CoinDenom')),
      sortable: true,
      minWidth: '165px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div title={`${playerData.coinDenomation}`}>{playerData.coinDenomation*100}/${playerData.coinDenomation}</div>
    },
    {
      name: (getMessage(defaultLang, 'nooFPaylines')),
      sortable: true,
      minWidth: '120px',
      selector: (playerData) => <div title={`${playerData.noOfPaylinePlayed}`}>{playerData.noOfPaylinePlayed}</div>
    },
    {
      name: (getMessage(defaultLang, 'betMultiplier')),
      sortable: true,
      minWidth: '130px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div title={`${playerData.initialBetMultiplier}`}>{playerData.initialBetMultiplier}</div>
    },
    {
      name: (getMessage(defaultLang, 'totalBetAmount')),
      sortable: true,
      minWidth: '160px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div title={`${playerData.betMoney}`}>{playerData.betMoney}/${(playerData.betMoneyInDollar).toFixed(2)}</div>
    },
    {
      name: (getMessage(defaultLang, 'spinType')),
      sortable: true,
      minWidth: '150px',
      selector: (playerData) => <div title={`${playerData.isFreeSpinWon}`}>{defaultLang == 'es' ? (playerData.spinTypeName == 'Normal Spin' ? 'Giro normal' : playerData.spinTypeName == 'Free Spin' ? 'Giro gratis' :'Giro pegajoso') : playerData.spinTypeName}</div>
    },
    {
      name: (getMessage(defaultLang, 'previousBal_$')),
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{(playerData.playerBeforeBetBalanceInDollar*100).toFixed(0)}/<br/>${playerData.playerBeforeBetBalanceInDollar}</div>,
    },
    {
      name: (getMessage(defaultLang, 'previousDenomBal_$')),
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{parseInt((playerData.playerBeforeBetBalance))}/<br/>${playerData.playerBeforeBetBalanceInDollar}</div>,
    },{
      name: (getMessage(defaultLang, 'gameResult')),
      sortable: true,
      minWidth: '200px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}><p><span style={{fontWeight:'800', color:'#ca8c0f'}}>{defaultLang != 'en' ? 'Apuesta:' : 'Bet:'} </span>{playerData.betMoney}</p>
     <p><span style={{fontWeight:'800', color:'#ca8c0f'}}>{defaultLang != 'en' ? 'Ganar:' : 'Win:'} </span> ${(playerData.dollarWinValue).toFixed(2)}</p>
     <p><span style={{fontWeight:'800', color:'#ca8c0f'}}>{defaultLang != 'en' ? 'Resultado:' : 'Result:'} </span> {(playerData.dollarWinValue > playerData.betMoneyInDollar) ? `$${(playerData.profitAndLossInDollar).toFixed(2)}` : `${playerData.spinTypeName === 'Normal Spin' ? `-` : ``}$${Math.abs(playerData.profitAndLossInDollar).toFixed(2)}`}</p>
        </div>,
    },
    {
      name: (getMessage(defaultLang, 'CurrentBal_$')),
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerCurrentBalance}`}>{(playerData.playerCurrentBalanceInDollar*100).toFixed(0)}/<br/>${(playerData.playerCurrentBalanceInDollar).toFixed(2)}</div>,
    },
    {
      name: (getMessage(defaultLang, 'currentDenomBal_$')),
      sortable: true,
      minWidth: '140px',
      selector: (playerData) => <div title={`${playerData.playerBeforeBetBalance}`}>{Math.floor((playerData.playerCurrentBalanceInDollar*100)/(playerData.coinDenomation*100))}/<br/>${(playerData.playerCurrentBalanceInDollar).toFixed(2)}</div>,
    },
    {
      name: (getMessage(defaultLang, 'ReelCombination')),
      sortable: true,
      minWidth: '200px',
      style:{
        display: 'flex',
        flexWrap:'wrap'
      },
      selector: (playerData) => <div className='grid-container' title={`${playerData.winningCombination}`}>{ indices.map(index => <img className='grid-item' src={`${process.env.REACT_APP_ASSET_URL}/Images/GameIcon/Newfolder_${props.gameId}/${playerData?.winningCombination?.split(",")[index]}.png`} width={'30px'} alt={playerData?.winningCombination?.split(",")[index]} />)} 
        </div>
    },
    {
      name: (getMessage(defaultLang, 'winningPayline')),
      sortable: true,
      minWidth: '160px',
      selector: (playerData) => <>
      {(playerData.dollarWinValue === playerData.betMoneyInDollar) ? `` : <div style={{display:'flex', flexWrap:'wrap'}} title={`${playerData.winningPayline}`}>{playerData.winningPayline.split(',').map((item)=> {
        return (<>
          <p className='seprateComma' style={{whiteSpace:'nowrap !important', display:'inline-block', width:'fit-content'}}>{item}<span className=''>{(playerData.winningPayline.length == '0' ? '': ',')}</span></p>
          </>
        )
      })}</div>}
      </>
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    background: {
      default: '#00000066',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#4a4a4a',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const playersId = localStorage.getItem('playerId')
  console.log('playerId', playersId)
  const handlePlayerIdChange = (event) => {
    setPlayerId(playersId);
  };

  useEffect(() => {
    console.log("state", selectedRows);
  }, [selectedRows]);


  return (
    <>
    <div className='playerHistoryPage'>
      {playerData.length > 0 ? <RingLoader className='loader' style={{display:'none'}} /> :  <RingLoader className='loader' />}
      <div style={{ width: '90%', margin: '0 auto', border: '1px solid #ffb700', padding: '8px', background: '#69696994', borderRadius: '10px', position:'relative' }}>
      <button style={{background:'none', border:'none', position: 'absolute', zIndex:'9', top:'-20px', right:'-20px', cursor:'pointer'}} onClick={props.onClick}><img style={{height:'40px', width:'40px'}} src={closeBtn} alt=""/></button>
       
        <DataTable
          title= {getMessage(defaultLang, 'gameHistoryTitle')}
          columns={columns}
          className="testing"
          data={playerData}
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          progressPending={isLoading}
          theme="solarized"
          />
      </div>
    </div>
          </>
  );
};
export default GameHistoryTable;
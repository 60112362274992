import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logo from '../../assets/logo/logo.png'
import navCoin from '../../assets/logo/navCoin.png'
import setting from '../../assets/logo/setting.png'
import headerCoin from '../../assets/logo/headerCoin.png';
import cardCoin from '../../assets/logo/cardCoin.png'
import coinsList from '../../assets/logo/coinsList.png'
import profile from '../../assets/logo/profile.png'
import sound from '../../assets/logo/sound.svg'
import fire from '../../assets/logo/fire.png'
import AoA from '../../assets/logo/AoA.jpg'
import MexTest from '../../assets/logo/MexTest.png'
import commingSoon from '../../assets/logo/commingSoon.png'
export default function GameLobby() {
    const [game, setGame] = useState('')
    const navigate = useNavigate()
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

        const handleFlamesOfFortuneGame=()=>{
        const token = localStorage.getItem("token");
        navigate(token ? '/flames-of-fortune' : '/')
    }

    const handleAtlantisLegendGame=()=>{
        const token = localStorage.getItem("token");
        navigate(token ? '/atlantis-legend' : '/')
    }
   
    const handleMexTest=()=>{
        const token = localStorage.getItem("token");
        navigate(token ? '/MexTest' : '/')
    }
    return (
        <>
            <header>
                <div className='header-wrapper'>
                    <div className='coin-number'><img src={headerCoin} alt="" /><span>$848432823</span></div>
                    <div className='header-logo'><img src={logo} alt="" /></div>
                    <div className='header-tabs'>
                        <div className="icon"><img src={navCoin} alt="" width={50} height={50} /></div>
                        <div className="user-profile"><img src={setting} alt="" width={30} height={30} /></div>
                        <div className="setting-icon"><img src={profile} alt="" width={50} height={50} /><span> Mr. James Bond</span></div>
                    </div>
                </div>
            </header>
            <section className='carousel-section'>
                <div className="carousel-sound-icon">

                    <img src={game} alt="" /><img src={sound} alt="" />

                </div>
                <Carousel responsive={responsive}>
                    <div>
                        <div className='carousel-card card-1'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper" >
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={fire} alt="" /></div>
                                    <button type='button' className='play-btn' onClick={handleFlamesOfFortuneGame}>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='carousel-card card-2'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={AoA} alt="" /></div>
                                    <button type='button' className='play-btn' onClick={handleAtlantisLegendGame}>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='carousel-card card-3'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={MexTest} alt="" /></div>
                                    <button type='button' className='play-btn' onClick={handleMexTest}>PLAY</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='carousel-card card-4'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={commingSoon} alt="" /></div>
                                    <button type='button' className='play-btn'>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='carousel-card card-1'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={commingSoon} alt="" /></div>
                                    <button type='button' className='play-btn'>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </Carousel>
            </section>

        </>
    )
}

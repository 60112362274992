const messages = {
    en: {
      credit: "Credits",
      win: "Win",
      bet: "Bet",
      info : "Info",
      denominationText : "CHOOSE YOUR DENOMINATION",
      returnButton : "/assets/Tutorials/Return-Button.png",
      gameHistoryTitle: "Player Game History",
      spinIdDateTime: "Spin ID, Date & Time",
      CoinDenom: "Coin Denomination",
      nooFPaylines : "No. of Paylines",
      betMultiplier : "Bet Multiplier",
      totalBetAmount : "Total Bet Amount",
      spinType : "Spin Type",
      previousBal_$ : "Previous Bal($)",
      previousDenomBal_$ : "Previous Denom. Bal($)",
      gameResult: "Result (Loss($)/Win($))",
      CurrentBal_$ : "Current Bal($)",
      currentDenomBal_$: "Current Denom. Bal($)",
      ReelCombination : "Reel Combination",
      winningPayline : "Winning Payline",
      RemainingFreeSpinText : "Remaining \nFree Spin",
      RemainingStickyCount: "Remaining Count",
      SpacesFillCountText:"Spaces \nFilled" 
    },
    es: {
      credit: "créditos",
      win: "ganar",
      bet: "apuesta",
      info : "Información",
      denominationText : "ELIGE TU DENOMINACIÓN",
      returnButton : "/assets/Tutorials/Boton-Volver.png",
      gameHistoryTitle: "Historial de juego del jugador",
      spinIdDateTime: "ID de giro, fecha y hora",
      CoinDenom: "Denominación de la moneda",
      nooFPaylines : "No. de líneas de pago",
      betMultiplier : "Multiplicador de Apuestas",
      totalBetAmount : "Monto total de la Apuesta",
      spinType : "Tipo de giro",
      previousBal_$ : "Balón Anterior ($)",
      previousDenomBal_$ : "Denominación anterior. Bal($)",
      gameResult: "Resultado (pérdida ($)/ganancia ($))",
      CurrentBal_$ : "Balón actual ($)",
      currentDenomBal_$: "Denominación actual. Bal($)",
      ReelCombination : "Combinación de carretes",
      winningPayline : "Línea de pago ganadora",
      RemainingFreeSpinText : "Restante \nGiro gratis",
      RemainingStickyCount: "Recuento restante",
      SpacesFillCountText:"Espacios llenos" 
    }
  };
  
  export const getMessage = (lang, key) => {
    return messages[lang][key] || messages['en'][key]; // Fallback to English if the key is not found
  };
